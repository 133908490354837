import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import "./BusinessPage.css";
import "../../utils/common.css";
import Loader from "react-loader-spinner";
import { loadStateOptions } from "../../services/StateService";
import { loadCityOptions } from "../../services/CityService";
import { loadCityCategoriesOptions } from "../../services/Categories";
import { businessDetailsByCategory } from "../../services/BusinessService";
import BusinessPage from "./BusinessPage";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import Alert from "../Alert/Alert";
import BusinessGetListingForCategory from "./BusinessGetListingForCategory";
import Select from "react-select";
import _ from "lodash";

const colorStyle = {
  menuPortal: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    padding: 20,
  }),
};

const formatOptionLabel = ({ value, label, is_category }, rest) => {
  return (
    <div style={{ display: "block" }}>
      <div>{label}</div>
      {rest.selectValue &&
      rest.selectValue.length > 0 &&
      rest.selectValue[0]["label"] === label ? null : (
        <div
          style={{
            color: "#6a6a6a",
            fontSize: "14px",
            margin: "10px 0 0",
          }}
        >
          {is_category === 1 ? "Category" : "Service"}
        </div>
      )}
    </div>
  );
};

function BusinessListingForCategory(props) {
  const [stateOption, setStateOption] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  const [cityOption, setCityOption] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [categoryOption, setCategoryOption] = useState([]);

  useEffect(() => {
    loadStateOptions().then((data) => setStateOption(data));
  }, []);

  useEffect(() => {
    if (selectedCity != null) {
      const city = selectedCity
        ? cityOption[
            _.findIndex(cityOption, function (o) {
              return selectedCity && o.value === selectedCity.value;
            })
          ]
        : null;
      if (city && !_.isEqual(city, selectedCity)) {
        setSelectedCity(city);
      }
    }
  }, [cityOption, selectedCity]);

  useEffect(() => {
    if (selectedState != null) {
      loadCityOptions(null, selectedState.value).then((data) => {
        setCityOption(data);
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity != null) {
      loadCityCategoriesOptions(null, selectedCity.value).then((data) => {
        setCategoryOption(data);
      });
    }
  }, [selectedCity]);

  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [businessListing, setBusinessListing] = useState([]);
  const [filterDisplay, setFilterDisplay] = useState(true);
  const [alertDisplay, setAlertDisplay] = useState("none");
  const [alertErrorMsg, setAlertErrorMsg] = useState(
    "Please fill all the inputs!"
  );

  const [focusInput, setFocusInput] = useState(null);
  console.log(focusInput);
  const openFilters = (filter_type) => {
    if (filter_type === "city") {
      setFocusInput("city");
    }
    setFilterDisplay(true);
  };
  const goBack = () => {
    if (filterDisplay) {
      history.push("/home");
    } else {
      history.location.state && history.location.state.category
        ? history.goBack()
        : openFilters(null);
    }
  };
  const backLink = (event) => {
    setSelectedCompany(null);
  };
  if (selectedCompany) {
    return (
      <BusinessPage
        business_id={selectedCompany}
        backLink={backLink}
        setSelectedCompany={setSelectedCompany}
      />
    );
  }
  return (
    <>
      <Loader
        type="TailSpin"
        height={"100px"}
        width={"100px"}
        color="#1a73e8"
        style={{ display: showLoader ? "block" : "none" }}
        className={"body-container-footer"}
      />
      <div
        className="profile-page"
        style={{ display: !showLoader ? "block" : "none" }}
      >
        <div className="back-div">
          <div onClick={goBack} className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </div>
        </div>
        <div className="listing-main-category-container">
          <div
            className="filter-container custom-search-container"
            style={{
              display: filterDisplay ? "flex" : "none",
              marginTop: "60px",
            }}
          >
            <Alert value={alertErrorMsg} show={alertDisplay} />

            <Select
              name="form-field-name"
              styles={colorStyle}
              className="cities search-field"
              placeholder={"Select State"}
              multi={false}
              defaultOptions
              value={selectedState}
              options={stateOption}
              onChange={(e) => {
                if (!selectedState || selectedState.value !== e.value) {
                  setSelectedCity(null);
                  setSelectedCategory(null);
                }
                return setSelectedState(e);
              }}
            />
            {/* <label from="city">City</label> */}
            {/* {selectedState !== null ? ( */}
            <Select
              key={selectedState ? selectedState.label : "disabled"}
              name="form-field-name"
              className="cities search-field"
              placeholder={"Select City/Town"}
              isDisabled={selectedState === null}
              multi={false}
              defaultOptions
              value={selectedCity}
              options={cityOption}
              onChange={(e) => {
                if (
                  !selectedCity ||
                  (selectedCity && selectedCity.value !== e.value)
                ) {
                  setSelectedCategory(null);
                }
                return setSelectedCity(e);
              }}
            />
            <Select
              key={selectedCity ? selectedCity.label : "isdisabled"}
              name="form-field-name"
              className="cities search-field"
              placeholder={"What are you looking for?"}
              isDisabled={selectedCity === null || selectedState === null}
              multi={false}
              value={selectedCategory}
              defaultOptions
              options={categoryOption}
              formatOptionLabel={formatOptionLabel}
              onChange={(e) => {
                return setSelectedCategory(e);
              }}
            />
            <button
              className="done-btn search-btn"
              onClick={(e) => {
                if (
                  selectedState == null ||
                  selectedCategory == null ||
                  selectedCity == null
                ) {
                  setAlertErrorMsg("Please fill all the fields");
                  setAlertDisplay(true);
                } else {
                  setShowLoader(true);
                  businessDetailsByCategory(selectedCategory.value).then(
                    (res) => {
                      setBusinessListing(res.data);
                    }
                  );
                  setShowLoader(false);
                  setFilterDisplay(false);
                }
              }}
            >
              Search
            </button>
          </div>
          <BusinessGetListingForCategory
            filterDisplay={filterDisplay}
            selectedStateCity={selectedCity}
            openFilters={openFilters}
            businessListing={businessListing}
            setSelectedCompany={setSelectedCompany}
          />
        </div>
      </div>
    </>
  );
}
export default BusinessListingForCategory;
