import "./Category.css";
import { getCategoies, getSubCategoies } from "../../services/Categories";
import { useEffect, useState } from "react";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";

const history = [];
const titleHistory = [];

function BusinessCategory(props) {
  const myHistory = useHistory();
  const country = myHistory.location.state["country"];
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(false);
  const [displayPage, setDisplayPage] = useState("none");

  useEffect(() => {
    if (myHistory.location.state && myHistory.location.state.from) {
      if (
        myHistory.location.state.from === "/business-register-country" ||
        myHistory.location.state.from === "/business-register-location"
      ) {
        getCategoies().then((res) => {
          setData(res.data);
        });
        setDisplayPage("block");
      }
    }
  }, [myHistory.location.state]);

  const goBack = () => {
    if (history.length >= 1) {
      setData(history.pop());
    }
    if (titleHistory.length >= 1) {
      titleHistory.pop();
      if (titleHistory.length === 0) {
        setTitle(false);
      }
    }
  };

  const checkBackAndTitle = (someData, title) => {
    if (title) {
      return (
        <div className="back-div">
          <p onClick={goBack} className="link" style={{ marginTop: "17px" }}>
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </p>
        </div>
      );
    } else {
      return (
        <div className="back-div">
          <Link
            to={{
              pathname: "/business-register-country",
              state: {
                from: myHistory.location.pathname,
              },
            }}
            className="link"
          >
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
      );
    }
  };

  const submitCategory = (el) => {
    myHistory.push({
      pathname: "/business-register-location",
      state: {
        from: myHistory.location.pathname,
        category_id: el.id,
        country: country,
      },
    });
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  const returnComponent = (someData) => {
    // return data.map((el) => {
    //   if (
    //     (_.has(el, "categories_set") && _.isEmpty(el.categories_set)) ||
    //     !_.has(el, "categories_set")
    //   ) {
    //     return (
    //       <div
    //         key={elementGetNameWithId(el)}
    //         className="each-category-element"
    //         onClick={() => submitCategory(el)}
    //       >
    //         <div className="each-category">
    //           <p>{getCategoryNameId(elementGetNameWithId(el), false)}</p>
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div
    //         key={el.name_with_id}
    //         onClick={() => {
    //           const some1 = data;
    //           history.push(some1);
    //           setData(el.categories_set);
    //           setTitle(getCategoryNameId(el.name_with_id, false));
    //           titleHistory.push(getCategoryNameId(el.name_with_id, false));
    //         }}
    //         className="each-category-element"
    //       >
    //         <div className="each-category">
    //           <p>{getCategoryNameId(el.name_with_id, false)}</p>
    //           <FaChevronRight fill="#1a73e8" />
    //         </div>
    //       </div>
    //     );
    //   }
    // });
    someData.sort((a, b) => a.category_name.localeCompare(b.category_name));
    return someData.map((el) => {
      if (el.has_child) {
        return (
          <div
            key={el.id}
            className={`each-category-element ${
              el.main_category ? "main-category " + el.name_key : ""
            }`}
            onClick={() => {
              getSubCategoies(el.category_name).then((res) => {
                history.push(data);
                setData(res.data);
                setTitle(true);
                titleHistory.push(el.category_name);
              });
              scrollToTop();
            }}
          >
            <div className="each-category">
              <p
                className="each-sub-category each-category"
                style={{ marginLeft: "0px" }}
              >
                {el.category_name}
              </p>
              <FaChevronRight fill="#1a73e8" />
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={el.id}
            className={`each-category-element ${
              el.main_category ? "main-category " + el.name_key : ""
            }`}
            onClick={() => {
              submitCategory(el);
              //   myHistory.push({
              //     pathname: "/category-search",
              //     state: {
              //       category: el.id,
              //       from: myHistory.location.pathname,
              //     },
              //   });
              //   scrollToTop();
            }}
          >
            <div className="each-category">
              <p
                className="each-sub-category each-category"
                style={{ marginLeft: "0px" }}
              >
                {el.category_name}
              </p>
            </div>
          </div>
        );
      }
    });
  };
  return (
    <div className="body-container-footer">
      <div className="profile-page" style={{ display: displayPage }}>
        {data != null ? checkBackAndTitle(data, title) : null}
        <h2 className={"category-title"}>{title}</h2>
        <div
          className="profile-page-element-container"
          style={{ marginTop: "20px" }}
        >
          <div className="profile-page-element category-element">
            {data != null ? returnComponent(data) : null}
          </div>
        </div>
      </div>
    </div>
    // <div className="home">
    // </div>
  );
}

export default BusinessCategory;
