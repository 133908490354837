import "./TermAndService.css";
function TermAndServiceComman() {
  return (
    <>
      <p className="c4">
        <span className="c1">Terms Of Service</span>
      </p>

      <p className="c4">
        <span className="c0">Updated at 02-10-2024</span>
      </p>

      <p className="c4">
        <span className="c0 head">General Terms</span>
      </p>

      <p className="c4">
        <span className="c0">
          By accessing and subscribing with zopfind, you confirm that you are in
          agreement with and bound by the terms of service contained in the
          Terms Of Service outlined below. These terms apply to the entire
          website and any email or other type of communication between you and
          zopfind.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Under no circumstances shall the zopfind team is liable for any
          direct, indirect, special, incidental, or consequential damages,
          including, but not limited to, loss of data or profit, arising out of
          the use, or the inability to use, the materials on this site, even if
          zopfind team or an authorized representative has been advised of the
          possibility of such damages. If your use of materials from this site
          results in the need for servicing, repair, or correction of equipment
          or data, you assume any costs thereof.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          The Service may be modified, updated, interrupted, suspended or
          discontinued at any time without notice or liability.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          zopfind will not be responsible for any outcome that may occur during
          the usage of our resources. We reserve the right to change prices and
          revise the resources usage policy at any moment.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          We grant you permission to use the Service subject to these Terms.
          Your use of the Service is at your own risk, including the risk that
          you might be exposed to Content that is offensive, indecent,
          inaccurate, objectionable, incomplete, fails to provide adequate
          warning about potential risks or hazards, or is otherwise
          inappropriate.
        </span>
      </p>
      <p className="c4">
        <span className="c0 head">Line Of Business (LOB)</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind website offers business listings for business users.
        </span>
      </p>
      <p className="c4">
        <span className="c0 head">License</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind grants you a revocable, non-exclusive, non-transferable,
          limited license to download, install and use the website strictly in
          accordance with the terms of this Agreement.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          These Terms Of Service are a contract between you and zopfind
          (referred to in these Terms Of Service as "zopfind", "us", "we" or
          "our"), the provider of the zopfind website, and the services
          accessible from the zopfind website (which are collectively referred
          to in these Terms Of Service as the "zopfind Service").
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          You are agreeing to be bound by these Terms Of Service. If you do not
          agree to these Terms Of Service, please do not use the zopfind
          Service. In these Terms Of Service, "you" refers both to you as an
          individual and to the entity you represent. If you violate any of
          these Terms Of Service, we reserve the right to cancel your account or
          block access to your account without notice.
        </span>
      </p>
      <p className="c4">
        <span className="c0">“User Site” means zopfind's User website.</span>
      </p>
      <p className="c4">
        <span className="c0">
          “User Account” means the account you create to access or use the User
          Site.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          “Business Account” means the account you create to access or use the
          zopfind for Business Owners website.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          “Account” means any User Account or Business Account.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Business Account users can not change the business location which is
          Country, City, Pincode, area after listing their business.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Business Account user's locations can not be changed after the listing
          has happened.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Once the listing has been created the listing can not be allowed to
          change to another location.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          For one Pincode there will be only ten business listings space
          available for any category or subcategory to list a business for a
          year.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear more than ten business listings space for one Pincode
          for any category or subcategory to list a business for a year, there
          will be only ten business listings space available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear hosting services with more than ten business listings
          space for one Pincode for any category or subcategory to list a
          business for a year, there will be only ten business listings space
          available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear database services more than ten business listings space
          for one Pincode for any category or subcategory to list a business for
          a year, there will be only ten business listings space available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear storage services more than ten business listings space
          for one Pincode for any category or subcategory to list a business for
          a year, there will be only ten business listings space available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear application scaling more than ten business listings
          space for one Pincode for any category or subcategory to list a
          business for a year, there will be only ten business listings space
          available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear resources more than ten business listings space for one
          Pincode for any category or subcategory to list a business for a year,
          there will be only ten business listings space available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear application code maintenance more than ten business
          listings space for one Pincode for any category or subcategory to list
          a business for a year, there will be only ten business listings space
          available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear application development more than ten business listings
          space for one Pincode for any category or subcategory to list a
          business for a year, there will be only ten business listings space
          available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We cannot bear web servers and network services more than ten business
          listings space for one Pincode for any category or subcategory to list
          a business for a year, there will be only ten business listings space
          available.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind can not afford database, hosting, storage, scaling,
          development, web servers, network services, code maintenance,
          resources more than ten business listings space for one Pincode for
          any category or subcategory to list a business for a year, there will
          be only ten business listings space available.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          zopfind can not supply database, hosting, storage, scaling,
          development, web servers, network services, code maintenance,
          resources more than ten business listings space for one Pincode for
          any category or subcategory to list a business for a year, there will
          be only ten business listings space available.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          To continue in the listing after one year, business users should pay
          for the next year if not paid the listing will not be available or
          other business users will come into the listing space.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Business Account users showing Offer and Today's deal in the zopfind
          website to the users should give Offer and Today’s deal to the users
          when they show it and ask for it.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          You must create an Account and provide certain information about
          yourself in order to use some of the features that are offered through
          the Service. You are responsible for maintaining the confidentiality
          of your Account security information. You are also responsible for all
          activities that occur in connection with your Account. You agree to
          notify us immediately of any unauthorized use of your Account. We
          reserve the right to close your Account at any time for any or no
          reason.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          Your User Account is for your personal, non-commercial use only, and
          you may not create or use a User Account for anyone other than
          yourself. We ask that you provide complete and accurate information
          about yourself when creating an Account in order to bolster your
          credibility as a contributor to the Service. You may not impersonate
          someone else, provide an email address other than your own, create
          multiple Accounts, or transfer your User Account to another person
          without zopfind’s prior approval.
        </span>
      </p>
      <p className="c4">
        <span className="c0 head">Meanings</span>
      </p>

      <p className="c4">
        <span className="c0 head">For this Terms Of Service:</span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          - Cookie: a small amount of data generated by a website and saved by
          your web browser. It is used to identify your browser, provide
          analytics, and remember information about you such as your language
          preference or login information.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Company: when this policy mentions &ldquo;Company,&rdquo;
          &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our,&rdquo; it refers to
          zopfind that is responsible for your information under this Terms Of
          Service.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Country: where zopfind or the owners/founders of zopfind are based,
          in this case, is India
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Device: any internet-connected device such as a phone, tablet,
          computer, or any other device that can be used to visit zopfind and
          use the services.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Service: refers to the service provided by zopfind as described in
          the relative terms (if available) and on this platform.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Third-party service: refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Website: zopfind.&quot;&rsquo;s&quot; site, which can be accessed
          via this URL: www.zopfind.com
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - You: a person or entity that is registered with zopfind to use the
          Services.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Changes</span>
      </p>

      <p className="c4">
        <span className="c0">
          We may periodically change the Terms and the Site without notice, and
          you are responsible for checking these Terms periodically for
          revisions. All amended Terms become effective upon our posting to the
          Site, and any use of the site after such revisions have been posted
          signifies your consent to the changes.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Restrictions</span>
      </p>

      <p className="c4">
        <span className="c0">
          You agree not to, and you will not permit others to:
        </span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          - License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the website or
          make the platform available to any third party.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Modify, make derivative works of, disassemble, decrypt, reverse
          compile or reverse engineer any part of the website.
        </span>
      </p>
      <p className="c4 left-align">
        <span className="c0 left-align">
          - Remove, alter or obscure any proprietary notice (including any
          notice of copyright or trademark) of zopfind or its affiliates,
          partners, business users, or the licensors of the website.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Return &amp; Refund Policy</span>
      </p>

      <p className="c4">
        <span className="c0">
          Thanks for listing your business at zopfind. We appreciate the fact
          that you like to buy the stuff we build. We also want to make sure you
          have a rewarding experience while you&rsquo;re exploring, evaluating,
          and purchasing our service.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          As with listing experience, there are terms and conditions that apply
          to transactions at zopfind. We&rsquo;ll be as brief as our attorneys
          will allow. The main thing to remember is that by placing an order or
          making a purchase or subscription at zopfind, you agree to the terms
          along with zopfind.&quot;&rsquo;s&quot; Privacy Policy.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          If there&rsquo;s something wrong with what you bought, or if you are
          not happy with it, you will not be able to issue a refund for it.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We follow certain policies to ensure transparency, efficiency, and
          quality :
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Refunds are NOT given for any purchases made.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          If, for any reason, You are not completely satisfied with any good or
          service that we provide, don&#39;t hesitate to contact us and we will
          discuss any of the issues you are going through with our service.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Your Consent</span>
      </p>

      <p className="c4">
        <span className="c0">
          By using our website, listing your business, registering an account,
          or making a purchase, you hereby consent to our Return &amp; Refund
          Policy and agree to its terms.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">
          Changes To Our Return &amp; Refund Policy
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          We update, amend or make any changes to this document so that they
          accurately reflect our Service and policies. Unless otherwise required
          by law, those changes will be prominently posted here. Then, if you
          continue to use the Service, you will be bound by the updated Return
          &amp; Refund Policy. If you do not want to agree to this or any
          updated Return &amp; Refund Policy, you can delete your account.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Your Suggestions</span>
      </p>

      <p className="c4">
        <span className="c0">
          Any feedback, comments, ideas, improvements, or suggestions
          (collectively, &quot;Suggestions&quot;) provided by you to zopfind for
          the website shall remain the sole and exclusive property of zopfind.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind shall be free to use, copy, modify, publish, or redistribute
          the Suggestions for any purpose and in any way without any credit or
          any compensation to you.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Your Consent</span>
      </p>

      <p className="c4">
        <span className="c0">
          We&#39;ve updated our Terms Of Service to provide you with complete
          transparency into what is being set when you visit our site and how
          it&#39;s being used. By using our website, registering an account, or
          making a purchase, you hereby consent to our Terms Of Service.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Links to Other Websites</span>
      </p>

      <p className="c4">
        <span className="c0">
          These Terms Of Service apply only to the Services. The Services may
          contain links to other websites not operated or controlled by zopfind.
          We are not responsible for the content, accuracy, or opinions
          expressed in such websites, and such websites are not investigated,
          monitored, or checked for accuracy or completeness by us. Please
          remember that when you use a link to go from the Services to another
          website, our Terms Of Service are no longer in effect. Your browsing
          and interaction on any other website, including those that have a link
          on our platform, is subject to that website&rsquo;s own rules and
          policies. Such third parties may use their cookies or other methods to
          collect information about you.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Cookies</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind uses &quot;Cookies&quot; to identify the areas of our website
          that you have visited. A cookie is a small piece of data stored on
          your computer or mobile device by your web browser. We use cookies to
          enhance the performance and functionality of our website but are
          non-essential to their use. However, without these cookies, certain
          functionality like videos may become unavailable or you would be
          required to enter your login details every time you visit the website
          as we would not be able to remember that you had logged in previously.
          Most web browsers can be set to disable the use of Cookies. However,
          if you disable Cookies, you may not be able to access functionality on
          our website correctly or at all. We never place Personally
          Identifiable Information in Cookies.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Changes To Our Terms Of Service</span>
      </p>

      <p className="c4">
        <span className="c0">
          You acknowledge and agree that &lt;?=zopfind;?&gt; may stop
          (permanently or temporarily) providing the Service (or any features
          within the Service) to you or to users generally at
          &lt;?=zopfind;?&gt;&rsquo;s sole discretion, without prior notice to
          you. You may stop using the Service at any time. You do not need to
          specifically inform &lt;?=zopfind;?&gt; when you stop using the
          Service. You acknowledge and agree that if &lt;?=zopfind;?&gt;
          disables access to your account, you may be prevented from accessing
          the Service, your account details, or any files or other materials
          which are contained in your account.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          If we decide to change our Terms Of Service, we will post those
          changes on this page, and/or update the Terms Of Service modification
          date below.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Modifications to Our website</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind reserves the right to modify, suspend or discontinue,
          temporarily or permanently, the website or any service to which it
          connects, with or without notice and without liability to you.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Updates to Our website</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind may from time to time provide enhancements or improvements to
          the features/ functionality of the website, which may include patches,
          bug fixes, updates, upgrades, and other modifications
          (&quot;Updates&quot;).
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Updates may modify or delete certain features and/or functionalities
          of the website. You agree that zopfind has no obligation to (i)
          provide any Updates, or (ii) continue to provide or enable any
          particular features and/or functionalities of the website to you.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          You further agree that all Updates will be (i) deemed to constitute an
          integral part of the website, and (ii) subject to the terms and
          conditions of this Agreement.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Third-Party Services</span>
      </p>

      <p className="c4">
        <span className="c0">
          We may display, include or make available third-party content
          (including data, information, applications, and other products
          services) or provide links to third-party websites or services
          (&quot;Third-Party Services&quot;).
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          You acknowledge and agree that zopfind shall not be responsible for
          any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency,
          quality, or any other aspect thereof. zopfind does not assume and
          shall not have any liability or responsibility to you or any other
          person or entity for any Third-Party Services.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties terms and conditions.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Term and Termination</span>
      </p>

      <p className="c4">
        <span className="c0">
          After completion of your term or year which you paid for listing your
          listing will not be visible, It will be visible only after the payment
          for the next term or year, incase if someone took the slot for that
          area, city, Pincode before you, you can not be in the listing and you
          will not be visible.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          This Agreement shall remain in effect until terminated by you or
          zopfind.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind may, in its sole discretion, at any time and for any or no
          reason, suspend or terminate this Agreement with or without prior
          notice.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          This Agreement will terminate immediately, without prior notice from
          zopfind, if you fail to comply with any provision of this Agreement.
          You may also terminate this Agreement by deleting the website and all
          copies thereof from your computer.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Upon termination of this Agreement, you shall cease all use of the
          website and delete all copies of the website from your computer.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          Termination of this Agreement will not limit any of zopfind&#39;s
          rights or remedies at law or in equity in case of breach by you
          (during the term of this Agreement) of any of your obligations under
          the present Agreement.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Copyright Infringement Notice</span>
      </p>

      <p className="c4">
        <span className="c0">
          If you are a copyright owner or such owner&rsquo;s agent and believe
          any material on our website constitutes an infringement on your
          copyright, please contact us setting forth the following information:
          (a) a physical or electronic signature of the copyright owner or a
          person authorized to act on his behalf; (b) identification of the
          material that is claimed to be infringing; (c) your contact
          information, including your address, telephone number, and an email;
          (d) a statement by you that you have a good faith belief that use of
          the material is not authorized by the copyright owners; and (e) the
          statement that the information in the notification is accurate, and,
          under penalty of perjury you are authorized to act on behalf of the
          owner.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Indemnification</span>
      </p>

      <p className="c4">
        <span className="c0">
          You agree to indemnify and hold zopfind and its parents, subsidiaries,
          affiliates, officers, employees, agents, partners, and licensors (if
          any) harmless from any claim or demand, including reasonable
          attorneys&#39; fees, due to or arising out of your: (a) use of the
          website; (b) violation of this Agreement or any law or regulation; or
          (c) violation of any right of a third party.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">No Warranties</span>
      </p>

      <p className="c4">
        <span className="c0">
          The website is provided to you &quot;AS IS&quot; and &quot;AS
          AVAILABLE&quot; and with all faults and defects without warranty of
          any kind. To the maximum extent permitted under applicable law,
          zopfind, on its behalf and behalf of its affiliates and its and their
          respective licensors and service providers, expressly disclaims all
          warranties, whether express, implied, statutory, or otherwise, for the
          website, including all implied warranties of merchantability, fitness
          for a particular purpose, title and non-infringement, and warranties
          that may arise out of the course of dealing, course of performance,
          usage or trade practice. Without limitation to the foregoing, zopfind
          provides no warranty or undertaking and makes no representation of any
          kind that the website will meet your requirements, achieve any
          intended results, be compatible or work with any other software,
          systems, or services, operate without interruption, meet any
          performance or reliability standards or be error-free or that any
          errors or defects can or will be corrected.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Without limiting the foregoing, neither zopfind nor any zopfind&#39;s
          provider makes any representation or warranty of any kind, express or
          implied: (i) as to the operation or availability of the website, or
          the information, content, and materials or products included thereon;
          (ii) that the website will be uninterrupted or error-free; (iii) as to
          the accuracy, reliability, or currency of any information or content
          provided through the website; or (iv) that the website, its servers,
          the content, or e-mails sent from or on behalf of zopfind are free of
          viruses, scripts, trojan horses, worms, malware, timebombs or other
          harmful components.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Some jurisdictions do not allow the exclusion of or limitations on
          implied warranties or the limitations on the applicable statutory
          rights of a consumer, so some or all of the above exclusions and
          limitations may not apply to you.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Limitation of Liability</span>
      </p>

      <p className="c4">
        <span className="c0">
          Zopfind shall not be liable for indirect, incidental, special,
          exemplary, punitive, or consequential damages, (including without
          limitation loss of profits, revenue, interest, goodwill) related to,
          in connection with, or otherwise, even if zopfind has been advised of
          the possibility of such damages. zopfind shall not be liable for any
          damages, liability, or losses arising out of: (i) your use of or
          reliance on the services or your inability to access or use the
          services; or (ii) any transaction or relationship between you and a
          vendor, even if zopfind has been advised of the possibility of such
          damages. zopfind shall not be liable for delay or failure in
          performance resulting from causes beyond zopfind&#39;s reasonable
          control. in no event shall zopfind&#39;s total liability to you
          connect with the services for all damages.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Notwithstanding any damages that you might incur, the entire liability
          of zopfind and any of its business users under any provision of this
          Agreement and your exclusive remedy for all of the foregoing shall be
          limited to the amount paid by you for the website.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          To the maximum extent permitted by applicable law, in no event shall
          zopfind or its business users be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, for loss of data or other
          information, for business interruption, for personal injury, for loss
          of privacy arising out of or in any way related to the use of or
          inability to use the website, third-party software and/or third-party
          hardware used with the website, or otherwise in connection with any
          provision of this Agreement), even if zopfind or any business user has
          been advised of the possibility of such damages and even if the remedy
          fails of its essential purpose.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Severability</span>
      </p>

      <p className="c4">
        <span className="c0">
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          This Agreement, together with the Privacy Policy and any other legal
          notices published by zopfind on the Services, shall constitute the
          entire agreement between you and zopfind concerning the Services. If
          any provision of this Agreement is deemed invalid by a court of
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of this Agreement,
          which shall remain in full force and effect. No waiver of any term of
          this Agreement shall be deemed a further or continuing waiver of such
          term or any other term, and zopfind.&quot;&rsquo;s&quot; failure to
          assert any right or provision under this Agreement shall not
          constitute a waiver of such right or provision. YOU AND zopfind AGREE
          THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES
          MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES.
          OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Waiver</span>
      </p>

      <p className="c4">
        <span className="c0">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Agreement shall not
          affect a party&#39;s ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute waiver of any subsequent breach.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          No failure to exercise and no delay in exercising, on the part of
          either party, any right or any power under this Agreement shall
          operate as a waiver of that right or power. Nor shall any single or
          partial exercise of any right or power under this Agreement preclude
          further exercise of that or any other right granted herein. In the
          event of a conflict between this Agreement and any applicable purchase
          or other terms, the terms of this Agreement shall govern.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Amendments to this Agreement</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind reserves the right, at its sole discretion, to modify or
          replace this Agreement at any time. If a revision is a material we
          will provide at least 30 days notice prior to any new terms taking
          effect. What constitutes a material change will be determined at our
          sole discretion.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          By continuing to access or use our website after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use zopfind.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Entire Agreement</span>
      </p>

      <p className="c4">
        <span className="c0">
          The Agreement constitutes the entire agreement between you and zopfind
          regarding your use of the website and supersedes all prior and
          contemporaneous written or oral agreements between you and zopfind.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          You may be subject to additional terms and conditions that apply when
          you use or purchase other zopfind&#39;s services, which zopfind will
          provide to you at the time of such use or purchase.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Updates to Our Terms</span>
      </p>

      <p className="c4">
        <span className="c0">
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Unless otherwise required by law, we will notify you (for
          example, through our Service) before we make changes to these Terms
          and allow you to review them before they go into effect. Then, if you
          continue to use the Service, you will be bound by the updated Terms.
          If you do not want to agree to these or any updated Terms, you can
          delete your account.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">&nbsp;Indemnification</span>
      </p>
      <p className="c4">
        <span className="c0">
          a. Reseller shall defend, indemnify and hold harmless zopfind and its
          directors, officers, employees, agents, and affiliates, including from
          any third party actions/claims/demands, for all costs, expenses, and
          losses arising from or in any way attributable to (i) acts and
          omissions of the Reseller under these Terms; (ii) breach by Reseller
          of any of the terms and conditions of these Terms; (iii) violation of
          any applicable laws by Reseller; (iv) any damage to property or bodily
          injury (including death) caused by the Reseller; (v) acts of fraud,
          misappropriation, and disclosure of Confidential Information by the
          Reseller vi) any claim from vendor or third party claims for reasons
          attributable to Reseller.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          b. The Reseller hereby agrees to indemnify the zopfind, its directors
          and officers from and against any and all claims, suits, actions,
          demands or proceedings and all related damages, losses, liabilities,
          cost and expenses (including but not limited to reasonable
          lawyers&#39; fees) arising out of or relating to any (i) damage,
          destruction, unauthorised sharing or other misuse of Confidential
          Information (ii) embezzlement, misappropriation or other
          misapplication of subscription amount received from the Vendor by the
          Reseller or any of its employees, (iii) violation or breach by the
          Reseller of any law, rules or regulations, or any term, policy or
          obligation under this Terms; (iv) claims made by Reseller&#39;s
          personnel on zopfind including but not limited to claims for wages,
          benefits or employment; (v) breach by the Reseller of its
          responsibilities under these Terms or a violation of the restrictions
          hereunder and (vi) claims made by the state or central government, or
          local authority in respect of non-payment of taxes or fees, for
          non-provision of statutory benefits by the Reseller to its personnel;
          and (vii) Misrepresentation to the Vendor or third parties regarding
          any information relating to zopfind or the Services/products.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          c. The Reseller shall not make any claim or allegation against zopfind
          or hold zopfind liable in respect of any claim, demand, or action
          (&quot;Claims&quot;) made or filed by any Vendor against the Reseller.
          All such Claims shall be resolved solely by the Reseller at
          Reseller&#39;s own cost and expense, failing which the Reseller shall
          indemnify zopfind as provided for in these Terms.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Intellectual Property Rights</span>
      </p>

      <p className="c4">
        <span className="c0">
          The website and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection, and
          arrangement thereof), are owned by zopfind, its licensors, or other
          providers of such material and are protected by India and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws. The material may not
          be copied, modified, reproduced, downloaded, or distributed in any
          way, in whole or in part, without the express prior written permission
          of zopfind, unless and except as is expressly provided in these Terms
          Of Service. Any unauthorized use of the material is prohibited.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          a. All right, title, and interest, including all intellectual property
          rights, in and to, in all content, trademarks, marketing collateral,
          forms, Vendor-related data, and in all other material handed over to
          the Reseller, or collected by the Reseller or to which the Reseller
          may have access to (&quot;zopfind Property&quot;), shall vest in, and
          be owned by, zopfind. No right or other license to such zopfind
          Property is given to the Vendor.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          b. zopfind names, logos, and all related products and service names,
          design marks, and slogans (&quot;zopfind marks&quot;) are trademarks
          and service marks owned by and used under license from zopfind or its
          wholly-owned subsidiaries. The Reseller is not authorized to use any
          zopfind Mark in any advertising, publicity, or any other commercial
          manner without the prior written consent of zopfind.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">
          Governing Law, Jurisdiction, And Dispute Resolution
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          These Terms of Service shall be governed by and interpreted in all
          respects in accordance with the laws of the Republic of India.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          Subject to the provisions made in Clause 9.3, the parties hereby
          submit to the exclusive jurisdiction of the courts of Eluru, India.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          All disputes arising out of or in relation to these Terms of Service
          shall be settled amicably by the parties. In the event no amicable
          settlement is arrived at within a period of 15 (fifteen) days from the
          date of the first initiation of the dispute by one party to another,
          the parties shall resolve the dispute by means of arbitration pursuant
          to the Arbitration and Conciliation Act, 1996.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          The arbitration proceedings shall be conducted by an arbitral tribunal
          comprising of 1 (one) arbitrator mutually appointed by you and the
          Company.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          The arbitration proceedings shall be conducted in the English language
          only and the seat for arbitration shall be Eluru, India.
        </span>
      </p>
      <p className="c4">
        <span className="c0">
          The award of the arbitral tribunal shall be final and binding.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Agreement to Arbitrate</span>
      </p>

      <p className="c4">
        <span className="c0">
          This section applies to any dispute EXCEPT IT DOESN&rsquo;T INCLUDE A
          DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF
          REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR
          zopfind.&quot;&rsquo;s&quot; INTELLECTUAL PROPERTY RIGHTS. The term
          &ldquo;dispute&rdquo; means any dispute, action, or another
          controversy between you and zopfind concerning the Services or this
          agreement, whether in contract, warranty, tort, statute, regulation,
          ordinance, or any other legal or equitable basis.
          &ldquo;Dispute&rdquo; will be given the broadest possible meaning
          allowable under law.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Notice of Dispute</span>
      </p>

      <p className="c4">
        <span className="c0">
          In the event of a dispute, you or zopfind must give the other a Notice
          of Dispute, which is a written statement that sets forth the name,
          address, and contact information of the party giving it, the facts
          giving rise to the dispute, and the relief requested. You must send
          any Notice of Dispute via email to: support@zopfind.com. zopfind will
          send any Notice of Dispute to you by mail to your address if we have
          it, or otherwise to your email address. You and zopfind will attempt
          to resolve any dispute through informal negotiation within sixty (60)
          days from the date the Notice of Dispute is sent. After sixty (60)
          days, you or zopfind may commence arbitration.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Binding Arbitration</span>
      </p>

      <p className="c4">
        <span className="c0">
          If you and zopfind don&rsquo;t resolve any Dispute by informal
          negotiation, any other effort to resolve the dispute will be conducted
          exclusively by binding arbitration as described in this section. You
          are giving up the right to litigate (or participate in as a party or
          className member) all disputes in court before a judge or jury. The
          dispute shall be settled by binding arbitration in accordance with the
          commercial arbitration rules of the American Arbitration Association.
          Either party may seek any interim or preliminary injunctive relief
          from any court of competent jurisdiction, as necessary to protect the
          party&rsquo;s rights or property pending the completion of
          arbitration. Any legal, accounting, and other costs, fees, and
          expenses incurred by the prevailing party shall be borne by the
          non-prevailing party.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Submissions and Privacy</span>
      </p>

      <p className="c4">
        <span className="c0">
          If you submit or post any ideas, creative suggestions, designs,
          photographs, information, advertisements, data, or proposals,
          including ideas for new or improved products, services, features,
          technologies, or promotions, you expressly agree that such submissions
          will automatically be treated as non-confidential and non-proprietary
          and will become the sole property of zopfind without any compensation
          or credit to you whatsoever. zopfind and its affiliates shall have no
          obligations for such submissions or posts and may use the ideas
          contained in such submissions or posts for any purposes in any medium
          in perpetuity, including, but not limited to, developing,
          manufacturing, and marketing products and services using such ideas.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Privacy Policy</span>
      </p>
      <p className="c4">
        <span className="c0">
          Zopfind is committed to protecting the privacy and confidentiality of
          any personal information that it may request and receive from its
          clients, business users, and other users of the Website. To read our
          privacy policy statement regarding such personal information please
          refer to Privacy Policy
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Promotions</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind may, from time to time, include contests, promotions,
          sweepstakes, or other activities (&ldquo;Promotions&rdquo;) that
          require you to submit material or information concerning yourself.
          Please note that all Promotions may be governed by separate rules that
          may contain certain eligibility requirements, such as restrictions as
          to age and geographic location. You are responsible to read all
          Promotions rules to determine whether or not you are eligible to
          participate. If you enter any Promotion, you agree to abide by and
          comply with all Promotions Rules.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          Additional terms and conditions may apply to purchases of goods or
          services on or through the Services, which terms and conditions are
          made a part of this Agreement by this reference.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Typographical Errors</span>
      </p>

      <p className="c4">
        <span className="c0">
          In the event a product and/or service is listed at an incorrect price
          or with incorrect information due to typographical error, we shall
          have the right to refuse or cancel any subscription placed for the
          product and/or service listed at the incorrect price. We shall have
          the right to refuse or cancel any such subscription whether or not the
          subscription has been confirmed and your credit card charged. If your
          credit card has already been charged for the purchase and your
          subscription is canceled, we shall issue a credit to your credit card
          account or another payment account in the amount of the charge.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Miscellaneous</span>
      </p>

      <p className="c4">
        <span className="c0">
          If for any reason a court of competent jurisdiction finds any
          provision or portion of these Terms Of Service to be unenforceable,
          the remainder of these Terms Of Service will continue in full force
          and effect. Any waiver of any provision of these Terms Of Service will
          be effective only if in writing and signed by an authorized
          representative of zopfind. zopfind will be entitled to injunctive or
          other equitable relief (without the obligations of posting any bond or
          surety) in the event of any breach or anticipatory breach by you.
          zopfind operates and controls the zopfind Service from its offices in
          India. The Service is not intended for distribution to or use by any
          person or entity in any jurisdiction or country where such
          distribution or use would be contrary to law or regulation.
          Accordingly, those persons who choose to access the zopfind Service
          from other locations do so on their initiative and are solely
          responsible for compliance with local laws, if and to the extent local
          laws are applicable. These Terms Of Service (which include and
          incorporate the zopfind Privacy Policy) contains the entire
          understanding, and supersedes all prior understandings, between you
          and zopfind concerning its subject matter, and cannot be changed or
          modified by you. The section headings used in this Agreement are for
          convenience only and will not be given any legal import.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Disclaimer</span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind is not responsible for any content, code, or any other
          imprecision.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind does not provide warranties or guarantees.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          zopfind is not responsible for any criminal activity, crime, offense,
          wrongdoing, illegal, unethical, immoral, unlawful that happens between
          users, customers, business account users.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          In no event shall zopfind be liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever,
          whether in an action of contract, negligence or another tort, arising
          out of or in connection with the use of the Service or the contents of
          the Service. zopfind reserves the right to make additions, deletions,
          or modifications to the contents of the Service at any time without
          prior notice.
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          The zopfind Service and its contents are provided &quot;as are&quot;
          and &quot;as available&quot; without any warranty or representations
          of any kind, whether express or implied. zopfind is a distributor and
          not a publisher of the content supplied by third parties; as such,
          zopfind exercises no editorial control over such content and makes no
          warranty or representation as to the accuracy, reliability, or
          currency of any information, content, service, or merchandise provided
          through or accessible via the zopfind Service. Without limiting the
          foregoing, zopfind specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          the zopfind Service or on sites that may appear as links on the
          zopfind Service, or in the products provided as a part of, or
          otherwise in connection with, the zopfind Service, including without
          limitation any warranties of merchantability, fitness for a particular
          purpose or non-infringement of third party rights. No oral advice or
          written information given by zopfind or any of its affiliates,
          employees, officers, directors, agents, or the like will create a
          warranty. Price and availability information is subject to change
          without notice. Without limiting the foregoing, zopfind does not
          warrant that the zopfind Service will be uninterrupted, uncorrupted,
          timely, or error-free.
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">
          LIMITATION OF LIABILITY AND INDEMNIFICATION
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          {`zopfind ("we" or "us") provides a platform for users, customers, and business users ("you") to interact. While we strive to maintain a safe and secure environment, we cannot guarantee that our platform will be free from criminal activity or unauthorized use.`}
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          {`zopfind expressly disclaims any liability for any damages, losses, or harm arising from or related to any criminal activity or unauthorized use of our platform, including but not limited to:`}
        </span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          {"- Fraudulent transactions or activities"}
        </span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          {"- Theft or unauthorized use of personal or financial information"}
        </span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          {"- Harassment, intimidation, or other harmful behavior"}
        </span>
      </p>

      <p className="c4 left-align">
        <span className="c0 left-align">
          {"- Violations of applicable laws or regulations"}
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          {`You acknowledge and agree that we are not responsible for monitoring or policing user interactions or transactions, and we have no liability for any damages or harm resulting from such interactions or transactions.`}
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          {`By using our platform, you agree to indemnify, defend, and hold harmless zopfind, its officers, directors, employees, and agents from any claims, demands, or damages arising from or related to any criminal activity or unauthorized use of our platform.`}
        </span>
      </p>

      <p className="c4">
        <span className="c0">
          {`Please report any suspicious activity or unauthorized use to our support team immediately.`}
        </span>
      </p>

      <p className="c4">
        <span className="c0 head">Contact Us</span>
      </p>

      <p className="c4">
        <span className="c0">
          Don&#39;t hesitate to contact us if you have any questions.
        </span>
      </p>

      <p className="c4">
        <span className="c0">&nbsp;-Via Email: &nbsp;support@zopfind.com</span>
      </p>
    </>
  );
}
export default TermAndServiceComman;
