import "../Pages/Privacy.css";

function PrivacyAndPolicyComman() {
  return (
    <>
      <p className="c2">
        <span className="c6">Privacy Policy</span>
      </p>
      <p className="c2">
        <span className="c0">Updated at 25-02-2023</span>
      </p>
      <p className="c2">
        <span className="c0">
          zopfind (&ldquo;we,&rdquo; &ldquo;our,&rdquo; or &ldquo;us&rdquo;) is
          committed to protecting your privacy. This Privacy Policy explains how
          your personal information is collected, used, and disclosed by
          zopfind.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          This Privacy Policy applies to our website, and its associated
          subdomains (collectively, our &ldquo;Service&rdquo;) alongside our
          application, zopfind. By accessing or using our Service, you signify
          that you have read, understood, and agree to our collection, storage,
          use, and disclosure of your personal information as described in this
          Privacy Policy and our Terms of Service.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">DEFINITIONS AND KEY TERMS</span>
      </p>
      <p className="c2">
        <span className="c0">
          To help explain things as clearly as possible in this Privacy Policy,
          every time any of these terms are referenced, are strictly defined as:
        </span>
      </p>
      <ul className="c4 lst-kix_list_3-0 start">
        <li className="c2 c3 li-bullet-0">
          <span>
            Business Account users: refers to a person that signs up to use the
            zopfind Service to manage the relationships with your users
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Cookie:&nbsp;small amount of data generated by a website and saved
            by your web browser. It is used to identify your browser, provide
            analytics, remember information about you such as your language
            preference or login information.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Company: when this policy mentions &ldquo;Company,&rdquo;
            &ldquo;we,&rdquo; &ldquo;us,&rdquo; or &ldquo;our,&rdquo; it refers
            to zopfind that is responsible for your information under this
            Privacy Policy.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Country: where zopfind or the owners/founders of zopfind are based,
            in this case is India
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Device:&nbsp;any internet connected device such as a phone, tablet,
            computer or any other device that can be used to visit zopfind and
            use the services.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            IP address: Every device connected to the Internet is assigned a
            number known as an Internet protocol (IP) address. These numbers are
            usually assigned in geographic blocks. An IP address can often be
            used to identify the location from which a device is connecting to
            the Internet.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Personnel:&nbsp;refers to those individuals who are employed by
            zopfind or are under contract to perform a service on behalf of one
            of the parties.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Personal Data: any information that directly, indirectly, or in
            connection with other information &mdash; including a personal
            identification number &mdash; allows for the identification or
            identifiability of a natural person.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Service: refers to the service provided by zopfind as described in
            the relative terms (if available) and on this platform.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Third-party service:&nbsp;refers to advertisers, contest sponsors,
            promotional and marketing partners, and others who provide our
            content or whose products or services we think may interest you.
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Users: refers to a person that signs up to use the zopfind Service
            to manage the relationships with your Business Account users
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            Website: zopfind.&quot;&rsquo;s&quot; site, which can be accessed
            via this URL: zopfind.com
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            You: a person or entity that is registered with zopfind to use the
            Services.
          </span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0">&nbsp; </span>
      </p>
      <p className="c2">
        <span className="c0 head">WHAT INFORMATION DO WE COLLECT?</span>
      </p>
      <p className="c2">
        <span className="c0">
          We collect information from you when you visit our website, register
          on our site, place an order, subscribe to our newsletter, respond to a
          survey or fill out a form.
        </span>
      </p>
      <ul className="c4 lst-kix_list_2-0 start">
        <li className="c2 c3 li-bullet-0">
          <span>Name / Username</span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>Phone Numbers</span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>Email Addresses</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0">&nbsp; </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          HOW DO WE USE THE INFORMATION WE COLLECT?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          Any of the information we collect from you may be used in one of the
          following ways:
        </span>
      </p>
      <ul className="c4 lst-kix_list_1-0 start">
        <li className="c2 c3 li-bullet-0">
          <span>
            To personalize your experience (your information helps us to better
            respond to your individual needs)
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            ●To improve our website (we continually strive to improve our
            website offerings based on the information and feedback we receive
            from you)
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            To improve user service (your information helps us to more
            effectively respond to your user service requests and support needs)
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>To process transactions</span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>
            To administer a contest, promotion, survey or other site feature
          </span>
        </li>
        <li className="c2 c3 li-bullet-0">
          <span>To send transactional emails</span>
        </li>
      </ul>
      <p className="c2">
        <span className="c0 head">
          WHEN DOES ZOPFIND USE END USER INFORMATION FROM THIRD PARTIES?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          zopfind will collect End User Data necessary to provide the zopfind
          services to our customers.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          End users may voluntarily provide us with information they have made
          available on social media websites. If you provide us with any such
          information, we may collect publicly available information from the
          social media websites you have indicated. You can control how much of
          your information social media websites make public by visiting these
          websites and changing your privacy settings.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          WHEN DOES ZOPFIND USE CUSTOMER INFORMATION FROM THIRD PARTIES?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          We receive some information from the third parties when you contact
          us. For example, when you submit your email address to us to show
          interest in becoming a zopfind customer, we receive information from a
          third party that provides automated fraud detection services to
          zopfind. We also occasionally collect information that is made
          publicly available on social media websites. You can control how much
          of your information social media websites make public by visiting
          these websites and changing your privacy settings.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          DO WE SHARE THE INFORMATION WE COLLECT WITH THIRD PARTIES?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          We will not share the information that we collect, both personal and
          non-personal, with third parties such as advertisers, contest
          sponsors, promotional and marketing partners, and others who provide
          our content or whose products or services we think may interest you.
          We will not share it with our current and future affiliated companies
          and business partners, and if we are involved in a merger, asset sale
          or other business reorganization, we may share or transfer your
          personal and non-personal information to our successors-in-interest.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          We may engage trusted third party service providers to perform
          functions and provide services to us, such as hosting and maintaining
          our servers and the website, database storage and management, email
          management, storage marketing, credit card, debit card or other
          payment processing details, users service and fulfilling orders for
          products and services you may purchase through the website. We will
          likely share your personal information, and possibly some non-personal
          information, with these third parties to enable them to perform these
          services for us and for you.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          We may also disclose personal and non-personal information about you
          to government or law enforcement officials or private parties as we,
          in our sole discretion, believe necessary or appropriate in order to
          respond to claims, legal process (including subpoenas), to protect our
          rights and interests or those of a third party, the safety of the
          public or any person, to prevent or stop any illegal, unethical, or
          legally actionable activity, or to otherwise comply with applicable
          court orders, laws, rules and regulations.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          WHERE AND WHEN IS INFORMATION COLLECTED FROM CUSTOMERS AND END USERS?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          zopfind will collect personal information that you submit to us. We
          may also receive personal information about you from third parties as
          described above.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">HOW LONG DO WE KEEP YOUR INFORMATION?</span>
      </p>
      <p className="c2">
        <span className="c0">
          We keep your information only so long as we need it to provide zopfind
          to you and fulfill the purposes described in this policy. This is also
          the case for anyone that we share your information with and who
          carries out services on our behalf. When we no longer need to use your
          information and there is no need for us to keep it to comply with our
          legal or regulatory obligations, we’ll either remove it from our
          systems or depersonalize it so that we can't identify you.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">HOW DO WE PROTECT YOUR INFORMATION?</span>
      </p>
      <p className="c2">
        <span className="c0">
          We implement a variety of security measures to maintain the safety of
          your personal information when you place an order or enter, submit, or
          access your personal information. We offer the use of a secure server.
          All supplied sensitive/credit information is transmitted via Secure
          Socket Layer (SSL) technology and then encrypted into our Payment
          gateway providers database only to be accessible by those authorized
          with special access rights to such systems, and are required to keep
          the information confidential. After a transaction, your private
          information (credit cards, social security numbers, financials, etc.)
          is never kept on file. We cannot, however, ensure or warrant the
          absolute security of any information you transmit to zopfind or
          guarantee that your information on the Service may not be accessed,
          disclosed, altered, or destroyed by a breach of any of our physical,
          technical, or managerial safeguards.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          COULD MY INFORMATION BE TRANSFERRED TO OTHER COUNTRIES?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          zopfind is incorporated in India. Information collected via our
          website, through direct interactions with you, or from use of our help
          services may be transferred from time to time to our offices or
          personnel, or to third parties, located throughout the world, and may
          be viewed and hosted anywhere in the world, including countries that
          may not have laws of general applicability regulating the use and
          transfer of such data. To the fullest extent allowed by applicable
          law, by using any of the above, you voluntarily consent to the
          trans-border transfer and hosting of such information.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          IS THE INFORMATION COLLECTED THROUGH THE ZOPFIND SERVICE SECURE?
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          We take precautions to protect the security of your information. We
          have physical, electronic, and managerial procedures to help
          safeguard, prevent unauthorized access, maintain data security, and
          correctly use your information. However, neither people nor security
          systems are foolproof, including encryption systems. In addition,
          people can commit intentional crimes, make mistakes or fail to follow
          policies. Therefore, while we use reasonable efforts to protect your
          personal information, we cannot guarantee its absolute security. If
          applicable law imposes any non-disclaimable duty to protect your
          personal information, you agree that intentional misconduct will be
          the standards used to measure our compliance with that duty.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">CAN I UPDATE OR CORRECT MY INFORMATION?</span>
      </p>
      <p className="c2">
        <span className="c0">
          The rights you have to request updates or corrections to the
          information zopfind collects depend on your relationship with zopfind.
          Personnel may update or correct their information as detailed in our
          internal company employment policies.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          Customers have the right to request the restriction of certain uses
          and disclosures of personally identifiable information as follows. You
          can&nbsp;contact us in order to (1) update or correct your personally
          identifiable information, (2) change your preferences with respect to
          communications and other information you receive from us, or (3)
          delete the personally identifiable information maintained about you on
          our systems (subject to the following paragraph), by&nbsp;cancelling
          your account. Such updates, corrections, changes and deletions will
          have no effect on other information that we maintain, or information
          that we have provided to third parties in accordance with this Privacy
          Policy prior to such update, correction, change or deletion. To
          protect your privacy and security, we may take reasonable steps (such
          as requesting a unique password) to verify your identity before
          granting you profile access or making corrections. You are responsible
          for maintaining the secrecy of your unique password and account
          information at all times.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          You should be aware that it is not technologically possible to remove
          each and every record of the information you have provided to us from
          our system. The need to back up our systems to protect information
          from inadvertent loss means that a copy of your information may exist
          in a non-erasable form that will be difficult or impossible for us to
          locate. Promptly after receiving your request, all personal
          information stored in databases we actively use, and other readily
          searchable media will be updated, corrected, changed or deleted, as
          appropriate, as soon as and to the extent reasonably and technically
          practicable.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          If you are an end user and wish to update, delete, or receive any
          information we have about you, you may do so by contacting the
          organization of which you are a user.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Personnel</span>
      </p>
      <p className="c2">
        <span className="c0">
          If you are a zopfind worker or applicant, we collect information you
          voluntarily provide to us. We use the information collected for Human
          Resources purposes in order to administer benefits to workers and
          screen applicants.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          You may contact us in order to (1) update or correct your information,
          (2) change your preferences with respect to communications and other
          information you receive from us, or (3) receive a record of the
          information we have relating to you. Such updates, corrections,
          changes and deletions will have no effect on other information that we
          maintain, or information that we have provided to third parties in
          accordance with this Privacy Policy prior to such update, correction,
          change or deletion.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Sale of Business</span>
      </p>
      <p className="c2">
        <span className="c0">
          We reserve the right to transfer information to a third party in the
          event of a sale, merger or other transfer of all or substantially all
          of the assets of zopfind or any of its Corporate Affiliates (as
          defined herein), or that portion of zopfind or any of its Corporate
          Affiliates to which the Service relates, or in the event that we
          discontinue our business or file a petition or have filed against us a
          petition in bankruptcy, reorganization or similar proceeding, provided
          that the third party agrees to adhere to the terms of this Privacy
          Policy.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Affiliates</span>
      </p>
      <p className="c2">
        <span className="c0">
          We may disclose information (including personal information) about you
          to our Corporate Affiliates. For purposes of this Privacy Policy,
          &quot;Corporate Affiliate&quot; means any person or entity which
          directly or indirectly controls, is controlled by or is under common
          control with zopfind, whether by ownership or otherwise. Any
          information relating to you that we provide to our Corporate
          Affiliates will be treated by those Corporate Affiliates in accordance
          with the terms of this Privacy Policy.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Governing Law</span>
      </p>
      <p className="c2">
        <span className="c0">
          This Privacy Policy is governed by the laws of India without regard to
          its conflict of laws provision. You consent to the exclusive
          jurisdiction of the courts in connection with any action or dispute
          arising between the parties under or in connection with this Privacy
          Policy except for those individuals who may have rights to make claims
          under Privacy Shield, or the Indian framework.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          The laws of India, excluding its conflicts of law rules, shall govern
          this Agreement and your use of the website. Your use of the website
          may also be subject to other local, state, national, or international
          laws.
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          By using zopfind or contacting us directly, you signify your
          acceptance of this Privacy Policy. If you do not agree to this Privacy
          Policy, you should not engage with our website, or use our services.
          Continued use of the website, direct engagement with us, or following
          the posting of changes to this Privacy Policy that do not
          significantly affect the use or disclosure of your personal
          information will mean that you accept those changes.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Your Consent</span>
      </p>
      <p className="c2">
        <span className="c0">
          We&#39;ve updated our&nbsp;Privacy Policy&nbsp;to provide you with
          complete transparency into what is being set when you visit our site
          and how it&#39;s being used. By using our website, registering an
          account, or making a purchase, you hereby consent to our Privacy
          Policy and agree to its terms.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Links to Other Websites</span>
      </p>
      <p className="c2">
        <span className="c0">
          This Privacy Policy applies only to the Services. The Services may
          contain links to other websites not operated or controlled by zopfind.
          We are not responsible for the content, accuracy or opinions expressed
          in such websites, and such websites are not investigated, monitored or
          checked for accuracy or completeness by us. Please remember that when
          you use a link to go from the Services to another website, our Privacy
          Policy is no longer in effect. Your browsing and interaction on any
          other website, including those that have a link on our platform, is
          subject to that website&rsquo;s own rules and policies. Such third
          parties may use their own cookies or other methods to collect
          information about you.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Cookies</span>
      </p>
      <p className="c2">
        <span className="c0">
          zopfind uses &quot;Cookies&quot; to identify the areas of our website
          that you have visited. A Cookie is a small piece of data stored on
          your computer or mobile device by your web browser. We use Cookies to
          enhance the performance and functionality of our website but are
          non-essential to their use. However, without these cookies, certain
          functionality like videos may become unavailable or you would be
          required to enter your login details every time you visit the website
          as we would not be able to remember that you had logged in previously.
          Most web browsers can be set to disable the use of Cookies. However,
          if you disable Cookies, you may not be able to access functionality on
          our website correctly or at all. We never place Personally
          Identifiable Information in Cookies.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">
          Blocking and disabling cookies and similar technologies
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          Wherever you&#39;re located you may also set your browser to block
          cookies and similar technologies, but this action may block our
          essential cookies and prevent our website from functioning properly,
          and you may not be able to fully utilize all of its features and
          services. You should also be aware that you may also lose some saved
          information (e.g. saved login details, site preferences) if you block
          cookies on your browser. Different browsers make different controls
          available to you. Disabling a cookie or category of cookie does not
          delete the cookie from your browser, you will need to do this yourself
          from within your browser, you should visit your browser&#39;s help
          menu for more information.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Kids&#39; Privacy</span>
      </p>
      <p className="c2">
        <span className="c0">
          Kids under 13 years of age should take permission from your parents or
          guardian to use our website. Parental consent is required for kids
          under 13 years of age to use our website. If You are a parent or
          guardian and You are aware that Your child has provided Us with
          Personal Data without your permission, please contact Us. If We become
          aware that We have collected Personal Data from anyone under the age
          of 13 without verification of parental consent, We take steps to
          remove that information from Our servers.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Changes To Our Privacy Policy</span>
      </p>
      <p className="c2">
        <span className="c0">
          We may change our Service and policies, and we may need to make
          changes to this Privacy Policy so that they accurately reflect our
          Service and policies. Unless otherwise required by law, we will notify
          you (for example, through our Service) before we make changes to this
          Privacy Policy and give you an opportunity to review them before they
          go into effect. Then, if you continue to use the Service, you will be
          bound by the updated Privacy Policy. If you do not want to agree to
          this or any updated Privacy Policy, you can delete your account.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Third-Party Services</span>
      </p>
      <p className="c2">
        <span className="c0">
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          (&quot;Third- Party Services&quot;).
        </span>
      </p>
      <p className="c2">
        <span className="c0">
          You acknowledge and agree that zopfind shall not be responsible for
          any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. zopfind does not assume and shall not
          have any liability or responsibility to you or any other person or
          entity for any Third-Party Services. Third-Party Services and links
          thereto are provided solely as a convenience to you and you access and
          use them entirely at your own risk and subject to such third parties'
          terms and conditions.
        </span>
      </p>
      <p className="c2">
        <span className="c0 head">Contact Us</span>
      </p>
      <p className="c2">
        <span className="c0">
          Don&#39;t hesitate to contact us if you have any questions.
        </span>
      </p>
      <p className="c2">
        <span className="c0">&nbsp;-Via Email: &nbsp;support@zopfind.com</span>
      </p>
    </>
  );
}
export default PrivacyAndPolicyComman;
