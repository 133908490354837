import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import _ from "lodash";
import { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { toast } from "react-toastify";


function Virtual(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Virtual Consultation
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessVirtualAppointmentVisibility(
                !props.businessVirtualAppointmentVisibility
              )
            }
            checked={props.businessVirtualAppointmentVisibility}
          />
          <br />
          <br />
          <IoLogoWhatsapp
            fill={
              props.businessVirtualAppointmentVisibility ? "#55CD6C" : "#ddd"
            }
            className="whatsapp-logo"
          />
          <button
            className="done-btn full-width-btn"
            onClick={() => {
              if (
                !_.isEmpty(props.businessWhatsapp) &&
                props.businessWhatsappVisibility === true
              ) {
                props.updateDetails({
                  business_virtual_appointment_visibility:
                    props.businessVirtualAppointmentVisibility,
                });
              } else {
                setAlertMessgae("Please add and enable your whatsapp!");
                setShowAlert("block");
              }
            }}
          >
            Done
          </button>
          <br />
          <p>You need to add Whatsapp</p>
        </div>
      </div>
    </>
  );
}

export default Virtual;