import "./Privacy.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useHistory } from "react-router-dom";
import PrivacyAndPolicyComman from "../CommonComponets/PrivacyAndPolicyComman";

function PrivacyAndPolicy(props) {
  const history = useHistory();
  const goBackLink = () => {
    history.goBack();
  };
  return (
    <div className="c5 privacyAndPolicy">
      <div style={{ border: "none" }} className="back-div">
        <Link onClick={goBackLink} to="" className="link">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <PrivacyAndPolicyComman />
    </div>
  );
}
export default PrivacyAndPolicy;
