import moment from "moment";
import _ from "lodash";

export const randomColour = () => {
  const color_list = ["#FF3366", "#22CC88", "#0099FF", "#FFCC02"];
  return color_list[Math.floor(Math.random() * 4)];
};

export const getWeekDay = (dayNum) => {
  const days = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday",
  };
  return days[dayNum];
};

export const isAvailable = (businessAvailableHoursSingleObj) => {
  if (moment().format("E") - 1 !== businessAvailableHoursSingleObj["day"])
    return false;
  return businessAvailableHoursSingleObj["closed"]
    ? false
    : businessAvailableHoursSingleObj["all_day"]
    ? true
    : moment() -
        moment(businessAvailableHoursSingleObj["end_time"], "HH:mm:ss") <
        0 &&
      moment() -
        moment(businessAvailableHoursSingleObj["start_time"], "HH:mm:ss") >
        0;
};

export const checkAvailability = (businessAvailableHours) => {
  if (
    businessAvailableHours != null &&
    typeof businessAvailableHours != "undefined" &&
    !_.isEmpty(businessAvailableHours) &&
    businessAvailableHours
  ) {
    const date_obj = businessAvailableHours[moment().format("E") - 1];

    const available = date_obj["close"]
      ? false
      : date_obj["all_day"]
      ? true
      : moment() - moment(date_obj["end_time"], "HH:mm:ss") < 0 &&
        moment() - moment(date_obj["start_time"], "HH:mm:ss") > 0;

    return available ? (
      <>
        <span className={"availability-status available"}>Available</span>
        {date_obj["all_day"] ? (
          <span className={"availability-time"}> 24 hours</span>
        ) : (
          <span className={"availability-time"}>{`${moment(
            businessAvailableHours[moment().format("E") - 1]["start_time"],
            "HH:mm:ss"
          ).format("HH:mm a")} - ${moment(
            businessAvailableHours[moment().format("E") - 1]["end_time"],
            "HH:mm:ss"
          ).format("hh:mm a")}`}</span>
        )}
      </>
    ) : (
      <>
        <span className={"availability-status closed"}>Closed</span>
        <span className={"availability-time"}>
          {date_obj["closed"]
            ? "for the day"
            : `${moment(
                businessAvailableHours[moment().format("E") - 1]["start_time"],
                "HH:mm:ss"
              ).format("hh:mm a")} - ${moment(
                businessAvailableHours[moment().format("E") - 1]["end_time"],
                "HH:mm:ss"
              ).format("HH:mm a")}`}
        </span>
      </>
    );
  }
};
export const categoriesDisplay = (categories_set,fromWhichPage) => {

    const fromPage =  fromWhichPage
    let new_cat = [];
    for (let cat in categories_set) {
      new_cat.push(categories_set[cat].split(" ").join("\u00A0"));
    }
    if(fromPage===false){
      new_cat.reverse()
    }
    return new_cat.join(", ");
  };