import WhyWebAppComman from "../CommonComponets/WhyWebAppComman";
import "../../utils/common.css"

function WhyWebApp(props) {
  return <div className="body-container" >
    <WhyWebAppComman back="/how-to-install" />;
  </div>
}

export default WhyWebApp;
