import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { getUserBusinessDetails } from "../../services/BusinessService";
import { updateBusinessProfile } from "../../services/BusinessRegistration";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@material-ui/core/TextField";
import Alert from "../Alert/Alert";
import _ from "lodash";
import "../../utils/common.css";

class EditBusinessName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessName: "Create Business Name",
      showLoader: "display",
      showProfile: "none",
      is_business_user: false,
      alertErrorMsg: "Please fill the input",
      displayAlert: "none",
      id: null,
    };
  }
  componentDidMount() {
    getUserBusinessDetails()
      .then((res) => {
        let data = res.data[0];
        this.setState({
          businessName: data["business_name"],
          showLoader: "none",
          showProfile: true,
          id: data["id"],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  changeBusinessName = () => {
    if (_.isEmpty(this.state.businessName)) {
      this.setState({
        displayAlert: true,
        alertErrorMsg: "Business name can't be empty!",
      });
      return;
    }
    if (this.state.businessName.length < 3 || this.state.businessName > 100) {
      this.setState({
        displayAlert: true,
        alertErrorMsg:
          "Invalid business name! Accept only min 3 char and max 100 char long name",
      });
      return;
    }
    this.setState({
      displayAlert: "none",
    });
    let body = {
      business_name: this.state.businessName,
    };
    updateBusinessProfile(body, this.state.id).then((res) => {
      this.setState({
        businessName: res.data["business_name"],
        showLoader: "none",
        showProfile: true,
      });
      toast.info("Business Name Changed 🤗");
    });
  };

  render() {
    return (
      <div className="body-container-footer">
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: this.state.showLoader }}
          className={"loader"}
        />
        <div
          className="profile-page"
          style={{ display: this.state.showProfile }}
        >
          <div className="back-div">
            <Link to="/business/edit-profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <div className="other-page-element-container">
            <div className="other-page-element page-element withoutmargin">
              <h2 className={"title-color title your-business-name"}>
                Your Business Name
              </h2>
              <Alert
                value={this.state.alertErrorMsg}
                show={this.state.displayAlert}
              />
              <ToastContainer />
              <TextField
                label="Business Name"
                variant="outlined"
                id="business_name"
                className="full-width-input custom-material-input update-label top-label-business-name"
                inputProps={{ maxLength: 100, minLength: 3 }}
                type="text"
                placeholder="Business Name"
                onChange={(e) =>
                  this.setState({ businessName: e.target.value })
                }
                value={this.state.businessName}
              />
              {/* <input
                type="text"
                className="full-width-input"
                placeholder="Business Name"
                value={this.state.businessName}
                onChange={(e) =>
                  this.setState({
                    businessName: e.target.value,
                  })
                }
              /> */}
              <button
                className="done-btn full-width-btn"
                onClick={this.changeBusinessName.bind(this)}
              >
                Change
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditBusinessName;
