import "./BusinessRegistration.css";
import "../../utils/common.css";
import { useLocation } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";
import Switch from "react-switch";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Alert from "../Alert/Alert";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import { getUserBusinessDetails } from "../../services/BusinessService";
import {
  updateBusinessProfile,
  updateBusinessTimeing,
} from "../../services/BusinessRegistration";

import Contact from "./BusinessDetails/Contact";
import Location from "./BusinessDetails/Location";
import ShareLink from "./BusinessDetails/ShareLink";
import Whatsapp from "./BusinessDetails/Whatsapp";
import Youtube from "./BusinessDetails/Youtube";
import Facebook from "./BusinessDetails/Facebook";
import Instagram from "./BusinessDetails/Instagram";
import Twitter from "./BusinessDetails/Twitter";
import Linkedin from "./BusinessDetails/Linkedin";
import Skype from "./BusinessDetails/Skype";
import Website from "./BusinessDetails/Website";
import TodayOffer from "./BusinessDetails/TodayOffer";
import TodayDeal from "./BusinessDetails/TodayDeal";
import BookAppointment from "./BusinessDetails/BookAppointment";
import Virtual from "./BusinessDetails/Virtual";
import Services from "./BusinessDetails/Services";
import Features from "./BusinessDetails/Features";
import Health from "./BusinessDetails/Health";
import Home from "./BusinessDetails/Home";
// import Available from "./BusinessDetails/Available";

import BusinessAddress from "../BusinessAddress/BusinessAddress";
import BusinessTiming from "../BusinessTiming/BusinessTiming";
import AddMenuDetails from "./BusinessDetails/AddMenu/AddMenuDetails";

function BusinessDetails() {
  const location = useLocation();
  const [openDailog, setOpenDailog] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessMobileNumber, setBusinessMobileNumber] = useState("");
  const [businessLocationLink, setBusinessLocationLink] = useState("");
  const [businessWhatsapp, setBusinessWhatsapp] = useState("");
  const [businessYoutube, setBusinessYoutube] = useState("");
  const [businessFacebook, setBusinessFacebook] = useState("");
  const [businessInstagram, setBusinessInstagram] = useState("");
  const [businessTwitter, setBusinessTwitter] = useState("");
  const [businessLinkedin, setBusinessLinkedin] = useState("");
  const [businessSkype, setBusinessSkype] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");

  const [businessServices, setBusinessServices] = useState([]);
  const [businessFeature, setBusinessFeature] = useState([]);
  const [businessTiming, setBusinessTiming] = useState([]);

  const [service, setService] = useState("");
  const [feature, setFeature] = useState("");
  const [health, setHealth] = useState("");
  const [businessHealthAndSafety, setBusinessHealthAndSafety] = useState([]);

  const [showLoader, setShowLoader] = useState("block");
  const [showDetails, setShowDetails] = useState("none");
  const [deleteElement, setDeleteElement] = useState(null);

  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );

  // city, area, locality
  const [businessArea, setBusinessArea] = useState(null);
  const [businessCity, setBusinessCity] = useState(null);
  const [businessState, setBusinessState] = useState(null);
  const [businessPincode, setBusinessPincode] = useState(null);

  // address
  const [businessAreaLine1, setBusinessAreaLine1] = useState(null);
  const [businessAreaLine2, setBusinessAreaLine2] = useState(null);
  const [businessAreaLine3, setBusinessAreaLine3] = useState(null);

  // Visibility
  const [businessMobileNumberVisibility, setBusinessMobileNumberVisibility] =
    useState(null);
  const [businessLocationLinkVisibility, setBusinessLocationLinkVisibility] =
    useState(false);
  const [businessWhatsappVisibility, setBusinessWhatsappVisibility] =
    useState(false);
  const [businessYoutubeVisibility, setBusinessYoutubeVisibility] =
    useState(false);
  const [businessFacebookVisibility, setBusinessFacebookVisibility] =
    useState(false);
  const [businessInstagramVisibility, setBusinessInstagramVisibility] =
    useState(false);
  const [businessTwitterVisibility, setBusinessTwitterVisibility] =
    useState(false);
  const [businessLinkedinVisibility, setBusinessLinkedinVisibility] =
    useState(false);
  const [businessSkypeVisibility, setBusinessSkypeVisibility] = useState(false);
  const [businessWebsiteVisibility, setBusinessWebsiteVisibility] =
    useState(false);
  const [
    businessBookAppointmentVisibility,
    setBusinessBookAppointmentVisibility,
  ] = useState(false);
  const [
    businessVirtualAppointmentVisibility,
    setBusinessVirtualAppointmentVisibility,
  ] = useState(false);

  const [businessHomeDelieveryVisibility, setBusinessHomeDelieveryVisibility] =
    useState(false);

  const [businessEmailVisibility, setBusinessEmailVisibility] = useState(false);
  const [businessAddMenuDetailsVisibility, setBusinessAddMenuDetailsVisibility] = useState(false);
  const [anbusinessMobileNumberVisibility, setAnbusinessMobileNumberVisibility] = useState(false)

  // const focusInputField = input => {
  // 	if (input) {
  // 		setTimeout(() => { input.focus() }, 1);
  // 	}
  // };

  // useEffect(() => {
  //   if (!JSON.parse(localStorage.getItem("location"))){

  //   }
  // }, [])

  useEffect(() => {
    getUserBusinessDetails().then((res) => {
      let data = res.data[0];
      // console.log("data",data);
      setBusinessId(data["id"]);
      setBusinessMobileNumber(data["business_mobile_number"]);
      setBusinessLocationLink(data["business_location_link"]);
      setBusinessWhatsapp(data["business_whatsapp"]);
      setBusinessYoutube(data["business_youtube"]);
      setBusinessFacebook(data["business_facebook"]);
      setBusinessInstagram(data["business_instagram"]);
      setBusinessTwitter(data["business_twitter"]);
      setBusinessLinkedin(data["business_linkedin"]);
      setBusinessSkype(data["business_skype"]);
      setBusinessWebsite(data["business_website"]);
      setBusinessEmail(res.data["business_email"]);

      setBusinessServices(data["business_services"]);
      setBusinessFeature(data["business_features_highlight"]);
      setBusinessHealthAndSafety(data["business_health_and_safety"]);

      //business address
      setBusinessArea(data["business_area"]);
      setBusinessPincode(data["business_zipcode"]);
      setBusinessCity(data["business_city"]);
      setBusinessState(data["business_state"]);
      setBusinessAreaLine1(data["business_address_line1"]);
      setBusinessAreaLine2(data["business_address_line2"]);
      setBusinessAreaLine3(data["business_address_line3"]);

      // Visibility
      setBusinessMobileNumberVisibility(data["business_mobile_visibility"]);
      setBusinessLocationLinkVisibility(
        data["business_location_link_visibility"]
      );
      setBusinessWhatsappVisibility(data["business_whatsapp_visibility"]);
      setBusinessYoutubeVisibility(data["business_youtube_visibility"]);
      setBusinessFacebookVisibility(data["business_facebook_visibility"]);
      setBusinessInstagramVisibility(data["business_instagram_visibility"]);
      setBusinessTwitterVisibility(data["business_twitter_visibility"]);
      setBusinessLinkedinVisibility(data["business_linkedin_visibility"]);
      setBusinessSkypeVisibility(data["business_skype_visibility"]);
      setBusinessWebsiteVisibility(data["business_website_visibility"]);
      setBusinessBookAppointmentVisibility(
        data["business_book_appointment_visibility"]
      );
      setBusinessVirtualAppointmentVisibility(
        data["business_virtual_appointment_visibility"]
      );
      setBusinessHomeDelieveryVisibility(data["business_home"]);
      setBusinessEmailVisibility(res.data["business_email_visibility"]);

      setShowLoader("none");
      setShowDetails(true);

      setBusinessTiming(data["business_timeing"]);
      setAnbusinessMobileNumberVisibility(data["business_mobile_visibility"])

      localStorage.setItem("business_mobile_visibility", data["business_mobile_visibility"])
      
      localStorage.setItem("business_location_link_visibility", data["business_location_link_visibility"])
      localStorage.setItem("business_youtube_visibility", data["business_youtube_visibility"])
      localStorage.setItem("business_facebook_visibility", data["business_facebook_visibility"])
      localStorage.setItem("business_instagram_visibility", data["business_instagram_visibility"])
      localStorage.setItem("business_twitter_visibility", data["business_twitter_visibility"])
      localStorage.setItem("business_linkedin_visibility", data["business_linkedin_visibility"])
      localStorage.setItem("business_skype_visibility", data["business_skype_visibility"])
      localStorage.setItem("business_website_visibility", data["business_website_visibility"])

    });
   
  }, []);

  const updateDetails = async (body) => {
    await updateBusinessProfile(body, businessId);
    console.log(body)
    await toast.info("Details Updated 🤗");
    await setService("");
    await setHealth("");
    await setFeature("");

  };

  // const MenuupdateDetails = async (body) => {

  // }


  const handleClose = (e) => {
    setDeleteElement(null);
    setOpenDailog(false);
  };

  const handleConfirmClose = (e) => {
    if (deleteElement != null) {
      if (deleteElement.elem_type === "service") {
        setBusinessServices((arr) =>
          arr.filter((each) => each !== deleteElement.elem)
        );

        updateDetails({
          business_services: businessServices.filter(
            (each) => each !== deleteElement.elem
          ),
        });
      } else if (deleteElement.elem_type === "highlight") {
        setBusinessFeature((arr) =>
          arr.filter((each) => each !== deleteElement.elem)
        );

        updateDetails({
          business_features_highlight: businessFeature.filter(
            (each) => each !== deleteElement.elem
          ),
        });
      } else if (deleteElement.elem_type === "health") {
        setBusinessHealthAndSafety((arr) =>
          arr.filter((each) => each !== deleteElement.elem)
        );
        updateDetails({
          business_health_and_safety: businessHealthAndSafety.filter(
            (each) => each !== deleteElement.elem
          ),
        });
      }
    }
    setDeleteElement(null);
    setOpenDailog(false);
  };

  const returnComponent = () => {
    if (location.state.componentString === "email") {
      return (
        <>
          <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
            Add Email
          </h2>
          <Alert value={alertMessage} show={showAlert} />
          <div className="profile-page-element-container">
            <div className="business-element page-element">
              <Switch
                onColor="#1777F7"
                uncheckedIcon={false}
                checkedIcon={false}
                onChange={() =>
                  setBusinessEmailVisibility(!businessEmailVisibility)
                }
                checked={businessEmailVisibility}
              />
              <br />
              <br />
              <TextField
                inputRef={(input) => input && input.focus()}
                autoFocus="true"
                label="Email"
                variant="outlined"
                id="email"
                className="full-width-input custom-material-input"
                type="text"
                inputProps={{ maxLength: 30, minLength: 3 }}
                placeholder="Email"
                onChange={(e) => setBusinessEmail(e.target.value)}
                value={businessEmail}
              />
              <button
                className="done-btn full-width-btn"
                onClick={async () => {
                  if (
                    businessEmailVisibility === true &&
                    (businessEmail === "" || businessEmail === null)
                  ) {
                    setAlertMessgae("Please enter the email!");
                    setShowAlert(true);
                  } else if (
                    businessEmail.length > 30 ||
                    businessEmail.length < 3
                  ) {
                    setAlertMessgae(
                      "Invalid email! Accept only min 3 char and max 30 char long email"
                    );
                    setShowAlert(true);
                  } else {
                    setShowAlert("none");
                    await updateDetails({
                      business_email: businessEmail,
                      business_email_visibility: businessEmailVisibility,
                    });
                  }
                }}
              >
                Done
              </button>
            </div>
          </div>
        </>
      );
    } else if (location.state.componentString === "contact") {
      return (
        <Contact
          setBusinessMobileNumberVisibility={setBusinessMobileNumberVisibility}
          businessMobileNumberVisibility={businessMobileNumberVisibility}
          setBusinessMobileNumber={setBusinessMobileNumber}
          businessMobileNumber={businessMobileNumber}
          updateDetails={updateDetails}
          visibile={anbusinessMobileNumberVisibility}
          visible={businessMobileNumberVisibility}
        />
      );
    } else if (location.state.componentString === "location") {
      return (
        <Location
          setBusinessLocationLinkVisibility={setBusinessLocationLinkVisibility}
          businessLocationLinkVisibility={businessLocationLinkVisibility}
          setBusinessLocationLink={setBusinessLocationLink}
          businessLocationLink={businessLocationLink}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "link") {
      return <ShareLink businessId={businessId} />;
    } else if (location.state.componentString === "whatsapp") {
      return (
        <Whatsapp
          setBusinessWhatsappVisibility={setBusinessWhatsappVisibility}
          businessWhatsappVisibility={businessWhatsappVisibility}
          setBusinessWhatsapp={setBusinessWhatsapp}
          businessWhatsapp={businessWhatsapp}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "youtube") {
      return (
        <Youtube
          setBusinessYoutubeVisibility={setBusinessYoutubeVisibility}
          businessYoutubeVisibility={businessYoutubeVisibility}
          setBusinessYoutube={setBusinessYoutube}
          businessYoutube={businessYoutube}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "facebook") {
      return (
        <Facebook
          setBusinessFacebookVisibility={setBusinessFacebookVisibility}
          businessFacebookVisibility={businessFacebookVisibility}
          setBusinessFacebook={setBusinessFacebook}
          businessFacebook={businessFacebook}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "instagram") {
      return (
        <Instagram
          setBusinessInstagramVisibility={setBusinessInstagramVisibility}
          businessInstagramVisibility={businessInstagramVisibility}
          setBusinessInstagram={setBusinessInstagram}
          businessInstagram={businessInstagram}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "twitter") {
      return (
        <Twitter
          setBusinessTwitterVisibility={setBusinessTwitterVisibility}
          businessTwitterVisibility={businessTwitterVisibility}
          setBusinessTwitter={setBusinessTwitter}
          businessTwitter={businessTwitter}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "linkedin") {
      return (
        <Linkedin
          setBusinessLinkedinVisibility={setBusinessLinkedinVisibility}
          businessLinkedinVisibility={businessLinkedinVisibility}
          setBusinessLinkedin={setBusinessLinkedin}
          businessLinkedin={businessLinkedin}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "skype") {
      return (
        <Skype
          setBusinessSkypeVisibility={setBusinessSkypeVisibility}
          businessSkypeVisibility={businessSkypeVisibility}
          setBusinessSkype={setBusinessSkype}
          businessSkype={businessSkype}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "website") {
      return (
        <Website
          setBusinessWebsiteVisibility={setBusinessWebsiteVisibility}
          businessWebsiteVisibility={businessWebsiteVisibility}
          setBusinessWebsite={setBusinessWebsite}
          businessWebsite={businessWebsite}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "offer") {
      return <TodayOffer businessId={businessId} />;
    } else if (location.state.componentString === "today_deal") {
      return <TodayDeal businessId={businessId} />;
    } else if (location.state.componentString === "book_appointment") {
      return (
        <BookAppointment
          setBusinessBookAppointmentVisibility={
            setBusinessBookAppointmentVisibility
          }
          businessBookAppointmentVisibility={businessBookAppointmentVisibility}
          businessWhatsappVisibility={businessWhatsappVisibility}
          businessWhatsapp={businessWhatsapp}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "virtual") {
      return (
        <Virtual
          setBusinessVirtualAppointmentVisibility={
            setBusinessVirtualAppointmentVisibility
          }
          businessVirtualAppointmentVisibility={
            businessVirtualAppointmentVisibility
          }
          businessWhatsappVisibility={businessWhatsappVisibility}
          businessWhatsapp={businessWhatsapp}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "services") {
      return (
        <Services
          setBusinessServices={setBusinessServices}
          businessServices={businessServices}
          setService={setService}
          service={service}
          setDeleteElement={setDeleteElement}
          setOpenDailog={setOpenDailog}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "features") {
      return (
        <Features
          setBusinessFeature={setBusinessFeature}
          businessFeature={businessFeature}
          setFeature={setFeature}
          feature={feature}
          setDeleteElement={setDeleteElement}
          setOpenDailog={setOpenDailog}
          updateDetails={updateDetails}
        />
      );
    } else if (location.state.componentString === "health") {
      return (
        <Health
          setBusinessHealthAndSafety={setBusinessHealthAndSafety}
          businessHealthAndSafety={businessHealthAndSafety}
          setHealth={setHealth}
          health={health}
          setDeleteElement={setDeleteElement}
          setOpenDailog={setOpenDailog}
          updateDetails={updateDetails}
          businessId={businessId}
        />
      );
    } else if (location.state.componentString === "home") {
      return (
        <Home
          setBusinessHomeDelieveryVisibility={
            setBusinessHomeDelieveryVisibility
          }
          businessHomeDelieveryVisibility={businessHomeDelieveryVisibility}
          updateDetails={updateDetails}
          businessId={businessId}
        />
      );
    } else if (location.state.componentString === "available") {
      const changeBusinessTiming = (obj) => {
        const data = businessTiming;
        data[obj["day"] - 1] = obj;
        setBusinessTiming([...data]);
      };

      return (
        // <Available
        // 	setBusinessTiming ={setBusinessTiming}
        // 	businessTiming={businessTiming}
        // 	updateDetails={updateDetails}
        // />
        <>
          <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
            Business Timing
          </h2>
          <div
            style={{
              display: "table",
              width: "90%",
              margin: "0 auto 70px",
            }}
          >
            <div className={"timing-details"}>
              <div className="weekday-detail timing-header">
                <div>Weekdays</div>
                <div className="to-from-time">
                  <div
                    style={{
                      width: "50px",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  >
                    From
                  </div>
                  <div
                    style={{
                      width: "50px",
                      justifyContent: "center",
                      marginLeft: "10px",
                    }}
                  >
                    To
                  </div>
                </div>
                <div>24 hours</div>
              </div>
              {_.map(
                _.filter(businessTiming, (timing) => timing["day"] <= 5),
                (obj) => {
                  return (
                    <BusinessTiming
                      key={obj["day"] + "sss"}
                      id={obj["id"]}
                      userId={obj["business_user"]}
                      day={obj["day"]}
                      startTime={obj["start_time"]}
                      endTime={obj["end_time"]}
                      allDay={obj["all_day"]}
                      openClose={obj["close"]}
                      updateDetail={changeBusinessTiming}
                    />
                  );
                }
              )}
            </div>
            <div className={"timing-details"} style={{ marginTop: "15px" }}>
              <div className="weekday-detail timing-header">
                <div>Weekends</div>
                <div className="to-from-time">
                  <div
                    style={{
                      width: "72px",
                      justifyContent: "center",
                    }}
                  >
                    From
                  </div>
                  <div
                    style={{
                      width: "72px",
                      justifyContent: "center",
                    }}
                  >
                    To
                  </div>
                </div>
                <div>24 hours</div>
              </div>
              {_.map(
                _.filter(businessTiming, (timing) => timing["day"] >= 6),
                (obj) => {
                  return (
                    <BusinessTiming
                      key={obj["day"] + "sss"}
                      id={obj["id"]}
                      userId={obj["business_user"]}
                      day={obj["day"]}
                      startTime={obj["start_time"]}
                      endTime={obj["end_time"]}
                      allDay={obj["all_day"]}
                      openClose={obj["close"]}
                      updateDetail={changeBusinessTiming}
                    />
                  );
                }
              )}
            </div>
            <div>
              <button
                className="done-btn full-width-btn"
                onClick={async () => {
                  if (businessTiming == null || businessTiming === {}) {
                    setAlertMessgae("Please select the business timing!");
                    setShowAlert("block");
                  } else {
                    setShowAlert("none");
                    await updateBusinessTimeing({
                      timeing_list: businessTiming,
                    });
                    await toast.info("Details Updated 🤗");
                  }
                }}
              >
                Done
              </button>
            </div>
          </div>
        </>
      );
    }
    else if (location.state.componentString === "AddMenuDetails") {
      return (
        <AddMenuDetails 
        setBusinessAddMenuDetailsVisibility={setBusinessAddMenuDetailsVisibility}
        businessAddMenuDetailsVisibility={businessAddMenuDetailsVisibility}
        />
      );
    }
    else if (location.state.componentString === "address") {
      return (
        <>
          <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
            Business Address
          </h2>
          <div className="profile-page-element-container">
            <div className="business-element page-element business-address">
              <BusinessAddress
                businessArea={businessArea}
                businessCity={businessCity}
                businessState={businessState}
                businessPincode={businessPincode}
                businessAreaLine1={businessAreaLine1}
                businessAreaLine2={businessAreaLine2}
                businessAreaLine3={businessAreaLine3}
                updateDetails={updateDetails}
              />
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <div className="body-container-footer">
      <Loader
        type="TailSpin"
        height={"100px"}
        width={"100px"}
        color="#1a73e8"
        style={{ display: showLoader }}
        className={"loader"}
      />

      <div className="profile-page" style={{ display: showDetails }}>
        <div className="back-div">
          <Link to="/business/add-details" className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
        <ToastContainer />
        {returnComponent()}
        <Dialog
          open={openDailog}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Press `Delete` button if you want to delete.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-btn">
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "35px",
                border: "1px solid #a6a6a6",
                marginTop: "20px",
                fontFamily: "Roboto",
                fontSize: "20px",
              }}
              onClick={handleClose}
              color="btn btn-default"
              autoFocus
            >
              Cancel
            </button>
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "35px",
                border: "1px solid #FF3366",
                backgroundColor: "#FF3366",
                color: "#fff",
                marginTop: "20px",
                fontFamily: "Roboto",
                fontSize: "20px",
              }}
              onClick={handleConfirmClose}
              color="btn btn-danger"
            >
              Delete
            </button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default BusinessDetails;
