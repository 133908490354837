import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import fetchCustomerProfile from "../../services/ProfileService";

function AccountPage() {
  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchCustomerProfile().then((res) => {
      setEmail(res.data["email"]);
    });
  }, []);

  const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("firstLoad");
    window.location.href = "/";
  };

  return (
    <>
      <div className="body-container-footer">
        <div className="profile-page">
          <div style={{ border: "none" }} className="back-div">
            <Link to="/setting" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <div className="other-page-element-container">
            <div className="other-page-element light-brown  withoutmargin">
              <h2 id="account-heading" className="title-color title ">
                Account
              </h2>
              <div className="other-element-box">
                <div className="account-detail">
                  <label>Email :</label>
                  <Link to="/setting-account" className="link">
                    <p>{email}</p>
                  </Link>
                </div>
                <div className="margin-30">
                  <Link className="link" onClick={logout}>
                    <p style={{ color: "#6a6a6a" }}>Logout</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountPage;
