import axios from "axios";

const checkTokenPresent = () => {
  if (window.localStorage.getItem("token") !== "") {
    return true;
  } else {
    return false;
  }
};

export const addBusinessUserPartially = async (body) => {
  if (checkTokenPresent()) {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    try {
      let res = await axios.post(
        process.env.REACT_APP_BASE_URL_API +"/api/v1/business/business_user/",
        body,
        config
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  } else {
    window.location.href = "/";
  }
};

export const sendVerificationCode = async () => {
  if (checkTokenPresent()) {
    try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };

      let res = await axios.post(
       process.env.REACT_APP_BASE_URL_API + "/api/v1/business/send_otp/",
        {},
        config
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Send Verification Code");
    }
  } else {
    window.location.href = "/";
  }
};

export const ResendVerificationCode = async () => {
  if (checkTokenPresent()) {
    try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };

      let res = await axios.post(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/resend_otp/",
        {},
        config
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Resend Verification Code");
    }
  } else {
    window.location.href = "/";
  }
};

export const verifyOtp = async (mobile_otp) => {
  if (checkTokenPresent()) {
    try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };

      let res = await axios.post(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/verify_otp/",
        {
          mobile_otp,
        },
        config
      );
      return res;
    } catch (err) {
      throw new Error("Invalid Mobile OTP");
    }
  } else {
    window.location.href = "/";
  }
};

export const getCountOfBusiness = async (zipcode_id, category_id) => {
  if (checkTokenPresent()) {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +
        "/api/v1/business/get_count_of_business_in_zipcode/",
      {
        params: {
          zipcode_id: zipcode_id,
          category_id: category_id,
        },
      },
      config
    );
    console.log(res)
    return res;
  } else {
    window.location.href = "/";
  }
};

export const getOfferOfBusiness = async(businessId) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        business_user:businessId
      }
      
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/customer_offers/",
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const getDealOfBusiness = async(businessId) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        business_user:businessId
      }
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/customer_deals/",
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const updateBusinessProfile = async(body,businessId) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    let res = await axios.patch(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/business_user/" + businessId + "/",
      body,
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const updateOffers = async(body,offerId) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },     
    };
    let res = await axios.patch(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/customer_offers/" + offerId +"/" ,
      body,
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const updateDeal = async(body,dealId) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },     
    };
    let res = await axios.patch(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/customer_deals/" + dealId +"/" ,
      body,
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const updateBusinessTimeing = async(body) =>{
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },     
    };
    let res = await axios.post(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/save_business_timeing/" ,
      body,
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
};

export const checkMobileNumber = async(business_mobile_number) => {
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },  
      params:{
        business_mobile_number:business_mobile_number
      }   
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +"/api/v1/business/business_user/",
      config
    );
    return res;
    } catch (err) {
      console.log(err);
    }
}