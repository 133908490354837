import { useState, useEffect } from "react";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";
import { verifyOtp } from "../../services/BusinessRegistration";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "../Alert/Alert";
import { ResendVerificationCode } from "../../services/BusinessRegistration";
import TextField from "@material-ui/core/TextField";

function MobileVerification(props) {
  const history = useHistory();
  const [mobileOtp, setMobileOtp] = useState("");
  const [alertDisplay, setAlertDisplay] = useState("none");
  const [displayPage, setDisplayPage] = useState("none");
  const verify = async () => {
    if (mobileOtp === "" || mobileOtp.length !== 6) {
      setAlertDisplay("block");
    } else {
      try {
        setAlertDisplay("none");
        await verifyOtp(mobileOtp);
        await history.push("/payment-starter");
      } catch (err) {
        toast.error("Wrong OTP, Please Try Again!!");
      }
    }
  };
  useEffect(() => {
    if (history.location.state && history.location.state.from) {
      if (history.location.state.from === "/business-register-mobile") {
        setDisplayPage("block");
        toast.info("OTP is sent on Provided Mobile Number");
      }
    }
  }, [history.location.state]);
  // passing an empty array as second argument triggers the callback in useEffect only
  // after the initial render thus replicating `componentDidMount` lifecycle behaviour

  return (
    <div className="body-container-footer">
      <div className="profile-page" style={{ display: displayPage }}>
        <div className="back-div">
          <Link
            to={{
              pathname: "/business-register-mobile",
              state: {
                from: history.location.pathname,
              },
            }}
            className="link"
          >
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="25px"
            />
          </Link>
        </div>
        <ToastContainer />
        <Alert value="Please Provide Correct OTP" show={alertDisplay} />
        <div className="other-page-element-container">
          <div className="other-page-element">
            <h2 style={{ marginBottom: "23px", color: "black" }}> OTP</h2>
            <TextField
              autoFocus={true}
              label="OTP"
              variant="outlined"
              id="otp"
              className="full-width-input custom-material-input update-label top-label-otp"
              type="text"
              placeholder="Provide OTP"
              onChange={(e) => setMobileOtp(e.target.value)}
              value={null}
            />
            <Link
              onClick={async () => {
                await ResendVerificationCode();
                await toast.info("OTP is sent on Provided Mobile Number");
              }}
              style={{
                width: "100%",
                textAlign: "left",
              }}
            >
              Resend OTP
            </Link>
            <br />
            <br />
            <button className="done-btn" onClick={verify}>
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileVerification;
