import axios from "axios";

export const getZipcodes = async (zipcode, city_id) => {
  try {
    const config = {
      params:{
        city_name: city_id,
        active:true
      },
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/accounts/zipcode/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const loadZipcodeOptions = async (search, city_id) => {
  return await getZipcodes(search, city_id).then(({ data }) => {
    let options = data.map((zipcode_obj) => ({
      value: zipcode_obj.id,
      label: zipcode_obj.zipcode,
    }));
    return options;
  });
};
