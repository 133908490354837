import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import _ from "lodash";
import { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";

function BookAppointment(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [buttonPressed, setButtonPressed] = useState(JSON.parse(localStorage.getItem('appointment')));
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2
        className={"add-detail-title title-color"}
        style={{ fontWeight: "bold" }}
      >
        Add Book Appointment/Enquire
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessBookAppointmentVisibility(
                !props.businessBookAppointmentVisibility
              )
            }
            checked={props.businessBookAppointmentVisibility}
          />

          <br />
          <IoLogoWhatsapp
            fill={props.businessBookAppointmentVisibility ? "#55CD6C" : "#ddd"}
            className="whatsapp-logo"
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (!_.isEmpty(props.businessWhatsapp) && props.businessWhatsappVisibility === true && props.businessBookAppointmentVisibility ===true) {
                props.updateDetails({
                  business_book_appointment_visibility:
                    props.businessBookAppointmentVisibility,
                });
                localStorage.setItem('appointment','false')
                setButtonPressed(JSON.parse(localStorage.getItem('appointment')));
              }
              else if((props.businessWhatsapp) && props.businessWhatsappVisibility === true && props.businessBookAppointmentVisibility ===false && buttonPressed===false){
                props.updateDetails({
                  business_book_appointment_visibility:
                    props.businessBookAppointmentVisibility,
                });
                localStorage.setItem('appointment','true')
                setButtonPressed(JSON.parse(localStorage.getItem('appointment')));
              }
              else if(props.businessBookAppointmentVisibility === false && buttonPressed===true){
                toast.warning("Button Is Disabled");
              }
              else {
                setAlertMessgae("Please add and enable your whatsapp!");
                setShowAlert("block");
              }
            }}
          >
            Done
          </button>
          <br />
          <p>You need to add Whatsapp</p>
        </div>
      </div>
    </>
  );
}

export default BookAppointment;
