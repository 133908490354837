import Alert from "../../Alert/Alert";
import { TiDeleteOutline } from "react-icons/ti";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import _ from "lodash";

function Features(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Features and Highlights
      </h2>
      <br />
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Features"
            variant="outlined"
            id="feature_highlight"
            className="full-width-input custom-material-input"
            type="text"
            inputProps={{ maxLength: 100, minLength: 1 }}
            placeholder="Add Features"
            onChange={(e) => props.setFeature(e.target.value)}
            value={props.feature}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (
                props.feature == null ||
                _.isEmpty(props.feature) ||
                (props.feature != null && _.isEmpty(props.feature.trim()))
              ) {
                return;
              }
              if (
                props.businessFeature !== null &&
                props.businessFeature !== []
              ) {
                if (
                  _.filter(
                    props.businessFeature,
                    (name) => name.toLowerCase() === props.feature.toLowerCase()
                  ).length > 0
                ) {
                  setAlertMessgae("Feature Already Exist!");
                  setShowAlert("block");
                  return;
                }
                props.setBusinessFeature([
                  ...props.businessFeature,
                  props.feature,
                ]);
                setAlertMessgae("Please Provide All The Details");
                setShowAlert("none");
                await props.updateDetails({
                  business_features_highlight: [
                    ...props.businessFeature,
                    props.feature,
                  ],
                });
              } else {
                props.setBusinessFeature([props.feature]);
                await props.updateDetails({
                  business_features_highlight: [props.feature],
                });
              }
            }}
          >
            Add
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "10px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.businessFeature !== null && props.businessFeature !== [] ? (
              props.businessFeature.map((afeature, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    backgroundColor: "#FAFAFA",
                    margin: "10px",
                    padding: "10px",
                    boxShadow: "0 1px 5px -2px rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <div key={index + "highlight"}>{afeature}</div>
                  <TiDeleteOutline
                    style={{
                      fontSize: "25px",
                      fill: "red",
                      marginTop: "3px",
                      marginLeft: "5px",
                      cursor: "pointer",
                    }}
                    onClick={async (e) => {
                      props.setDeleteElement({
                        elem_type: "highlight",
                        elem: afeature,
                      });
                      props.setOpenDailog(true);
                    }}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
