import "./Home.css";
import "../../utils/common.css";
import HorizontalScroller from "../HorizontalScroller/HorizontalScroller";
import BestDeal from "../BestDeal/BestDeal";
import Offer from "../Offer/Offer";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ZopfindLogo from "../../assets/logo/logo.svg";
import OfferDealFilter from "../OfferDealFilter/OfferDealFilter";
import {
  getDealsAndOffer,
  saveDealsAndOffers,
} from "../../services/HomeService";
import { randomColour } from "../../utils/common";
import fetchCustomerProfile from "../../services/ProfileService";
import { useHistory } from "react-router-dom";
import BlankCard from "../BlankCard/BlankCard";
import {hash} from "../../utils/meta.json"


function Home() {
  const history = useHistory();
  const cardColor = randomColour();
  const [displayFilter, setDisplayFilter] = useState(false);
  const [topDeals, setTopDeals] = useState([]);
  const [dealOfferBtn, setDealOfferBtn] = useState("both");
  const [blankCard, setBlankCard] = useState(false);
  useEffect(() => {
    fetchCustomerProfile().then((res) => {
      if (!res.data["is_profile_completed"]) {
        history.push({
          pathname: "/customer-register-country",
          state: {
            from: history.location.pathname,
          },
        });
      }
    });
    getDealsAndOfferArea();
  }, [history]);

// useEffect(() => {
  //   const Hash = localStorage.getItem("hash")
  //   if (Hash) {
  //     if (Hash !== hash) {
  //       navigator.serviceWorker.controller.postMessage({ action: 'deleteAllCaches' });
  //       localStorage.setItem("hash", Hash)
  //       console.log("cleared cache")
  //     }

  //   } else {
  //     localStorage.setItem("hash", hash)
  //     navigator.serviceWorker.controller.postMessage({ action: 'deleteAllCaches' });
  //     console.log("cleared cache -no hash")
  //   }
  // }, [])

  const getDealsAndOfferArea = () => {
    getDealsAndOffer().then((res) => {
      setTopDeals(res.data);
      if (res.data.length === 0) {
        setBlankCard(true);
      }
    });
  };

  const handleOfferDealFilter = (cat = null) => {
    if (cat != null) {
      this.getDealsAndOffer(cat);
    }
    setDisplayFilter(false);
    handleDealOfferBtn();
  };

  const handleDealOfferBtn = (val) => {
    if (val === "offer") {
      if (dealOfferBtn === "both") {
        setDealOfferBtn("deal");
      } else if (dealOfferBtn === "offer") {
        setDealOfferBtn(null);
      } else if (dealOfferBtn === null) {
        setDealOfferBtn("offer");
      } else {
        setDealOfferBtn("both");
      }
    } else {
      if (dealOfferBtn === "both") {
        setDealOfferBtn("offer");
      } else if (dealOfferBtn === "deal") {
        setDealOfferBtn(null);
      } else if (dealOfferBtn === null) {
        setDealOfferBtn("deal");
      } else {
        setDealOfferBtn("both");
      }
    }
  };

  const AddToWishList = (dealId) => {
    saveDealsAndOffers(dealId).then((res) => {
      if (res && res.data && res.data.msg) {
        toast.info(res.data.msg);
      }
    });
  };

  return (
    <>
      <div className="body-container-footer">
        {displayFilter ? (
          <OfferDealFilter handleOfferDealFilter={handleOfferDealFilter} />
        ) : null}
        <div className="home-container">
          <div
            className="home"
            style={{
              display: displayFilter ? "none" : "flex",
            }}
          >
            <div style={{ height: "100%" }}>
              <h1 className="company-name">
                <img style={{}} alt="Zopfind" src={ZopfindLogo} />
              </h1>
            </div>

            {/* <Search /> */}
            <div className="horizontal-scroller-container">
              <HorizontalScroller />
            </div>
            <h2 style={{ marginBottom: "5px", fontWeight: 600 }}>
              Best Deals And Offers Today
            </h2>
            <div className="best-deals-container">
              {blankCard ? <BlankCard cardColor={cardColor} /> : null}
              {topDeals.length === 0
                ? null
                : topDeals.map((deal) => {
                  return (
                    <React.Fragment key={`${deal["id"]}-deal-offer`}>
                      {(dealOfferBtn === "deal" || dealOfferBtn === "both") &&
                        deal["is_deal"] ? (
                        <BestDeal
                          businessName={deal["business_name"]}
                          businessCategory={deal["business_category"]}
                          dealName={deal["top_deal_name"]}
                          dealActualPrice={deal["top_deal_actual_price"]}
                          dealPrice={deal["top_deal_price"]}
                          dealExpiry={deal["top_deal_expiry"]}
                          cardColor={deal["color_code"]}
                          AddToWishList={AddToWishList}
                          businessId={deal["business_user"]}
                          dealId={deal["id"]}
                          displayAddBtn={true}
                          requestFromBusinessPage={false}
                        />
                      ) : null}
                      {dealOfferBtn === "offer" || dealOfferBtn === "both" ? (
                        !deal["is_deal"] ? (
                          <Offer
                            businessName={deal["business_name"]}
                            businessCategory={deal["business_category"]}
                            offer={deal["business_offer"]}
                            cardColor={deal["color_code"]}
                            AddToWishList={AddToWishList}
                            businessId={deal["business_user"]}
                            dealId={deal["id"]}
                            displayAddBtn={true}
                            requestFromBusinessPage={false}
                          />
                        ) : null
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Home);
