import "./Screens.css";
import "../../utils/common.css";
import { Link, useHistory } from "react-router-dom";
import ZopfindLogo from "../../assets/logo/logo.svg";
import { useEffect, useState } from "react";
import Loader from "react-loader-spinner";

const languages = [
  { language: "English", default: true, id: 1 },
  { id: 2, language: "Hindi", default: false },
];
const year =  new Date().getFullYear()

function FirstScreen() {
  const [showLoader, setShowLoader] = useState(false);
  const [showFirstpage, setShowFirstpage] = useState(false);

  const mediaQueryList = window.matchMedia("(display-mode: standalone)");
  const history = useHistory();

  useEffect(() => {
    if (mediaQueryList.matches) {
      setShowLoader(true);
      setShowFirstpage(false);
      window.localStorage.setItem("firstLoad", true);
      if (
        window.localStorage.getItem("token") !== null &&
        window.localStorage.getItem("token") !== "" &&
        window.localStorage.getItem("token") !== undefined
      ) {
        history.push({
          pathname: "/home",
          state: {
            from: history.location.pathname,
          },
        });
      } else {
        history.push({
          pathname: "/login",
          state: {
            from: history.location.pathname,
          },
        });
      }
    } else {
      setShowLoader(false);
      setShowFirstpage(true);
    }
  }, [mediaQueryList.matches, history]);

  

  const redirectToLogin = () => {
    window.localStorage.setItem("firstLoad", true);

    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "" &&
      window.localStorage.getItem("token") !== undefined
    ) {
      history.push({
        pathname: "/home",
        state: {
          from: history.location.pathname,
          
        },
      });
    } else {
      history.push({
        pathname: "/login",
        state: {
          from: history.location.pathname,
        },
      });
    }
  };
  return (
    // .body-container {
    //   height: 100vh;
    //   width: 100%;
    //   position: fixed;
    //   top: 0;
    //   overflow: scroll;
    // }
    <div className="first" style={{width:"100%"}} >
      <Loader
        type="TailSpin"
        height={"100px"}
        width={"100px"}
        color="#1a73e8"
        style={{ display: showLoader ? "block" : "none" }}
        className={"loader"}
      />

      <div className="height-100 colored-screen" style={{ display: showFirstpage ? "block" : "none" }}>

        <div className="initial-screen-page ">

          <div className="intial-screen-container">

          {/* <select className="f-screen-language" id="language" disabled>

              {languages.map((lang) => {
                if (lang.default) {
                  return (
                    <option key={lang.id} defaultValue={lang.language}>
                      {lang.language}
                    </option>
                  );
                }
                return <option key={lang.id}>{lang.language}</option>;
              })}
            </select>  */}

            <div style={{ height: "100%" }} className="first-screen-container-holder">
              {window.innerWidth < 768 ?
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }} >
                  {/* 1st div */}

                  <div style={{ visibility: "visible" }} className="first-screen-container">
                    <div
                      style={{
                        height: "36px",
                        textAlign: "left",
                        // padding:  " 10px 20px",
                        marginTop: "10px",
                        marginBottom: "30px",
                      }}
                    >
                      <img alt="Zopfind" src={ZopfindLogo} />
                    </div>

                    <div className="content-box">
                      <h2 data-test="header-text-on-home-page"
                        className="f-screen-content"
                        style={{ fontWeight: "800", color: "#000000" }}
                      >
                        Make your
                        <br />
                        business popular
                      </h2>

                      <p
                        className="f-screen-p-content"
                        style={{
                          marginTop: "17px",
                          color: "#000000",
                          fontWeight: "600",
                        }}
                      >
                        {window.innerWidth >= 200 ? (
                          <>
                            {" "}
                            People will find your business
                            <br />
                            very easily now
                          </>
                        ) : (
                          <>
                            {" "}
                            People will find your
                            <br />
                            business very easily now
                          </>
                        )}
                      </p>

                    </div>
                  </div>
                  {/* 2nd div */}
                  <div style={{ visibility: "visible" }} className="first-screen-container-2">
                    <div>
                      <p 
                        id="f-screen-nxt"
                        className="f-screen-next"
                        onClick={redirectToLogin}
                      >
                        Login/SignUp
                      </p>
                      <Link
                        to={{
                          pathname: "/how-to-install",
                          state: { from: history.location.pathname },
                        }}
                      >
                        <button
                          className="blue-btn btn-full-with install-btn"
                          id="install-button"
                          style={{ fontWeight: 900 }}
                        >
                          How to install Web App
                        </button>
                      </Link>
                    </div>
                  </div>
                  
                </div> :

                <>

                <div style={{ visibility: "visible",  position:"relative", bottom: window.innerWidth < 1920 ? "40px" : "" }} className="first-screen-container">
                  
                  <div style={{height: "36px",textAlign: "left",marginTop: "10px",marginBottom: "30px"}}>
                    <img className="first-screen-zopfind" alt="Zopfind" src={ZopfindLogo} />
                  </div>

                  <div className={"content-box"}>
                    <h2 data-test="header-text-on-home-page"
                      className="f-screen-content"
                      style={{ fontWeight: "800", color: "#000000" }}
                    >
                      Make your 
                      <br />
                      business popular
                    </h2>

                    <p  data-test="paragraph-text-on-home-page"
                      className="f-screen-p-content"
                      style={{
                        marginTop: "17px",
                        color: "#000000",
                        fontWeight: "600",
                      }}
                    >
                      {window.innerWidth >= 200 ? (
                        <>
                          {" "}
                          People will find your business
                          <br />
                          very easily now
                        </>
                      ) : (
                        <>
                          {" "}
                          People will find your
                          <br />
                          business very easily now
                        </>
                      )}
                    </p>
                  </div>
                </div>
              {/* 2nd div */}

              <div style={{ visibility: "visible" , position:"relative", bottom:window.innerWidth > 768 && window.innerWidth < 1920 ? "50px" : ""}} className="first-screen-container-2">
                <div>
                  <p
                    data-test="login-and-signup-heading"
                    id={"f-screen-nxt"}
                    className="f-screen-next"
                    onClick={redirectToLogin}
                  >
                    Login/SignUp
                  </p>
                  <Link
                    to={{
                      pathname: "/how-to-install",
                      state: { from: history.location.pathname }
                    }}
                  >
                    <button data-test="how-to-install-button"
                      className="blue-btn btn-full-with install-btn"
                      id="install-button"
                      style={{ fontWeight: 900 }}
                    >
                      How to install Web App
                    </button>
                  </Link>
                </div>
              </div> </>}

            </div>

          </div>

        </div>
        {/* style={{position:"relative",top: window.innerWidth > 768 && window.innerWidth < 1920 ?  "40px" : ""}} */}
        {/* style={{position:"relative", bottom: `-${150 }px`}} */}
        <div  style={{ position: window.innerWidth > 600 ?  "absolute" : "relative", bottom:"20px", width:"100%" }}  >
          <span
            className="bottom-text"
            id="bottomT"
            style={{
              color: "#808285",
              textDecoration: "none",
              fontSize: "12px",
            }}
          >
            zopfind
            <sup
              style={{
                color: "#808285",
                textDecoration: "none",
                fontSize: "9px",
              }}
            >
              TM{" "}
            </sup>
            Copyright &copy; {year}. All Rights Reserved.
          </span>
            <br></br>
          <span
            style={{
              fontSize: "12px",
              color: "#808285",
              textDecoration: "none",
            }}
          >
            <Link to={"/terms-of-service"} className="footer-link" data-test="footer-link-to-terms">
              Terms
            </Link>
            <Link to={"/privacy-policy"} className="footer-link" data-test="footer-link-to-privacy">
              Privacy
            </Link>
            <Link to={"/refund-policy"} className="footer-link" data-test="footer-link-to-refund">
              Refund
            </Link>
            <Link to={"/contact-us"} className="footer-link" data-test="footer-link-to-contact-us">
              Contact Us
            </Link>
            <Link to={"/about-us"} className="footer-link" data-test="footer-link-to-about-us">
              About Us
            </Link>
          </span>

        </div>

      </div>
    </div >
  )
}

export default FirstScreen;
