import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Countries } from "../../Countries";
import Select from "react-select";
import "./CustomerRegistration.css";

function CountrySelection() {
	const history = useHistory();
	const countries = Countries.map((obj) => {
		return { name: obj.country_name, label: obj.country_name };
	});

	const [country, setCountry] = useState("India");
	const [displayPage, setDisplayPage] = useState("none");

	useEffect(() => {
		if (history.location.state && history.location.state.from) {
			if (
				history.location.state.from === "/home" ||
				history.location.state.from === "/signup" ||
				history.location.state.from === "/login" ||
				history.location.state.from === "/customer-register-location"
			) {
				setDisplayPage(true);
			}
		}
	}, [history]);

	const changeCountry = (selected_country) => {
		setCountry(selected_country.name);
	};
	const submit = () => {
		window.localStorage.setItem("country", country);
		history.push({
			pathname: "/customer-register-location",
			state: {
				from: history.location.pathname,
				country:country,
			},
		});
	};
	return (
    <div
      className="profile-page"
      style={{ display: displayPage }}
    >
      <h2>Select Country</h2>
      <div className="profile-page-element-container">
        <div className="profile-page-element">
          <Select
            name="form-field-name"
            className="auto-search-select full-width-select"
            placeholder="Search Country"
            multi={false}
            value={countries[0]}
            options={countries}
            onChange={changeCountry}
          />
          <br />
          <br />
          <button className="done-btn full-width-btn" onClick={submit}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default CountrySelection;
