import "./Profile.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";

function ProblemSubmissionPage() {
  const history = useHistory();
  const [problem, setProblem] = useState("");
  const [file1Upload,setFile1Upload] = useState(false)
  const [file2Upload,setFile2Upload] = useState(false)
  const [problemImage, setProblemImage] = useState({
    1: null,
    2: null,
    3: null,
  });
  let fileBtn = null;
  let fileBtn1 = null;
  let fileBtn2 = null;

  // On file upload (click the upload button)
  const onFileUpload = (indx, event) => {
    setProblemImage({ ...problemImage, [indx]: event.target.files[0] });
    console.log(event.target.id)
    if(event.target.id === "file1"){
      setFile1Upload(true)
    }
    else if(event.target.id === "file2"){
      setFile2Upload(true)
    }
    // console.log(file1Upload)
    // formData.append("file", event.target.files[0], event.target.files[0].name);
  };

  const clickOnDiv = (number) => {
    // setSelectedDiv(number);
    fileBtn.click();
  };
  const clickOnDiv1 = (number) => {
    // setSelectedDiv(number);
    fileBtn1.click();
  };
  const clickOnDiv2 = (number) => {
    // setSelectedDiv(number);
    fileBtn2.click();
  };

  return (
    <div className="profile-page">
      <div className="back-div">
        <Link to="/help-report-problem" className="link">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>

      <h2 className={"title-color"}>Submit Problem</h2>
      <div className="profile-page-element-container">
        <div className="profile-page-element page-element withoutmargin">
          <textarea
            placeholder="Describe your Problem..."
            onChange={(e) => setProblem(e.target.value)}
          />
          <br />
          <div className="problem-attchments">
            <div onClick={(e) => clickOnDiv(1)}>
              {problemImage[1] ? (
                <img src={URL.createObjectURL(problemImage[1])} alt="" />
              ) : (
                <p>+</p>
              )}
            </div>
            
            <div onClick={(e) => clickOnDiv1(2)} >
              {problemImage[2] ? (
                <img src={URL.createObjectURL(problemImage[2])} alt="" />
              ) : (
                <p style={{ color: !file1Upload ? "#cccccc" : null }}>+</p>
              )}
            </div>
            <div onClick={(e) => clickOnDiv2(3)}>
              {problemImage[3] ? (
                <img src={URL.createObjectURL(problemImage[3])} alt="" />
              ) : (
                <p style={{ color: !file2Upload ? "#cccccc" : null }} >+</p>
              )}
            </div>
            <input
              className={"hidden"}
              ref={(e) => (fileBtn = e)}
              type="file"
              name="file1"
              id="file1"
              onChange={(event) => onFileUpload(1, event)}
              multiple={false}
              accept=".gif,.jpg,.jpeg,.png"
            />

            <input
              className={"hidden"}
              ref={(e) => (fileBtn1 = e)}
              type="file"
              name="file2"
              id="file2"
              onChange={(event) => onFileUpload(2, event)}
              multiple={false}
              disabled={file1Upload ? false:true}
              accept=".gif,.jpg,.jpeg,.png"
            />
            <input
              className={"hidden"}
              ref={(e) => (fileBtn2 = e)}
              type="file"
              name="file3"
              id="file3"
              onChange={(event) => onFileUpload(3, event)}
              multiple={false}
              disabled={file2Upload ? false:true}
              accept=".gif,.jpg,.jpeg,.png"
            />
          </div>
          <br />
          <button
            className="done-btn full-width-btn"
            onClick={() => {
              if (problem === "") {
                console.log("Alert");
              } else {
                const subject = history.location.state["subject"];
                const attachmentUrl = URL.createObjectURL(problemImage[1]);
                const mailtoString = `mailto:support@zopfind.com?subject=${subject}&body=${problem}`;
                window.location.href = `${mailtoString}&attachment=${encodeURIComponent(
                  attachmentUrl
                )}`;
              }
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProblemSubmissionPage;
