import "./Footer.css";
import categoryFillIcon from "../../assets/icons/Catagory rounded filled.svg";
import categoryOutlineIcon from "../../assets/icons/Catagory rounded outline.svg";
import hamburgerFillIcon from "../../assets/icons/Hamburger filled.svg";
import hamburgerOutlineIcon from "../../assets/icons/Hamburger outlined.svg";
import homeFillIcon from "../../assets/icons/Home filled.svg";
import homeOutlineIcon from "../../assets/icons/Home outlined.svg";

import { Link, useLocation } from "react-router-dom";

function Footer(props) {
  const location = useLocation();
  return (
    <div
      style={{
        backgroundColor: props.blue && props.mobile ? "white" : "white",
      }}
      className="footer"
    >
      <Link
        to="/home"
        className="footer-icon link"
        onClick={() => window.scrollTo(0, 0)}
      >
        {location.pathname === "/home" ? (
          <img
            src={homeFillIcon}
            alt={"home-fill-icon"}
            className="icon"
            height="24px"
          />
        ) : (
          <img
            src={homeOutlineIcon}
            alt={"home-icon"}
            className="icon"
            height="24px"
          />
        )}
      </Link>
      <Link
        to="/categories"
        className="footer-icon link"
        onClick={() => window.scrollTo(0, 0)}
      >
        {location.pathname === "/categories" ? (
          <img
            src={categoryFillIcon}
            alt={"category-fill-icon"}
            className="icon"
            height="24px"
          />
        ) : (
          <img
            src={categoryOutlineIcon}
            alt={"category-icon"}
            className="icon"
            height="24px"
          />
        )}
      </Link>
      <Link
        to="/profile"
        className="footer-icon link"
        onClick={() => window.scrollTo(0, 0)}
      >
        {location.pathname === "/profile" ||
        location.pathname === "/setting" ||
        location.pathname === "/setting-account" ||
        location.pathname === "/setting-about" ||
        location.pathname === "/my-profile" ||
        location.pathname === "/saved-offer-and-deals" ||
        location.pathname === "/change-country" ||
        location.pathname === "/business/add-details" ||
        location.pathname === "/business/edit-profile" ||
        location.pathname === "/setting-help" ||
        location.pathname === "/copyright" ||
        location.pathname === "/setting-terms-of-service" ||
        location.pathname === "/setting-privacy-policy" ||
        location.pathname === "/business/edit-profile-name" ||
        location.pathname === "/my-business" ||
        (typeof location.pathname === 'string'&& location.pathname.includes("/business-page/")) ? (
          <img
            src={hamburgerFillIcon}
            alt={"hamburger-fill-icon"}
            className="icon"
            height="24px"
          />
        ) : (
          <img
            src={hamburgerOutlineIcon}
            alt={"hamburger-icon"}
            className="icon"
            height="24px"
          />
        )}
      </Link>
    </div>
  );
}

export default Footer;
