function Alert(props) {
	return (
		<div
			style={{
				backgroundColor: "#F8D7DA",
				width: "calc(100% - 20px)",
				color: "#721C24",
				flexDirection: "row",
				justifyContent: "center",
				alignItems: "center",
				display: props.show,
				padding: "10px",
				marginBottom: "10px",
			}}
		>
			{props.value}
		</div>
	);
}

export default Alert;
