import Select from "react-select";
import "./StateCityPincodeField.css";
import _ from "lodash";
import EmptySelectElement from "../CommonComponets/EmptySelectElement";

/**
 *
 * @param {*} props
 * state:
 * city:
 * zipcode:
 *
 * selectedState
 * selectedCity
 * selectedZipcode
 *
 * setSelectedZipcode()
 * setSelectedState()
 * setSelectedCity()
 *
 * @returns
 */

function StateCityPincodeField(props) {
  return (
    <>
      {props.disableLabel ? null : <label from="state">State</label>}
      {props.stateOption.length > 0 ? (
        <Select
          name="state"
          className="auto-search-select mb-2"
          multi={false}
          placeholder="Search and select state..."
          value={
            props.selectedState
              ? props.stateOption[
                  _.findIndex(props.stateOption, function (o) {
                    return (
                      props.selectedState &&
                      o.value === props.selectedState.value
                    );
                  })
                ]
              : null
          }
          options={props.stateOption}
          onChange={(e) => {
            props.setSelectedCity(null);
            return props.setSelectedState(e);
          }}
        />
      ) : (
        <div className="mb-2 full-width-select">
          <EmptySelectElement placeholder="Search and select state..." />
        </div>
      )}

      {props.disableLabel ? null : <label from="city">City</label>}
      {props.cityOption.length > 0 ? (
        <Select
          name="form-field-name"
          className="auto-search-select mb-2"
          placeholder="Search and select city..."
          multi={false}
          value={
            props.selectedCity
              ? props.cityOption[
                  _.findIndex(props.cityOption, function (o) {
                    return (
                      props.selectedCity && o.value === props.selectedCity.value
                    );
                  })
                ]
              : null
          }
          options={props.cityOption}
          onChange={(e) => {
            props.setSelectedZipcode(null);
            return props.setSelectedCity(e);
          }}
        />
      ) : (
        <div className="mb-2 full-width-select">
          <EmptySelectElement placeholder="Search and select city..." />
        </div>
      )}
      {props.disableLabel ? null : <label from="zipcode">Pincode</label>}
      {props.zipcodeOption.length > 0 ? (
        <Select
          name="zipcode"
          className="auto-search-select "
          multi={false}
          value={
            props.selectedZipcode
              ? props.zipcodeOption[
                  _.findIndex(props.zipcodeOption, function (o) {
                    return (
                      props.selectedZipcode &&
                      o.value === props.selectedZipcode.value
                    );
                  })
                ]
              : null
          }
          placeholder="Search and select pincode..."
          options={props.zipcodeOption}
          onChange={(e) => {
            return props.setSelectedZipcode(e);
          }}
        />
      ) : (
        <div className="mb-2 full-width-select">
          <EmptySelectElement placeholder="Search and select pincode..." />
        </div>
      )}
    </>
  );
}

export default StateCityPincodeField;
