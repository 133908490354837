import React, { useEffect,useState } from "react";
import { Box, Modal, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    zIndex: 1,
  };

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "40px",
    boxShadow: 24,
    p: 4,
};

const Add_SubCategory = ({ id, open, handleClose, fetchData,category}) => {
  // console.log(id)
  const [selectIdData, setSelectIdData] = React.useState([]);

const [MenuDetails, setMenuDetails] = useState({
  category: "",
  subCategory:"",
  price:""
});

const getData = async (id) => {
  // console.log("seletId", id);
  const token = window.localStorage.getItem("token");
  

  try {
    const response = await axios.get(
      process.env.REACT_APP_BASE_URL_API +
        `/api/v1/business/menucategories/${id}/`,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );

    if (response.status === 200) {
      // Update the state with the response data
      setSelectIdData(response.data);

      // Set the initial values of MenuDetails with selected data
      setMenuDetails({
        category: response.data.category_name,
      });
      // console.log(MenuDetails.category)
      // console.log(response.data);
    } else {
      console.log("Error fetching menu items");
    }
  } catch (error) {
    console.error("GET request error:", error);
  }
};



// console.log(MenuDetails.category)

const handleInputChange = (event) => {
  const  name = event.target.name;
  const  value = event.target.value;
  // console.log(name,value)

  setMenuDetails((prevMenuDetails) => ({
    ...prevMenuDetails,
    [name]: value,
  }));
};

// const handleInputForSubCategoryAndPrice = () => {
//   console.log(MenuDetails)
// };

const handleMenuDetails = async () => {
  // console.log(MenuDetails)
  const payload = {
    category_name: MenuDetails.category,
    subcategories: [
      {
        sub_category_name: MenuDetails.subCategory,
        price: MenuDetails.price,
      },
    ],
  };
  // console.log("payload", payload);
  const token = window.localStorage.getItem("token");

  const config = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menu-items/",
      payload,
      config
    );

    if (response.data.status === 400) {
      console.log("Error");
    } else if (response.status === 200) {
      toast.success("Menu Details Added Successfully !", {
        autoClose: 2000,
      });
      handleClose();
      fetchData();
    }
  } catch (error) {
    console.error("Post error:", error);
  }
};

useEffect(() => {
  getData(id);
  fetchData();
}, [id]);

console.log()
  return (
    <div>
      {open && <div style={overlayStyle}></div>}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="business-element page-element"
          sx={style}
          textAlign={"center"}
          width={{ xs: 350, md: 600 }}
          position={"relative"}
        >
          <Box
            position="absolute"
            top={20}
            right={30}
            fontWeight={"bold"}
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              zIndex: 1,
            }}
            fontSize={"25px"}
          >
            &times;
          </Box>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{marginTop:"16px"}}>
            Add
          </Typography>
          <br />
          <TextField
            name="category"
            value={MenuDetails.category}
            onChange={handleInputChange}
            autoFocus={true}
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Category"
            fullWidth
            disabled
          />
          <TextField
            name="subCategory"
            onChange={handleInputChange}
            style={{ marginTop: "20px" }}
            autoFocus={true}
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            label="Sub Category"
            placeholder="Sub Category"
            fullWidth
          />
          <TextField
            name="price"
            onChange={handleInputChange}
            style={{ marginTop: "20px" }}
            autoFocus={false}
            variant="outlined"
            className="full-width-input custom-material-input"
            label="Price"
            placeholder="Price"
            fullWidth
          />

          <button
            onClick={handleMenuDetails}
            className="done-btn full-width-btn"
          >
            Add
          </button>
        </Box>
      </Modal>
    </div>
  )
}

export default Add_SubCategory

