import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { toast } from "react-toastify";


function Instagram(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Instagram
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessInstagramVisibility(
                !props.businessInstagramVisibility
              )
            }
            checked={props.businessInstagramVisibility}
          />
          <br />
          <br />
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Instagram"
            variant="outlined"
            id="instagram"
            inputProps={{ maxLength: 150, minLength: 3 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Add Instagram"
            onChange={(e) => props.setBusinessInstagram(e.target.value)}
            value={props.businessInstagram}
            disabled={!props.businessInstagramVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (props.businessInstagramVisibility === false && JSON.parse(localStorage.getItem("business_instagram_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessInstagramVisibility === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_instagram: props.businessInstagram,
                  business_instagram_visibility:
                    props.businessInstagramVisibility,
                });
                localStorage.setItem("business_instagram_visibility",  props.businessInstagramVisibility)

              } else {
                if (
                  props.businessInstagramVisibility === true &&
                  (props.businessInstagram === "" ||
                    props.businessInstagram === null)
                ) {
                  setAlertMessgae("Please fill the input!");
                  setShowAlert("block");
                } else if (
                  props.businessInstagram.length < 3 ||
                  props.businessInstagram.length > 150 ||
                  props.businessInstagram.match(
                    /^(https?:\/\/(?:www\.)?instagram\.com\/([^/?#&]+)).*/g
                  ) == null
                ) {
                  setAlertMessgae(
                    "Invalid input! accept only min 3 and max 150 char"
                  );
                  setShowAlert("block");
                } else {
                  setShowAlert("none");
                  await props.updateDetails({
                    business_instagram: props.businessInstagram,
                    business_instagram_visibility:
                      props.businessInstagramVisibility,
                  });
                localStorage.setItem("business_instagram_visibility",  props.businessInstagramVisibility)

                }
              }
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default Instagram;