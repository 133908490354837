import "./BusinessTiming.css";
import TimeRange from "react-time-range";
import moment from "moment";
import Switch from "react-switch";
import { useRef } from "react";
import { getWeekDay } from "../../utils/common";

function BusinessTiming(props) {
	const timeRange = useRef(null);
	const startTimeChange = (val) => {
		props.updateDetail({
			id:props.id,
			business_user:props.userId,
			day: props.day,
			start_time: moment(val["startTime"]).format("HH:mm:ss"),
			end_time: props.endTime,
			all_day: props.allDay,
			close: props.openClose,
		});
	};
	const endTimeChange = (val) => {
		props.updateDetail({
			id:props.id,
			business_user:props.userId,
			day: props.day,
			start_time: props.startTime,
			end_time: moment(val["endTime"]).format("HH:mm:ss"),
			all_day: props.allDay,
			close: props.openClose,
		});
	};
	const changeCheckBox = (val) => {
		props.updateDetail({
			id:props.id,
			business_user:props.userId,
			day: props.day,
			start_time: props.startTime,
			end_time: props.endTime,
			all_day: val,
			close: props.openClose,
		});
	};

	const openCloseHandler = (val) => {
		props.updateDetail({
			id:props.id,
			business_user:props.userId,
			day: props.day,
			start_time: props.startTime,
			end_time: props.endTime,
			all_day: props.allDay,
			close: val,
		});
	};
	let className = props.allDay ? "to-from-time disable" : "to-from-time";
	className = props.openClose ? className + " deactive" : className;
	return (
		<div className="weekday-detail">
			<div>
				<button
					className={
						!props.openClose
							? "day-btn active-day-btn"
							: "day-btn close-day-btn"
					}
					onClick={() => openCloseHandler(!props.openClose)}
				>
					{getWeekDay(parseInt(props.day)-1)}
				</button>
			</div>

			<TimeRange
				ref={timeRange}
				className={className}
				startMoment={moment(props.startTime, "HH:mm:ss")}
				endMoment={moment(props.endTime, "HH:mm:ss")}
				onStartTimeChange={startTimeChange}
				onEndTimeChange={endTimeChange}
				startLabel=""
				endLabel=""
				showErrors={false}
			/>
			<div className="twenty-four-hours">
				<Switch
					onColor="#1777F7"
					disabled={props.openClose}
					onChange={() => changeCheckBox(!props.allDay)}
					checked={props.allDay}
					uncheckedIcon={false}
					checkedIcon={false}
					height={22}
					width={36}
				/>
			</div>
		</div>
	);
}

export default BusinessTiming;
