import { useEffect, useState } from "react";
import BusinessGetListingForCategory from "../BusinessListing/BusinessGetListingForCategory";
import { businessDetailsByCategoryArea } from "../../services/BusinessService";
import "./CategorySearch.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useHistory } from "react-router-dom";
import "../../utils/common.css";

function CategorySearch() {
  const [businessListing, setBusinessListing] = useState([]);
  const history = useHistory();
  const category_id = history.location.state["category"];

  useEffect(() => {
    if (category_id === null) {
      setBusinessListing([]);
    } else {
      businessDetailsByCategoryArea(category_id).then((res) => {
        setBusinessListing(res.data);
      });
    }

    // if(category_id === null){
    //   setBusinessListing([])
    // }else{

    // }
  }, [category_id]);

  const goBackLink = () => {
    if (history.location.state.from === "/home") {
      history.push({
        pathname: "/home",
        state: {
          from: history.location.pathname,
        },
      });
      console.log("go home");
    } else {
      console.log("go back");
      history.goBack();
    }
  };
  return (
    <div className="body-container-footer" style={{ backgroundColor: "white" }}>
      <div className="profile-page">
        <div style={{ border: "none" }} className="back-div">
          <div onClick={goBackLink} className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div className="listing-main-category-container">
            <BusinessGetListingForCategory businessListing={businessListing} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorySearch;
