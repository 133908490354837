import "./BusinessRegistration.css";
import "../../utils/common.css";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { getUserBusinessDetails } from "../../services/BusinessService";
import Loader from "react-loader-spinner";
import ZopfindLogo from "../../assets/logo/logo.svg";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
export default function BusinessMainPage() {
  const history = useHistory();
  const [businessName, setBusinessName] = useState("Name");
  const [businessId, setBusinessId] = useState(null);
  const [showLoader, setShowLoader] = useState("none");
  const [showBusinessMainPage, setShowBusinessMainPage] = useState("none");

  const dataRef = useRef(businessId);
  dataRef.current = businessId;

  useEffect(() => {
    getUserBusinessDetails().then((res) => {
      setBusinessId(res.data[0].id);
      setBusinessName(res.data[0]["business_name"]);
      setShowLoader("none");
      setShowBusinessMainPage("block");
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!dataRef.current) setShowLoader("block");
    }, 3000);
  }, []);

  const openEditBusinessPage = () => {
    history.push("/business/edit-profile");
  };

  const openOwnListingPage = () => {
    if (businessId) {
      history.push(`/business-page/${businessId}`);
    }
  };

  const moveToAddDetails = () => {
    history.push("/business/add-details");
  };

  return (
    <>
      <div className="body-container-footer">
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: showLoader }}
          className={"loader"}
        />
        <div className="profile-page" style={{ display: showBusinessMainPage }}>
          <div
            id="business-main-back-div"
            style={{ border: "none" }}
            className="back-div"
          >
            <Link to="/profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>

          <div
            className="other-page-element-container"
            style={{ marginTop: "0px", justifyContent: "flex-start" }}
          >
            <div className="business-element page-element">
              <h1 id="business-page-logo" className="company-name">
                <img alt="Zopfind" src={ZopfindLogo} />
              </h1>
              <button
                className="create-business full-width-btn"
                onClick={openEditBusinessPage}
                style={{ fontWeight: "normal" }}
              >
                {businessName ? "Business Name" : "Create Business Name"}
              </button>
              <button
                className="create-business full-width-btn"
                onClick={moveToAddDetails}
              >
                Add Details
              </button>
              <button
                className="done-btn full-width-btn"
                onClick={openOwnListingPage}
              >
                Your Listing View
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
