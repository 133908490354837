import React from "react";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useEffect } from "react";
import { BiRupee } from "react-icons/bi";
import axios from "axios";
import { IoCaretDownOutline } from "react-icons/io5";
import { AiOutlineCaretRight } from "react-icons/ai";
import { Box, Grid } from "@material-ui/core";

const MenuDetails = () => {
  const [menuItems, setMenuItems] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [openedCategories,setOpencategories] = React.useState({})
  const fetchData = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menucategories/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the response data
        setMenuItems(response.data);
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };

  useEffect(() => {
    fetchData();
    if (selectedCategory !== null) {
      setOpencategories(prevTotalCategories => ({
        ...prevTotalCategories,
        [selectedCategory]: !prevTotalCategories[selectedCategory],
      }));
    } else {
      // When no category is selected, ensure that none are marked as open
      setOpencategories(prevTotalCategories => {
        let newTotalCategories = { ...prevTotalCategories };
        Object.keys(newTotalCategories).forEach(key => {
          newTotalCategories[key] = false;
        });
        return newTotalCategories;
      });
    }
  }, [selectedCategory]);
  return (
    <Box
      className="body-container-footer"
      width={{ xs: "80%", md: "90%" }}
      margin={"auto"}
    >
      <div
        style={{ border: "none" }}
        // id="business-pg-back-div"
        className="back-div"
      >
        <a style={{ cursor: "pointer" }} onClick={() => window.history.back()}>
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </a>
      </div>
      <h2
        className={"title-color"}
        style={{ fontWeight: "bold", marginTop: "60px" }}
      >
        Menu Details
      </h2>

      <Grid
        container
        width={{ base: "80%", md: "60%" }}
        margin="auto"
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "none", md: "center" }}
        alignItems={{ xs: "center", md: "start" }}
        padding={"10px"}
        flexWrap={{ xs: "none", md: "wrap" }}
      >
        {menuItems.map((category, index) => {
          const totalSubcategories = category.subcategories.length;
          // console.log(category.category_name)
          // console.log(selectedCategory)
          
          const toggleDropdown = (categoryName) => {
            setSelectedCategory(prevSelectedCategory => 
              prevSelectedCategory === categoryName ? null : categoryName
            );
            console.log(selectedCategory)
          };

          return (
            <Grid item xs={12} sm={6} md={6} lg={4} spacing={6} key={index}>
              <Box
                // onClick={toggleDropdown}
                onClick={() => {toggleDropdown(category.category_name);}}
                // onClick={() => {toggleDropdown();}}
                style={{
                  gap: "40px",
                  margin: "10px",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={{ xs: "space-between", md: "center" }}
              >
                <div>
                  {category.category_name} {`(${totalSubcategories})`}
                  <span style={{ marginLeft: "1px", cursor: "pointer" }}>
                    {selectedCategory === category.category_name  ? <IoCaretDownOutline />: <AiOutlineCaretRight />}
                    {/* {openedCategories === category.category_name  ? <IoCaretDownOutline />: <AiOutlineCaretRight />} */}
                  </span>
                </div>
              </Box>

              {openedCategories[category.category_name]===true && (
                <div>

                  {category.subcategories.map((subCategory, subIndex) => (
                    <Box
                      key={subIndex}
                      style={{
                        marginTop: "10px",
                        padding: "0px 40px",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent={{ xs: "space-between", md: "space-around" }}
                        // gridGap={"100px"}
                        alignItems="center"
                      >
                        <p style={{ fontWeight: "600" }}>
                          {" "}
                          {subCategory.sub_category_name}
                        </p>

                        <p
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <BiRupee />
                          {subCategory.price}
                        </p>
                      </Box>
                    </Box>
                  ))}
                </div>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MenuDetails;

          // const toggleDropdown = () => {
          //   if (selectedCategory === category.category_name) {
          //     setSelectedCategory(null);
          //     setOpencategories(prevState => ({...prevState, [category.category_name]: false}));
          //     // console.log(`${category.category_name} is closed`)
          //     console.log(openedCategories)
          //   } else {
          //     setSelectedCategory(category.category_name);
          //     // console.log(`${category.category_name} is opened`)
          //     // console.log(openedCategories.category.category_name)
          //     console.log(openedCategories)
          //     setOpencategories(prevState => ({...prevState, [category.category_name]: true}));
          //   }
          //   // setSelectedCategory("Lunch");
          // };