import axios from "axios";
import moment from "moment";

export const businessDetailsByCategory = async (category_id) => {
    try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
        params:{
          business_category__id: category_id,
          active: true,
        },
      };
      let res = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/get_business_details/",
        config,
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Send Verification Code");
  }
};

export const businessDetailsByCategoryArea = async (category_id) => {
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        category_id: category_id,
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +
        "/api/v1/business/get_business_details_in_area/",
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const businessDetailsById = async (business_id) => {
    try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
        params:{
          id: business_id,
          active: true,
      },

      };
      let res = await axios.get(
        process.env.REACT_APP_BASE_URL_API + `/api/v1/business/get_business_details/`,
        config
      );
      return res;
    } catch (err) {
      console.log(err)
      throw new Error("Unable to Send Verification Code");
  }
};


export const getUserBusinessDetails = async() =>{
   try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };
      let res = await axios.get(
        process.env.REACT_APP_BASE_URL_API + `/api/v1/business/get_user_business_details/`,
        config
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Send Verification Code");
  }
}

export const creatBlankOffer = async(businessId) => {
  try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };
      let body = {
        business_user: businessId,
        is_deal:false,
        is_active:false,
        deal_start_date: moment().add(-24,"hours").format("YYYY-MM-DDThh:mm:ss"),
        top_deal_expiry: moment().add(-24,"hours").format("YYYY-MM-DDThh:mm:ss"),
      }
      let res = await axios.post(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/customer_offers/",
        body,
        config
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Send Verification Code");
  }
};

export const creatBlankDeal = async(businessId) => {
  try {
      let config = {
        headers: {
          Authorization: `Token ${window.localStorage.getItem("token")}`,
        },
      };
      let body = {
        business_user: businessId,
        is_deal:true,
        is_active:false,
        deal_start_date: moment().add(-24,"hours").format("YYYY-MM-DDThh:mm:ss"),
        top_deal_expiry: moment().add(-24,"hours").format("YYYY-MM-DDThh:mm:ss"),
      }
      let res = await axios.post(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/customer_deals/",
        body,
        config
      );
      return res;
    } catch (err) {
      throw new Error("Unable to Send Verification Code");
  }
};