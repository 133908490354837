import "./CustomerRegistration.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import StateCityPincodeField from "../CommonComponets/StateCityPincodeField";
import Alert from "../Alert/Alert";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadStateOptions } from "../../services/StateService";
import { loadCityOptions } from "../../services/CityService";
import { loadZipcodeOptions } from "../../services/ZipcodeService";
import {
  updateCustomerProfileDetails,
  updateUserToCustomer,
} from "../../services/ProfileService";

function LocationSelection() {
  const history = useHistory();
  const country = history.location.state["country"];
  const [area, setArea] = useState(null);
  const [alertDisplay, setAlertDisplay] = useState("none");
  const [unabletoCreate, setUnableToCreate] = useState("none");
  const [displayPage, setDisplayPage] = useState("none");
  const [disableTextField, setDisableTextField] = useState(true);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [zipcodeOption, setZipcodeOption] = useState([]);

  useEffect(() => {
    loadStateOptions().then((data) => setStateOption(data));
  }, []);

  useEffect(() => {
    if (selectedState != null) {
      loadCityOptions(null, selectedState.value).then((data) =>
        setCityOption(data)
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity != null) {
      loadZipcodeOptions(null, selectedCity.value).then((data) =>
        setZipcodeOption(data)
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedZipcode != null) {
      setDisableTextField(false);
    }
  }, [selectedZipcode]);

  useEffect(() => {
    if (history.location.state && history.location.state.from) {
      if (history.location.state.from === "/customer-register-country") {
        setDisplayPage("block");
      }
    }
  }, [history.location.state]);

  const submit = async () => {
    if (
      selectedState === null ||
      selectedState === 0 ||
      selectedCity === null ||
      selectedCity === 0 ||
      selectedZipcode === null ||
      selectedZipcode === 0 ||
      area === "" ||
      area === "Area"
    ) {
      setAlertDisplay(true);
    } else {
      setAlertDisplay("none");
      try {
        const body = {
          city: selectedCity.value,
          state: selectedState.value,
          zipcode: selectedZipcode.value,
          area: area,
          country: country,
        };
        await updateCustomerProfileDetails(body);
        await updateUserToCustomer();
        history.push({
          pathname: "/home",
          state: {
            from: history.location.pathname,
          },
        });
      } catch (err) {
        setUnableToCreate(true);
        console.log(err);
      }
    }
  };
  return (
    <div
      className="profile-page customer-registration"
      style={{ display: displayPage }}
    >
      <div className="back-div">
        <Link
          to={{
            pathname: "/customer-register-country",
            state: {
              from: history.location.pathname,
            },
          }}
          className="link"
        >
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <h2>Location Details</h2>
      <Alert value="Please Provide All The Details" show={alertDisplay} />
      <Alert value="Please Try Again" show={unabletoCreate} />
      <div className="profile-page-element-container">
        <div className="profile-page-element">
          <StateCityPincodeField
            stateOption={stateOption}
            cityOption={cityOption}
            zipcodeOption={zipcodeOption}
            selectedState={selectedState}
            selectedCity={selectedCity}
            selectedZipcode={selectedZipcode}
            setSelectedState={setSelectedState}
            setSelectedCity={setSelectedCity}
            setSelectedZipcode={setSelectedZipcode}
          />
          <label from="area">Area</label>
          {disableTextField ? (
            <TextField
              label="Area"
              variant="outlined"
              id="mobile_num"
              className="full-width-input custom-material-input update-label"
              type="text"
              inputProps={{ maxLength: 100, minLength: 3 }}
              placeholder="Add Area"
              value={area}
              style={{
                backgroundColor: "rgb(242, 242, 242)",
                borderRadius: "35px",
                padding: " 5px 0px 0px 0px",
              }}
              disabled={true}
            />
          ) : (
            <TextField
              label="Area"
              variant="outlined"
              id="mobile_num"
              className="full-width-input custom-material-input update-label customer-area-label"
              type="text"
              inputProps={{ maxLength: 100, minLength: 3 }}
              placeholder="Add Area"
              onChange={(e) => {
                setArea(e.target.value);
              }}
              value={area}
            />
          )}
          <br />
          <br />
          <button className="done-btn full-width-btn" onClick={submit}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

export default LocationSelection;
