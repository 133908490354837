import React from "react";

function PaymentMode(props) {
  const PaymentModes = [
    {
      name: "Credit Card",
      value: "cc",
    },
    {
      name: "Debit Card",
      value: "dc",
    },
    {
      name: "Net Banking",
      value: "nb",
    },
    {
      name: "UPI",
      value: "upi",
    },
  ];
  return (
    <React.Fragment>
      <h2
        className={"title-color title"}
        style={{ marginLeft: 0, textAlign: "center", marginTop: "100px" }}
      >
        Payment Mode
      </h2>
      <div className="profile-page-element-container">
        <div className="profile-page-element page-element full-width without-padding without-margin">
          <div className="payment-options full-width">
            {PaymentModes.map((mode, index) => {
              return (
                <div
                  className="option-div"
                  key={"modes_" + index}
                  style={{ borderRadius: "60px" }}
                  onClick={() => props.SelectPaymentMode(mode)}
                >
                  <div className="option-name1">{mode.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PaymentMode;
