import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from "react-select";
import StateCityPincodeField from "../CommonComponets/StateCityPincodeField";
import _ from "lodash";
import Alert from "../Alert/Alert";
import { loadStateOptions } from "../../services/StateService";
import { loadCityOptions } from "../../services/CityService";
import { loadZipcodeOptions } from "../../services/ZipcodeService";
import { toast } from "react-toastify";
import {
  updateCustomerProfileDetails,
  fetchCustomerProfile,
} from "../../services/ProfileService";

const country_opt = [{ value: "India", label: "India" }];
const currency_opt = [{ value: "INR", label: "INR" }];

function CountryPage() {
  const [showAlert, setShowAlert] = useState("none");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [zipcodeOption, setZipcodeOption] = useState([]);

  useEffect(() => {
    loadStateOptions().then((data) => setStateOption(data));
  }, []);
  useEffect(() => {
    loadCityOptions().then((data) => setCityOption(data));
  }, []);
  useEffect(() => {
    loadZipcodeOptions().then((data) => setZipcodeOption(data));
  }, []);

  useEffect(() => {
    if (selectedCity != null) {
      const city = selectedCity
        ? cityOption[
            _.findIndex(cityOption, function (o) {
              return selectedCity && o.value === selectedCity.value;
            })
          ]
        : null;
      if (city && !_.isEqual(city, selectedCity)) {
        setSelectedCity(city);
      }
    }
  }, [cityOption, selectedCity]);

  useEffect(() => {
    if (selectedState != null) {
      loadCityOptions(null, selectedState.value).then((data) => {
        setCityOption(data);
        //     setSelectedCity(profile["city"]);
        setZipcodeOption([]);
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity != null) {
      loadZipcodeOptions(null, selectedCity.value).then((data) =>
        setZipcodeOption(data)
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    fetchCustomerProfile().then((res) => {
      let profile = res.data;
      setSelectedState({
        label: profile["state"],
        value: profile["state"],
      });
      setSelectedCity({
        label: profile["city"],
        value: profile["city"],
      });
      setSelectedZipcode({
        label: profile["zipcode"],
        value: profile["zipcode"],
      });
    });
  }, []);

  const updateProfile = async () => {
    const body = {
      city: selectedCity["value"],
      state: selectedState["value"],
      zipcode: selectedZipcode["value"],
    };
    await updateCustomerProfileDetails(body);
    toast.info("Details Updated 🤗");
  };
  return (
    <>
      <div className="body-container-footer">
        <div id="country-pg" className="profile-page">
          <div
            style={{ border: "none" }}
            id="country-back-div"
            className="back-div"
          >
            <Link to="/profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <Alert value="Please select all options" show={showAlert} />
          <div className="other-page-element-container">
            <div className="other-page-element light-brown">
              <h2 id="change-place" className={"title-color title"}>
                Change Place
              </h2>
              <div className="other-element-box">
                <Select
                data-test="currency-name-in-country-section"
                  name="currency"
                  className="auto-search-select mb-2"
                  multi={false}
                  placeholder="Search and select state..."
                  value={currency_opt[0]}
                  isDisabled={true}
                  options={currency_opt}
                />
                <Select
                  name="country"
                  className="auto-search-select mb-2"
                  multi={false}
                  placeholder="Search and select state..."
                  value={country_opt[0]}
                  isDisabled={true}
                  options={country_opt}
                />
                <StateCityPincodeField
                  data-test="select-state-option-in-country-section"
                  stateOption={stateOption}
                  cityOption={cityOption}
                  zipcodeOption={zipcodeOption}
                  selectedState={selectedState}
                  selectedCity={selectedCity}
                  selectedZipcode={selectedZipcode}
                  setSelectedState={setSelectedState}
                  setSelectedCity={setSelectedCity}
                  setSelectedZipcode={setSelectedZipcode}
                  disableLabel={true}
                />
                {/* ) : null} */}
                <br />
                <button
                  className="done-btn full-width-btn"
                  onClick={() => {
                    if (
                      selectedState &&
                      selectedCity &&
                      selectedZipcode &&
                      selectedState["value"] &&
                      selectedCity["value"]
                    ) {
                      setShowAlert("none");
                      updateProfile();
                      return;
                    }
                    setShowAlert(true);
                  }}
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CountryPage;
