import _ from "lodash";
import { getCategoies } from "../../services/Categories";
import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";

function OfferDealFilter(props) {
	const [allCategories, setAllCategories] = useState([]);
	const [data, setData] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const getSelectedCategoies = () => {
		let selCat = allCategories;
		_.forEach(selectedCategory, (obj) => {
			selCat = selCat[obj]["categories_set"];
		});
		return selCat;
	};
	useEffect(() => {
		setData(getSelectedCategoies());
		//eslint-disable-next-line
	}, [selectedCategory]);

	useEffect(() => {
		const catData = JSON.parse(window.localStorage.getItem("categories"));

		if (typeof catData != "undefined" && catData != null) {
			setAllCategories(catData);
			setData(catData);
		} else {
			getCategoies().then((res) => {
				window.localStorage.setItem(
					"categories",
					JSON.stringify(res.data)
				);
				setAllCategories(res.data);
				setData(res.data);
			});
		}
	}, []);

	const goBack = () => {
		if (selectedCategory.length >= 1) {
			setSelectedCategory(selectedCategory.slice(0, -1));
		}
	};
	const getCategoryNameId = (name_with_id, id = true) => {
		const seprate = name_with_id.split("##");
		return id ? seprate[1] : seprate[0];
	};

	const elementGetNameWithId = (el) => {
		return el.name_with_id ? el.name_with_id : el.name + "##" + el.id;
	};

	const submitCategory = (el) => {
		props.handleOfferDealFilter(el);
	};

	const returnComponent = (data) => {

		return data.map((el, idX) => {
			if (
				(_.has(el, "categories_set") && _.isEmpty(el.categories_set)) ||
				!_.has(el, "categories_set")
			) {
				return (
					<div
						key={elementGetNameWithId(el)}
						className="each-category-element"
						onClick={() =>
							submitCategory(
								getCategoryNameId(
									elementGetNameWithId(el),
									false
								)
							)
						}
					>
						<div className="each-category">
							<p>
								{getCategoryNameId(
									elementGetNameWithId(el),
									false
								)}
							</p>
						</div>
					</div>
				);
			} else {
				return (
					<div
						key={el.name_with_id}
						onClick={() => {
							setSelectedCategory([...selectedCategory, idX]);
						}}
						className="each-category-element"
					>
						<div className="each-category">
							<p>{getCategoryNameId(el.name_with_id, false)}</p>
							<FaChevronRight fill="#1a73e8" />
						</div>
					</div>
				);
			}
		});
	};
	return (
		<div className="profile-page">
			<div
				className="back-div"
				style={{ padding: 0, justifyContent: "space-between" }}
			>
				<div
					onClick={goBack}
					className=""
					style={{
						display: "inline-flex",
						padding: "10px",
						fontSize: "40px",
					}}
				>
					<backArrowIcon
						className=""
						style={{
							display:
								selectedCategory.length === 0
									? "none"
									: "block",
						}}
					/>
				</div>
				<div
					onClick={(e) => props.handleOfferDealFilter()}
					style={{
						display: "inline-flex",
						padding: "10px",
						fontSize: "36px",
					}}
				>
					<IoClose />
				</div>
			</div>
			<div
				className="profile-page-element-container"
				style={{ marginTop: "60px" }}
			>
				<div className="profile-page-element category-element">
					{data != null ? returnComponent(data) : null}
				</div>
			</div>
		</div>
	);
}

export default OfferDealFilter;
