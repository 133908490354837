import WhyWebAppComman from "../CommonComponets/WhyWebAppComman";
import "../../utils/common.css";

function SettingWhyWebApp() {
  return <div className="body-container-footer" >
    <WhyWebAppComman back="/setting-how-to-install" />;
  </div>
}

export default SettingWhyWebApp;
