import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import fetchCustomerProfile from "../../services/ProfileService";
import Loader from "react-loader-spinner";

function ProfilePage() {
  const [username, setUsername] = useState(null);
  const [email, setEmail] = useState(null);

  const [showLoader, setShowLoader] = useState("none");
  const [showProfile, setShowProfile] = useState("none");
  const dataRef = useRef(username);
  dataRef.current = username;

  useEffect(() => {
    async function fetchData() {
      let res = await fetchCustomerProfile();
      setUsername(res.data["username"]);
      setEmail(res.data["email"]);
      setShowLoader("none");
      setShowProfile(true);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      console.log(dataRef, "data");
      if (!dataRef.current) setShowLoader("block");
    }, 3000);
  }, []);

  return (
    <>
      <div className="body-container-footer">
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: showLoader }}
          className={"loader"}
        />
        <div
          id="profile-pg"
          className="profile-page"
          style={{ display: showProfile }}
        >
          <div
            style={{ border: "none" }}
            id="profilepage-back-div"
            className="back-div"
          >
            <Link to="/profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <div className="other-page-element-container">
            <div className="other-page-element light-brown">
              <h2
                style={{ marginBottom: "20px" }}
                className={"title-color title"}
              >
                Profile
              </h2>
              <div className="other-element-box">
                <p data-test="email-column-in-my-profile" className="elements">Email: {email}</p>
                <p data-test="user-id-column-in-my-profile" className="elements">User ID: {username}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
