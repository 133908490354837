import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { creatBlankOffer } from "../../../services/BusinessService";
import {
  getOfferOfBusiness,
  updateOffers,
} from "../../../services/BusinessRegistration";

function TodayOffer(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [buttonPressed, setButtonPressed] = useState(JSON.parse(localStorage.getItem('offer')));
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  const [businessOfferCreatedDate, setBusinessOfferCreatedDate] =
    useState(null);
  const [businessOffer, setBusinessOffer] = useState("");
  const [businessOfferId, setBusinessOfferId] = useState(null);
  const [businessOfferVisibility, setBusinessOfferVisibility] = useState(false);
  const [toggle, setToggle] = useState(null);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  useEffect(() => {
    getOfferOfBusiness(props.businessId).then((res) => {
      if (res.data.length === 1) {
        let offerData = res.data[0];
        setBusinessOfferVisibility(offerData["is_active"]);
        setToggle(offerData["is_active"])
        localStorage.setItem("offer-btn", JSON.stringify(offerData["is_active"]))
        setBusinessOffer(offerData["business_offer"]);
        setBusinessOfferCreatedDate(offerData["deal_start_date"]);
        setBusinessOfferId(offerData["id"]);
      } else if (res.data.length === 0) {
        creatBlankOffer(props.businessId).then((res) => {
          if (res.status === 201) {
            window.location.reload(false);
          }
        });
      }
    });
  }, [props.businessId]);
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Offer
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={(e) => {
              setBusinessOfferVisibility(!businessOfferVisibility);
              console.log("hello", e)
              localStorage.setItem("offer-btn", JSON.stringify(e))
            }}
            checked={businessOfferVisibility}
            className="bd-switch switch-offer"
          />
          <p>1% - 99% OFF</p>
          <TextField
            autoFocus={true}
            label="Offer"
            variant="outlined"
            id="offer"
            error={showAlert !== "none"}
            className="full-width-input custom-material-input"
            type="number"
            InputProps={{
              inputProps: { min: 1, max: 99, step: 1 },
            }}
            placeholder="Offer"
            onChange={(e) => setBusinessOffer(e.target.value)}
            onKeyDown={blockInvalidChar}
            value={businessOffer}
            disabled={!businessOfferVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              console.log(localStorage.getItem("offer-btn"), "offer btn", toggle)

              if (JSON.parse(localStorage.getItem("offer-btn")) === false && toggle === false){
                console.log("djang")
                console.log("NOPPPP")
                toast.warning("Button Is Disabled");
                return 
              }

              if (
                moment(businessOfferCreatedDate).add(24, "hours") > moment.now() && toggle === true
              ) {
                setAlertMessgae("You can't modify before 24 hours");
                setShowAlert("block");
                return;
              }


              if (
                businessOfferVisibility === true &&
                (businessOffer === "" ||
                  businessOffer === null ||
                  businessOffer.toString().length > 2 ||
                  parseInt(businessOffer) > 99 ||
                  parseInt(businessOffer) < 1)
              ) {
                setAlertMessgae(
                  "Invalid Input. Accept only whole number (range 1-99)"
                );
                setShowAlert("block");
              }
              else if (businessOfferVisibility === false && buttonPressed===false) {
                setShowAlert("none");
                await updateOffers(
                  {
                    business_offer: businessOffer,
                    is_active: businessOfferVisibility,
                    deal_start_date: moment().format("YYYY-MM-DDTH:mm:ss"),
                    top_deal_expiry: moment()
                      .add(24, "hours")
                      .format("YYYY-MM-DDTH:mm:ss"),
                  },
                  businessOfferId
                );
                toast.info("Details Updated 🤗");
                localStorage.setItem('offer','true')
                // localStorage.setItem('offer-btn','true')

                setButtonPressed(JSON.parse(localStorage.getItem('offer')));
              }
              else if(businessOfferVisibility === false && buttonPressed===true){
                toast.warning("Button Is Disabled");
              }else {
                setShowAlert("none");
                await updateOffers(
                  {
                    business_offer: businessOffer,
                    is_active: businessOfferVisibility,
                    deal_start_date: moment().format("YYYY-MM-DDTH:mm:ss"),
                    top_deal_expiry: moment()
                      .add(24, "hours")
                      .format("YYYY-MM-DDTH:mm:ss"),
                  },
                  businessOfferId
                );
                await toast.info("Details Updated 🤗");
                localStorage.setItem('offer','false')
                setButtonPressed(JSON.parse(localStorage.getItem('offer')));
              }
            }}
          >
            Done
          </button>
          <br />
          <p>You can modify after 24 hours</p>
        </div>
      </div>
    </>
  );
}

export default TodayOffer;