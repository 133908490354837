import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { toast } from "react-toastify";


function Skype(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Skype
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessSkypeVisibility(!props.businessSkypeVisibility)
            }
            checked={props.businessSkypeVisibility}
          />
          <br />
          <br />
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Skype"
            variant="outlined"
            id="skype"
            inputProps={{ maxLength: 100, minLength: 3 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Add Skype"
            onChange={(e) => props.setBusinessSkype(e.target.value)}
            value={props.businessSkype}
            disabled={!props.businessSkypeVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (props.businessSkypeVisibility === false && JSON.parse(localStorage.getItem("business_skype_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessSkypeVisibility === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_skype: props.businessSkype,
                  business_skype_visibility: props.businessSkypeVisibility,
                });
                localStorage.setItem("business_skype_visibility",  props.businessSkypeVisibility)

              } else {
                if (
                  props.businessSkypeVisibility === true &&
                  (props.businessSkype === "" || props.businessSkype === null)
                ) {
                  setAlertMessgae("Please fill the input!");
                  setShowAlert("block");
                } else if (
                  props.businessSkype.length > 100 ||
                  props.businessSkype.length < 3 ||
                  props.businessSkype.match(
                    /(^(https?:\/\/)?((www|\w\w)\.)?(join.)?skype\.com\/)/g
                  ) == null ||
                  !(props.businessSkype.indexOf("skype") > -1)
                ) {
                  setAlertMessgae(
                    "Invalid input! accept only min 3 and max 100 char"
                  );
                  setShowAlert("block");
                } else {
                  setShowAlert("none");
                  await props.updateDetails({
                    business_skype: props.businessSkype,
                    business_skype_visibility: props.businessSkypeVisibility,
                  });
                localStorage.setItem("business_skype_visibility",  props.businessSkypeVisibility)

                }
              }
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default Skype;