import "./Privacy.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";

function RefundPolicy(props) {
  return (
    <div className="c5 privacyAndPolicy">
      <div className="back-div">
        <Link to="/" className="link">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <p className="c2">
        <span className="c6">Return & Refund Policy</span>
      </p>
      <p className="c2">
        Thanks for listing your business at zopfind. We appreciate the fact that
        you like to buy the stuff we build. We also want to make sure you have a
        rewarding experience while you’re exploring, evaluating, and purchasing
        our service.
      </p>
      <p className="c2">
        As with listing experience, there are terms and conditions that apply to
        transactions at zopfind. We’ll be as brief as our attorneys will allow.
        The main thing to remember is that by placing an order or making a
        purchase or subscription at zopfind, you agree to the terms along with
        zopfind."’s" Privacy Policy.
      </p>
      <p className="c2">
        If there’s something wrong with what you bought, or if you are not happy
        with it, you will not be able to issue a refund for it.
      </p>
      <p className="c2">
        We follow certain policies to ensure transparency, efficiency, and
        quality :{" "}
      </p>
      <p className="c2">Refunds are not given for any purchases made. </p>
      <p className="c2">
        If, for any reason, You are not completely satisfied with any good or
        service that we provide, don't hesitate to contact us and we will
        discuss any of the issues you are going through with our service.
      </p>
      <p className="c2">Your Consent </p>
      <p className="c2">
        {" "}
        By using our website, listing your business, registering an account, or
        making a purchase, you hereby consent to our Return & Refund Policy and
        agree to its terms.{" "}
      </p>
      <p className="c2"> Changes To Our Return & Refund Policy </p>
      <p className="c2">
        We update, amend or make any changes to this document so that they
        accurately reflect our Service and policies. Unless otherwise required
        by law, those changes will be prominently posted here. Then, if you
        continue to use the Service, you will be bound by the updated Return &
        Refund Policy. If you do not want to agree to this or any updated Return
        & Refund Policy, you can delete your account.
      </p>
      <p className="c2"> Pricing </p>
      <p className="c2">
        To list the business in zopfind website business users should pay for 1
        year - Rs.14,998, 2 years - Rs.29,999, 3 years - Rs.44,998.
      </p>
    </div>
  );
}
export default RefundPolicy;
