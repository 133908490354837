import "./Privacy.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";

function ContactUs(props) {
	return (
    <div
      className=""
      style={{
        backgroundColor: "#fafafa",
        marginTop:"60px",
        width:"100%",
      }}
    >
      <div style={{backgroundColor:"#fafafa"}} className="back-div">
        <Link to="/" className="link">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <p className="c4 contact-us-cont">
        <span className="contact-us c6">Contact Us</span>
      </p>
      <p style={{width:"100%", display:"flex", flexDirection:"column",alignItems:"start", paddingLeft:"30px"}} className="">
      <p className="">Registered Address</p>
        <p className="">Zopfind (OPC) Private Limited</p>
        <p className="">2-4, Shirdi nagar, 4th road</p>
        <p className="">Near Park, Satrampadu,</p>
        <p className="">Eluru, West Godavari,</p>
        <p className="">534007, Andhra Pradesh, India</p>
        <p className="">Phone: +91 7337383583</p>
        <p className="">Email: support@zopfind.com</p>
      </p>
    </div>
  );
}
export default ContactUs;
