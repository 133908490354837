import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import fetchCustomerProfile, {
  deleteDealsAndOffers,
  fetchCustomerOfferAndDeals,
} from "../../services/ProfileService";
import Loader from "react-loader-spinner";
import _ from "lodash";
import BestDeal from "../BestDeal/BestDeal";
import Offer from "../Offer/Offer";
import circlePlayIcon from "../../assets/icons/circle play icon.svg";

function SavedOfferAndDeals() {
  const history = useHistory();
  const [dealsAndOffer, setDealsAndOffer] = useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchCustomerOfferAndDeals().then((res) => {
      setDealsAndOffer(res.data);
    });
  }, [reload]);

  const deleteDealOffer = (obj) => {
    console.log(obj);
    deleteDealsAndOffers(obj)
      .then((res) => {
        console.log(res, "res");
        if (res && res.data && res.data.msg) {
          toast.success(res.data.msg);
          const profileData = JSON.parse(
            window.localStorage.getItem("customer-profile")
          );
          fetchCustomerProfile().then((res) => {
            console.log(_.isEqual(res.data, profileData));
            if (!_.isEqual(res.data, profileData)) {
              window.localStorage.setItem(
                "customer-profile",
                JSON.stringify(res.data)
              );
              setDealsAndOffer(res.data["saved_offer_and_deals"]);
            }
          });
        }
      })
      .then(() => setReload(!reload));
  };

  const getDeals = (obj) => {
    return (
      <BestDeal
        dealId={obj["id"]}
        businessId={obj["business_user"]}
        deleteDealOffer={deleteDealOffer}
        businessName={obj["business_name"]}
        businessCategory={obj["business_category"]}
        dealName={obj["top_deal_name"]}
        dealActualPrice={obj["top_deal_actual_price"]}
        dealPrice={obj["top_deal_price"]}
        dealExpiry={obj["top_deal_expiry"]}
        cardColor={obj["color_code"]}
        // leftTime={leftTime}
        requestFromBusinessPage={false}
      />
    );
  };
  const getOffer = (obj) => {
    return (
      <Offer
        deleteDealOffer={deleteDealOffer}
        dealId={obj["id"]}
        businessId={obj["business"]}
        businessName={obj["business_name"]}
        businessCategory={obj["business_category"]}
        offer={obj["business_offer"]}
        cardColor={obj["color_code"]}
        requestFromBusinessPage={false}
      />
    );
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          backgroundColor: "black",
          height: "auto",
          overflowY: "visible",
        }}
        className=""
      >
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: dealsAndOffer == null ? "none" : "none" }}
          className={"loader"}
        />
        <div style={{ backgroundColor: "white" }} className="profile-page">
          <div
            style={{
              position: "relative",
              marginTop: "14px",
              marginBottom: "0px",
            }}
            className="back-div"
          >
            <Link to="/profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
            <div
              onClick={() => {
                history.push({
                  pathname: "/offer-usage-guide",
                });
              }}
              style={{
                fontSize: "22px",
                fontWeight: 600,
                textAlign: "center",
                width: "100%",
                zIndex: "-2",
                marginLeft: "-25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <span>Show it and Get it</span>
              <img
                src={circlePlayIcon}
                alt={"category-icon"}
                className="icon"
                height="24px"
              />
            </div>
          </div>
          <div className="save-offer-and-deal">
            <h2
              data-test="sub-heading-in-dicounts-section"
              className={"title-color"}
              onClick={() => {
                history.push({
                  pathname: "/offer-usage-guide",
                });
              }}
            >
              Saved Offers And Deals
            </h2>
            <div className="profile-page-element-container">
              <div
                style={{
                  overflow: "visible",
                  width: "calc(100vw - 15vw + 20px)",
                }}
                className="profile-page-element page-element profile-change-opt with-90-perc withoutmargin"
              >
                {dealsAndOffer != null
                  ? dealsAndOffer.map((deal_offer) => {
                      if (deal_offer["is_deal"]) {
                        return (
                          <div style={{ width: "100%" }} key={deal_offer["id"]}>
                            {getDeals(deal_offer)}
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ width: "100%" }} key={deal_offer["id"]}>
                            {" "}
                            {getOffer(deal_offer)}
                          </div>
                        );
                      }
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedOfferAndDeals;
