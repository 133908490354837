import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
  } from "@material-ui/core";
  import axios from "axios";
  import React, { useEffect } from "react";
  import { toast } from "react-toastify";
  
  const DeleteCategoryModel = ({ id, open, handleClose, fetchData }) => {
    const handleDelete = () => {
      const token = window.localStorage.getItem("token");
      axios
        .delete(
          process.env.REACT_APP_BASE_URL_API +
            `/api/v1/business/menucategories/${id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Category Deleted Successfully..", {
              autoClose: 2000,
            });
            handleClose();
            fetchData();
          }
        })
        .catch((error) => {
          console.error("Error deleting resource:", error);
        });
    };
  
    useEffect(() => {
      fetchData();
    }, [id]);
    return (
      <div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Press `Delete` button if you want to delete.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-action-btn">
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "35px",
                border: "1px solid #a6a6a6",
                marginTop: "20px",
                fontFamily: "Roboto",
                fontSize: "20px",
              }}
              onClick={handleClose}
              color="btn btn-default"
              autoFocus
            >
              Cancel
            </button>
            <button
              style={{
                padding: "10px 20px",
                borderRadius: "35px",
                border: "1px solid #FF3366",
                backgroundColor: "#FF3366",
                color: "#fff",
                marginTop: "20px",
                fontFamily: "Roboto",
                fontSize: "20px",
              }}
              onClick={handleDelete}
              color="btn btn-danger"
            >
              Delete
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  
  export default DeleteCategoryModel;
  