function ShareLink(props) {
  let shareLinkInput = null;
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Share Link
      </h2>
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          {/* <p>{`${process.env.REACT_APP_BASE_URL_1}/business-selected/${props.businessId}`}</p> */}
          <textarea
            ref={(p) => {
              shareLinkInput = p;
              // console.log(p, this);
            }}
            inputProps={{ maxLength: 255, minLength: 3 }}
            style={{ display: "block" }}
            readOnly={true}
            value={`${process.env.REACT_APP_BASE_URL_1}/business-page/${props.businessId}`}
          ></textarea>
          <button
            className="done-btn full-width-btn"
            onClick={(e) => {
              var copyText = shareLinkInput;
              /* Select the text field */
              copyText.select();
              copyText.setSelectionRange(0, 99999); /* For mobile devices */

              /* Copy the text inside the text field */
              document.execCommand("copy");
            }}
          >
            Copy
          </button>
        </div>
      </div>
    </>
  );
}

export default ShareLink;
