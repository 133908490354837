import Alert from "../../Alert/Alert";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import _ from "lodash";
import {
  getDealOfBusiness,
  updateDeal,
} from "../../../services/BusinessRegistration";
import { creatBlankDeal } from "../../../services/BusinessService";
import { randomColour } from "../../../utils/common";

function TodayDeal(props) {
  const cardColor = randomColour();
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  const [dealName, setDealName] = useState(null);
  const [actualPrice, setActualPrice] = useState(null);
  const [dealPrice, setDealPrice] = useState(null);
  const [businessDealId, setDusinessDealId] = useState(null);
  const [businessDealCreatedDate, setBusinessDealCreatedDate] = useState(null);

  useEffect(() => {
    getDealOfBusiness(props.businessId).then((res) => {
      if (res.data.length === 1) {
        let dealData = res.data[0];
        setDealName(dealData["top_deal_name"]);
        setActualPrice(dealData["top_deal_actual_price"]);
        setDealPrice(dealData["top_deal_price"]);
        setBusinessDealCreatedDate(dealData["deal_start_date"]);
        setDusinessDealId(dealData["id"]);
      } else if (res.data.length === 0) {
        creatBlankDeal(props.businessId).then((res) => {
          if (res.status === 201) {
            window.location.reload(false);
          }
        });
      }
    });
  }, [props.businessId]);
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Today's Deal
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <label from="deal_name">Your Deal Name</label>
          <TextField
            // inputRef={input => input && input.focus()}
            autoFocus={true}
            label="Deal Name"
            variant="outlined"
            id="deal_name"
            className="full-width-input custom-material-input"
            InputProps={{
              inputProps: {
                min: 2,
                maxLength: 20,
                required: true,
              },
            }}
            type="text"
            placeholder="Deal Name"
            onChange={(e) => setDealName(e.target.value)}
            value={dealName}
          />

          <label from="actual_price">Actual Price</label>
          <TextField
            // inputRef={input => input && input.focus()}
            autoFocus={true}
            label="Actual Price"
            variant="outlined"
            id="actual_price"
            inputProps={{
              minLength: 1,
              maxLength: 8,
              min: 1,
              max: 8,
              required: true,
            }}
            className="full-width-input custom-material-input"
            type="number"
            placeholder="Actual Price"
            onChange={(e) => setActualPrice(e.target.value)}
            value={actualPrice}
          />
          <label from="deal_price">Deal Price</label>
          <TextField
            // inputRef={input => input && input.focus()}
            autoFocus={true}
            label="Deal Price"
            variant="outlined"
            id="deal_price"
            className="full-width-input custom-material-input"
            inputProps={{
              minLength: 1,
              maxLength: 8,
              min: 1,
              max: 8,
              required: true,
            }}
            type="number"
            placeholder="Deal Price"
            onChange={(e) => setDealPrice(e.target.value)}
            value={dealPrice}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (
                dealName === null ||
                dealName === "" ||
                actualPrice === "" ||
                actualPrice === null ||
                dealPrice === "" ||
                dealPrice === null ||
                parseInt(actualPrice) <= parseInt(dealPrice)
              ) {
                if (
                  _.isEmpty(dealName) ||
                  _.isEmpty(actualPrice) ||
                  _.isEmpty(dealPrice)
                ) {
                  setAlertMessgae("Please fill all the fields!");
                } else if (dealName && actualPrice && dealPrice) {
                  setAlertMessgae(
                    "Actual price can't be less then the deal price"
                  );
                }
                setShowAlert("block");
              } else if (dealName.length > 20 || dealName.length < 1) {
                setAlertMessgae(
                  "Invalid deal name! Deal name should be greater then 1 char and less then 20 char long"
                );
                setShowAlert("block");
                return;
              } else if (actualPrice.length > 8 || actualPrice.length < 1) {
                setAlertMessgae(
                  "Invalid deal Actual Price! range should be 1 to 1000000"
                );
                setShowAlert("block");
                return;
              } else if (dealPrice.length > 20 || dealPrice.length < 1) {
                setAlertMessgae(
                  "Invalid deal Price! range should be 1 to 1000000"
                );
                setShowAlert("block");
                return;
              } else if (
                moment(businessDealCreatedDate).add(24, "hours") > moment.now()
              ) {
                setAlertMessgae("You can't modify before 24 hours");
                setShowAlert("block");
                return;
              } else {
                setShowAlert("none");
                await updateDeal(
                  {
                    top_deal_name: dealName,
                    top_deal_actual_price: actualPrice,
                    top_deal_price: dealPrice,
                    is_active: true,
                    deal_start_date: moment().format("YYYY-MM-DDTH:mm:ss"),
                    top_deal_expiry: moment()
                      .add(24, "hours")
                      .format("YYYY-MM-DDTH:mm:ss"),
                    color_code: cardColor,
                  },
                  businessDealId
                );
                await toast.info("Details Updated 🤗");
              }
            }}
          >
            Done
          </button>
          <br />
          <p>
            <span>Deal will expire after 24 hours</span>
            <br />
            You can modify after 24 hours
          </p>
        </div>
      </div>
    </>
  );
}

export default TodayDeal;
