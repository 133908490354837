import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ZopfindLogo from "../../assets/logo/logo.svg";
import { useHistory } from "react-router-dom";

function PaymentNotSucessFull() {
  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      history.push({
        pathname: "/home",
      });
    }, 6000);
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <>
      <div className="profile-container" style={{ display: true }}>
        <div className="header">
          <h1 className="company-name">
            <img alt="Zopfind" src={ZopfindLogo} />
          </h1>
        </div>
        <div className="profile-element">
          <p>Sorry, Your Payment is not Recieved. Try Again</p>
          <p>
            <Link to="/home">Go to Home Page</Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default PaymentNotSucessFull;
