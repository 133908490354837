import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import backArrowIcon from "../../../../assets/icons/Back arrow rounded.svg";
import SubCategoryModel from "./Sub_CategoryModel";
import AddSubCategory from "./Add_SubCategoryModel";
import DeleteSubCategoryModel from "./DeleteSubCategoryModel";
import DeleteCategoryModel from "./DeleteCategoryModel";
import axios from "axios";
import { BiRupee } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { IoCaretDownOutline } from "react-icons/io5";
import { AiOutlineCaretRight } from "react-icons/ai";
import { MdAddCircleOutline } from "react-icons/md";
import { wrap } from "lodash";

const Change_Add = () => {
  const [menuItems, setMenuItems] = React.useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [isSubCategoryModelOpen, setSubCategoryModelOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [deleteSubCategoryModel, setdeleteSubCategoryModel] = React.useState(null);
  const [deleteCategoryModel, setdeleteCategoryModel] = React.useState(null);
  const [isAddNewSubcategoryOpen,setAddNewSubcategory] = useState(false)
  const [totalCategories,setTotalCategories] = useState({})  //Conflicted here



  // OpendeleteCategoryModel here
  const handleOpendeleteCategoryModel = () => {
    setdeleteCategoryModel(true);
  };
  const handleClosedeleteCategoryModel = () => {
    setdeleteCategoryModel(false);
  };

  // OpendeleteSubCategoryModel here
  const handleOpendeleteSubCategoryModel = () => {
    setdeleteSubCategoryModel(true);
    console.log("Opened category to see the sub categories")
  };
  const handleClosedeleteSubCategoryModel = () => {
    setdeleteSubCategoryModel(false);
  };

  // OpenSubCategoryModel
  const handleOpenSubCategoryModel = () => {
    setSubCategoryModelOpen(true);
    // console.log("subcatory openeddddd")
  };
  const handleCloseSubCategoryModel = () => {
    setSubCategoryModelOpen(false);
    // console.log("subcatory closed")
  };

  const openAddNewSubCategory = () => {
    setAddNewSubcategory(true)
  }

  const closeAddNewSubCategory = () => {
    setAddNewSubcategory(false)
  }

  const fetchData = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menucategories/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the response data
        setMenuItems(response.data);
        // console.log(response.data[0].id)
        
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };
    
  useEffect(() => {
    fetchData();

    if (selectedCategory !== null) {
      setTotalCategories(prevTotalCategories => ({
        ...prevTotalCategories,
        [selectedCategory]: !prevTotalCategories[selectedCategory],
      }));
    } else {
      // When no category is selected, ensure that none are marked as open
      setTotalCategories(prevTotalCategories => {
        let newTotalCategories = { ...prevTotalCategories };
        Object.keys(newTotalCategories).forEach(key => {
          newTotalCategories[key] = false;
        });
        return newTotalCategories;
      });
    }
  },[selectedCategory]);
  


return (
    <Box className="body-container-footer" width={{ xs: "80%", md: "90%" }} margin={"auto"} >
      <div
        style={{ border: "none" }}
        // id="business-pg-back-div"
        className="back-div"
      >
        <a style={{ cursor: "pointer" }} onClick={() => window.history.back()}>
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </a>
      </div>
      <h2
        className={"title-color"}
        style={{ fontWeight: "bold", marginTop: "60px" }}
      >
        Change/Add
      </h2>
      <h5 style={{ fontWeight: 400, marginTop: "20px" }}>
        Click on the text to change/add
      </h5>

      <Box marginTop={4} display={"flex"} flexDirection={{ xs: "column", md: "row" }} justifyContent={{ xs: "none", md: "center" }} alignItems={{ xs: "center", md: "start" }} padding={"10px"} width={{ xs: "auto", md: "100%" }} flexWrap={{ xs: "none", md: "wrap" }} >
        {menuItems.map((category, index) => {
         
          const totalSubcategories = category.subcategories.length;
          // const toggleDropdown = () => {
            
          //   if (selectedCategory === category.category_name) {
          //     setSelectedCategory(null);
              
          //     setTotalCategories({...totalCategories,[category.category_name]:false})
          //     setTotalCategories({...totalCategories,[category.category_name]:true})
          //     console.log(category.category_name `is closed ${[category.category_name]} `)
          //   } else {
          //     setSelectedCategory(category.category_name);
          //     setTotalCategories({...totalCategories,[category.category_name]:true})
          //     setTotalCategories({...totalCategories,[category.category_name]:false})
          //     console.log(category.category_name `is opened ${[category.category_name]} `)
          //   }
          // };
          const toggleDropdown = (categoryName) => {
            setSelectedCategory(prevSelectedCategory => 
              prevSelectedCategory === categoryName ? null : categoryName
            );
          };

        return (
          <div key={index} style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-start", gap:"0px",margin:"20px"}}>
              {/* {console.log(category.category_name)} */}
              <p style={{fontWeight: "600",marginLeft:"25px",marginBottom:"-8px",marginTop:"40px"}}>
                Category
              </p>

            <Box >
              <div style={{display: "flex",overflow:"inherit",flexDirection: "column",justifyContent: "space-between",margin: "10px",padding: "10px",height:"50px",width:"193px",cursor: "pointer",fontWeight: "bold", }}>
                <div>
                  {/* Category name buttons starts here */}
                  <div style={{position:"absolute",boxShadow:"0 1px 5px -2px rgba(0, 0, 0, 0.8)",marginTop:"-8px",background:"#FAFAFA",padding:"10px",borderRadius: " 16px 16px 16px 16px",height:"60px",width:"190px",display:"flex", justifyContent:"space-between", alignItems:"center",overflow:"scroll"}}>
                      <div onClick={() => {toggleDropdown(category.category_name); setSelectedId(category.id);}}  style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"1px",}} >
                        <div style={{textAlign: "left",wordBreak:"break-all",width:"108px"}}>
                          {category.category_name}
                          {`(${totalSubcategories})`}

                          <span style={{ cursor: "pointer" }}>
                            {/* {totalCategories[category.category_name] ? "▼" : "▶"} */}
                            {totalCategories[category.category_name] ? <IoCaretDownOutline />: <AiOutlineCaretRight />}
                          </span>
                        </div>
                      </div>

                      <div onClick={() => { handleOpendeleteCategoryModel();
                        if (!totalCategories[category.category_name]) {
                          toggleDropdown(category.category_name);
                          handleOpendeleteCategoryModel();
                        }
                        else{
                          handleOpendeleteCategoryModel();
                        }
                         setSelectedId(category.id);}}>
                        <TiDeleteOutline style={{fontSize: "25px",fill: "red",cursor: "pointer",}} />
                      </div>

                      {/* onClick={() => { handleOpendeleteCategoryModel();
                        if (!totalCategories[category.category_name]) {
                          toggleDropdown();
                          handleOpendeleteCategoryModel();
                        }
                        else{
                          handleOpendeleteCategoryModel();
                        }
                         setSelectedId(category.id);}} */}
                  </div>
                  {/* Category name buttons starts here */}

                  {/* Add a NEW Sub category starts here */}
                  <Box 
                    display={"flex"}
                    marginTop={{xs:"35px",md:"34px"}}
                    marginLeft={{xs:"15px",md:"-10px"}}
                    flexDirection={{ xs: "column", md: "row" }}
                    justifyContent={{ xs: "none", md: "center" }}
                    alignItems={{ xs: "center", md: "start" }}
                    width={{ xs: "auto", md: "210px" }}
                    flexWrap={{ xs: "none", md: "wrap" }}
                    

                  >
                      <div onClick={()=>{
                        openAddNewSubCategory()
                        if (!totalCategories[category.category_name]) {
                          toggleDropdown(category.category_name);
                        }
                        setSelectedId(category.id);
                        // console.log(`Add category id ${category}has been clicked`)
                      } }  style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",alignItems:"center", gap: "10px",width:"190px",height:"52px", borderRadius: " 0px 0px 16px 16px", backgroundColor: "#D1D1D1",padding: "16px",margin:"10px", boxShadow: "white 0px 0px 60px -12px", cursor: "pointer",fontSize:"12px",whiteSpace:"nowrap"}} >
                        Add a Sub-Category <MdAddCircleOutline style={{fontSize:"20px"}} />
                      </div> 
                  </Box>
                  {/* Add a NEW Sub category ends here */}
                </div>
              </div>

              {/* {selectedCategory === category.category_name && ( */}
              {totalCategories[category.category_name]===true && (
                <div style={{textAlign:"left",marginLeft:"20px"}}>
                  <h6 style={{ fontWeight: "600",marginTop:"80px",marginLeft:"8PX"}}>

                    Sub-Category and Price
                  </h6>

                  {category.subcategories.map((subCategory, subIndex) => (
                    <div key={subIndex} style={{display: "flex",justifyContent: "center",alignSelf: "center",gap: "10px",borderRadius: "20px 20px",backgroundColor: "#FAFAFA",marginTop: "10px",padding: "0px 10px",paddingTop: "20px",boxShadow: "0 1px 5px -2px rgba(0, 0, 0, 0.8)"}}>
                      <div
                        onClick={() => {
                          handleOpenSubCategoryModel();
                          setSelectedId(subCategory.id); // Update the selected sub-category ID
                        }}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <p style={{ fontWeight: "600" }}>
                          {" "}
                          {subCategory.sub_category_name}
                        </p>

                        <p style={{ fontWeight: "600" }}>
                          <BiRupee />
                          {subCategory.price}
                        </p>

                      </div>

                      <div>
                        <p
                          onClick={() => {
                            handleOpendeleteSubCategoryModel();
                            setSelectedId(subCategory.id);
                          }}
                        >
                          <TiDeleteOutline
                            style={{
                              fontSize: "25px",
                              fill: "red",
                              cursor: "pointer",
                            }}
                          />
                        </p>
                      </div>

                    </div>
                  ))}
                  {/* {console.log(selectedId)} */}
                  {
                    <SubCategoryModel
                      open={isSubCategoryModelOpen}
                      handleClose={handleCloseSubCategoryModel}
                      fetchData={fetchData}
                      id={selectedId}
                    />
                  }
                  {
                    <AddSubCategory open={isAddNewSubcategoryOpen} 
                    handleClose={closeAddNewSubCategory} 
                    fetchData={fetchData} 
                    id={selectedId}/>
                  }
                  {
                    <DeleteSubCategoryModel
                      open={deleteSubCategoryModel}
                      handleClose={handleClosedeleteSubCategoryModel}
                      fetchData={fetchData}
                      id={selectedId}
                    />
                  }
                  {
                    <DeleteCategoryModel
                      open={deleteCategoryModel}
                      handleClose={handleClosedeleteCategoryModel}
                      fetchData={fetchData}
                      id={selectedId}
                    />
                  }
                  
                </div>
              )}
            </Box>
          </div>
          );
        })}
      </Box>
    </Box>
)
};

export default Change_Add;


        // {/* <div style={{display:"flex",justifyContent:"center",alignItems:"flex-start"}}> */}

        //                   {/* <p style={{display:"flex",justifyContent:"center",alignItems:"center",textAlign:"center"}}> */}

        //                   {/* {category.category_name.length < 9 ? <div>{category.category_name}{`(${totalSubcategories})`} </div>:<div style={{textAlign:"left",whiteSpace:"normal"}}>{`${category.category_name.slice(0,8)}-`}<br></br>{`-${category.category_name.slice(9)}`}{`(${totalSubcategories})`}</div>} */}
        //                   {/* {category.category_name.length < 9 ? <div>{category.category_name}{`(${totalSubcategories})`} </div>:<div style={{textAlign:"left",wordWrap:"break-word"}}>{category.category_name}{`(${totalSubcategories})`}</div>} */}
        //                   {/* <br></br>{`-${category.category_name.slice(8)}`} */}
        //                   {/* {`(${totalSubcategories})`}  */}
        //                   {/* </p> */}
        //                   {/* `${category.category_name.slice(0,8)}...` */}
        //                   {/* {category.category_name} */}
        //                 {/* </div> */}
        //                 {/* <span style={{ cursor: "pointer" }}> */}
        //                   {/* {totalCategories[category.category_name] ? "▼" : "▶"} */}
        //                 {/* </span> */}



              
                            // {/* {category.category_name.length < 10 ? 
                            // <div>{category.category_name}{`(${totalSubcategories})`}
                            //   <span style={{ cursor: "pointer" }}>
                            //     {totalCategories[category.category_name] ? "▼" : "▶"}
                            //   </span>
                            // </div>
                            // :
                            // <div style={{textAlign:"left",whiteSpace:"normal"}}>{`${category.category_name.slice(0,8)}-`}<br></br>{`${category.category_name.slice(8)}`}{`(${totalSubcategories})`}
                            // <span style={{ cursor: "pointer" }}>
                            //   {totalCategories[category.category_name] ? "▼" : "▶"}
                            // </span>
                            // </div>} */}
