import Switch from "react-switch";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

function Home(props) {
  const [buttonPressed, setButtonPressed] = useState(JSON.parse(localStorage.getItem('home')));
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Home Service/Delivery
      </h2>
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessHomeDelieveryVisibility(
                !props.businessHomeDelieveryVisibility
              )
            }
            checked={props.businessHomeDelieveryVisibility}
          />
          <br />
          <br />
          <button
            className="done-btn full-width-btn"
            onClick={() =>{
              if(props.businessHomeDelieveryVisibility===false && buttonPressed ===false ){
                props.updateDetails({
                  business_home: props.businessHomeDelieveryVisibility,
                })
                localStorage.setItem('home','true')
                setButtonPressed(JSON.parse(localStorage.getItem('home')));
              }
              else if(props.businessHomeDelieveryVisibility===false && buttonPressed ===true){
                toast.warning("Button Is Disabled");
              }
              else{
                props.updateDetails({
                  business_home: props.businessHomeDelieveryVisibility,
                })
                localStorage.setItem('home','false')
                setButtonPressed(JSON.parse(localStorage.getItem('home')));
              }
            }}
          >
            Done
          </button>
          <p className="para-space">I will do Home Service/Delivery</p>
        </div>
      </div>
    </>
  );
}

export default Home;
