import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import { useHistory } from "react-router-dom";
import { getCountOfBusiness } from "../../services/BusinessRegistration";
import StateCityPincodeField from "../CommonComponets/StateCityPincodeField";
import { loadStateOptions } from "../../services/StateService";
import { loadCityOptions } from "../../services/CityService";
import { loadZipcodeOptions } from "../../services/ZipcodeService";
import { addBusinessUserPartially } from "../../services/BusinessRegistration";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";

function BusinessLocationSelection() {
  const history = useHistory();
  const category_id = history.location.state["category_id"];
  const [pincode, setPincode] = useState(0);
  const [area, setArea] = useState("");
  const [alertDisplay, setAlertDisplay] = useState("none");
  const [alertErrorMsg, setAlertErrorMsg] = useState(
    "Please Provide All The Details"
  );
  const [businessNotAvailable, setBusinessNotAvailable] = useState(false);
  const [displayPage, setDisplayPage] = useState("none");

  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZipcode, setSelectedZipcode] = useState(null);
  const [stateOption, setStateOption] = useState([]);
  const [cityOption, setCityOption] = useState([]);
  const [zipcodeOption, setZipcodeOption] = useState([]);
  const [disableTextField, setDisableTextField] = useState(true);

  useEffect(() => {
    loadStateOptions().then((data) => setStateOption(data));
  }, []);

  useEffect(() => {
    if (selectedState != null) {
      loadCityOptions(null, selectedState.value).then((data) =>
        setCityOption(data)
      );
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedCity != null) {
      loadZipcodeOptions(null, selectedCity.value).then((data) =>
        setZipcodeOption(data)
      );
    }
  }, [selectedCity]);

  useEffect(() => {
    if (selectedZipcode != null) {
      setDisableTextField(false);
    }
  }, [selectedZipcode]);

  useEffect(() => {
    if (history.location.state && history.location.state.from) {
      if (
        history.location.state.from === "/business-register-category" ||
        history.location.state.from === "/business-register-mobile"
      ) {
        setDisplayPage(true);
      }
    }
  }, [history.location.state]);
  //eslint-disable-next-line
  const checkBusinessNotAvailable = () => {
    setBusinessNotAvailable(true);
  };
  const submit = async () => {
    if (
      _.isEmpty(selectedState) ||
      _.isEmpty(selectedCity) ||
      _.isEmpty(selectedZipcode) ||
      _.isEmpty(area)
    ) {
      setAlertErrorMsg("Please Provide All The Details");
      setAlertDisplay(true);
    } else if (!_.isEmpty(area) && (area.length >= 100 || area.length <= 3)) {
      setAlertErrorMsg("Invalid Area! accept min 3 char and max 100 char!");
      setAlertDisplay(true);
    } else {
      setAlertDisplay("none");
      let res = await getCountOfBusiness(selectedZipcode.value, category_id);
      if (res.data.count < 10) {
        let res = await addBusinessUserPartially({
          business_country: history.location.state["country"],
          business_category: [history.location.state["category_id"]],
          business_state: selectedState.value,
          business_city: selectedCity.value,
          business_zipcode: selectedZipcode.value,
          business_area: area,
          business_name: "Create Business Name",
        });
        history.push({
          pathname: "/business-register-mobile",
          state: {
            from: history.location.pathname,
            businessId: res.data["id"],
          },
        });
      } else {
        setBusinessNotAvailable(true);
      }
    }
  };

  return (
    <div className="body-container-footer">
      <div className="profile-page" style={{ display: displayPage }}>
        <div className="back-div">
          <Link
            to={{
              pathname: "/business-register-category",
              state: {
                from: history.location.pathname,
              },
            }}
            className="link"
          >
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
        <h2 style={{ marginTop: "100px" }}>Location Details</h2>
        <Alert value={alertErrorMsg} show={alertDisplay} />
        {/* <Alert value="Please Try Again" show={unabletoCreate} /> */}
        <Modal
          open={businessNotAvailable}
          aria-labelledby="server-modal-title"
          aria-describedby="server-modal-description"
        >
          <div className={"modal-container-box"}>
            <p
              style={{
                fontSize: "24px",
                color: "#eb4335",
                textAlign: "center",
              }}
            >
              No Space
            </p>
            <p
              style={{
                fontSize: "30px",
                color: "#6a6a6a",
                textAlign: "center",
                margin: 0,
              }}
            >
              Not available in this area
            </p>
            <button
              className="done-btn btn-blue full-width-btn"
              onClick={(e) => setBusinessNotAvailable(!businessNotAvailable)}
            >
              Okay
            </button>
            <p
              style={{
                fontSize: "16px",
                color: "#1a73e8",
                textAlign: "center",
              }}
            >
              Try next year
            </p>
          </div>
        </Modal>
        <div className="profile-page-element-container">
          <div className="profile-page-element">
            {/* {businessNotAvailable === true ? (
            <BusinessNotAvailable checkBusinessNotAvailable={checkBusinessNotAvailable} />
          ) : ( */}
            <>
              <StateCityPincodeField
                stateOption={stateOption}
                cityOption={cityOption}
                zipcodeOption={zipcodeOption}
                selectedState={selectedState}
                selectedCity={selectedCity}
                selectedZipcode={selectedZipcode}
                pincode={pincode}
                setPincode={setPincode}
                setSelectedState={setSelectedState}
                setSelectedCity={setSelectedCity}
                setSelectedZipcode={setSelectedZipcode}
              />
              <label from="area">Area</label>
              {disableTextField ? (
                <TextField
                  label="Area"
                  variant="outlined"
                  type="text"
                  className="full-width-input custom-material-input update-label"
                  placeholder="Add Area"
                  style={{
                    backgroundColor: "rgb(242, 242, 242)",
                    borderRadius: "35px",
                    padding: " 5px 0px 0px 0px",
                  }}
                  disabled={true}
                />
              ) : (
                <>
                  <TextField
                    label="Area"
                    variant="outlined"
                    id="area"
                    className="full-width-input custom-material-input update-label business-area-label"
                    type="text"
                    inputProps={{ maxLength: 100, minLength: 3 }}
                    placeholder="Add Area"
                    onChange={(e) => {
                      setArea(e.target.value);
                    }}
                    value={area}
                  />
                </>
              )}
              <br />
              <br />
              <button
                className="done-btn"
                onClick={submit}
                style={{ marginTop: "0px" }}
              >
                Done
              </button>
            </>
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessLocationSelection;
