import HowToInstallComman from "../CommonComponets/HowToInstallComman";
import "../../utils/common.css";

function HowToInstall() {

  return (
    <div className="body-container" >
      <HowToInstallComman
        back="/"
        whyWebApp="/why-web-app"
        video="/installation"
      />
    </div>
  );
}

export default HowToInstall;
