import "../InitialProcess/Screens.css";
import { Link } from "react-router-dom";
import IosVedioUrl from "../../assets/how_to_install/ios_rec1.mp4";
import { RiCloseCircleLine } from "react-icons/ri";
import { useLocation } from 'react-router-dom';
import IosVideo from "../../assets/how_to_install/iOS.mp4"
import androidChrome from "../../assets/how_to_install/Android_Chrome.mp4"
import Desktop_Chrome from "../../assets/how_to_install/Desktop_Chrome.mp4"
import Desktop_Edge from "../../assets/how_to_install/Desktop_Edge.mp4"
import Samsung from "../../assets/how_to_install/Samsung.mp4"



function VideoInstalltionComman(props) {
  //eslint-disable-next-line
  let video = null;
  const location = useLocation();

	// const {browser} = location.state || {};

  const browser = location.state.browser ;

	console.log(location.state, "browser")

  const get_url = (browser) => {
    if (browser === "IOS"){
      return IosVideo
    }else if(browser === "desktop_chrome"){
      return Desktop_Chrome
    }else if(browser === "desktop_edge"){
      return Desktop_Edge
    }else if(browser === "android_chrome"){
      return androidChrome
    }else if(browser === "samsung"){
      return Samsung
    }
  }

  return (
    <div className={"initial-screen-page gray"}>
      <div className={"intial-screen-container"}>
        <div style={{width:"50px", position:"absolute", right:"10px"}} >
            <Link
              to={props.back}
              style={{
                fontSize:"40px",
              }}
            >
              <RiCloseCircleLine />
            </Link>
        </div>
        
        <p
          style={{
            fontWeight: 900,
            // marginTop: "40px",
            fontSize: "30px",
            color: "#fff",
          }}
        ></p>
        <div className={"content installation"}>
          <video
            ref={(v) => {
              video = v;
            }}
            controls
            controlsList="nodownload noremoteplayback"
            style={{ width: "100%", display: "block" , height:"95vh"}}
          >
            <source src={get_url(browser)} type="video/mp4"></source>
          </video>
        </div>
      </div>
    </div>
  );
}

export default VideoInstalltionComman;
