import "./Privacy.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";

function AboutUs(props) {
  return (
    <div className="c5 privacyAndPolicy" style={{background:"#fafafa"}}>
      <div style={{backgroundColor:"#fafafa"}} className="back-div">
        <Link to="/" className="link">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <p className="c2">
        <span className="c6">About Us</span>
      </p>
      <p className="c2">
        Welcome to zopfind your ultimate online platform for connecting with
        local businesses in your area.
      </p>
      <p className="c2">
        Our mission is to help consumers find the best services and products,
        while supporting micro, small and medium-sized businesses in your
        community.
      </p>
      <p className="c2">
        At zopfind, we understand that small businesses are the backbone of the
        economy,
      </p>
      <p className="c2">
        and they are essential for creating vibrant and thriving communities.
      </p>
      <p className="c2">
        By connecting local businesses with consumers in the area, we aim to
        promote economic growth and provide convenience to our users.
      </p>
      <p className="c2">
        <span className="c6">Responsibility</span>
      </p>
      <p className="c2">
        Our platform is built on the foundation of empathy and understanding,
        which is why we strive to create an inclusive and welcoming environment.
      </p>
      <p className="c2">
        We will integrate environmental and social principles in our platform.
      </p>
      <p className="c2">
        <span className="c6">Excellence, Pioneering and Unity</span>
      </p>
      <p className="c2">
        At our zopfind, we strive for excellence in connecting consumers and
        local businesses. We are pioneering a way of bringing together these two
        groups to create a sense of unity in the community.{" "}
      </p>
      <p className="c2">
        Our commitment to this mission drives everything we do, and ensures that
        both consumers and businesses have the best possible experience using
        our platform. We believe that by working together, we can build a
        stronger, more vibrant community.
      </p>
    </div>
  );
}
export default AboutUs;
