import React, { Component } from "react";
import { Link } from "react-router-dom";
import fetchCustomerProfile from "../../services/ProfileService";
import ZopfindLogo from "../../assets/logo/logo.svg";

class PaymentTakingTime extends Component {
	constructor(props) {
		super(props);
		this.inviteRef = React.createRef();
		this.state = {
			language_name: "",
			country_name: "",
			showLoader: true,
			user_name: "",
			showProfile: "none",
			is_business_user: false,
		};
	}
	componentDidMount() {
		fetchCustomerProfile().then((res) => {
			window.localStorage.setItem(
				"customer-profile",
				JSON.stringify(res.data)
			);
			this.setState({
				language_name: res.data["user_language"],
				country_name: res.data["country"],
				user_name: res.data["user_name"],
				is_business_user: res.data["is_business_user"],
				showLoader: "none",
				showProfile: true,
			});
		});
	}
	render() {
		return (
			<div
				className="profile-container"
				style={{ display: this.state.showProfile }}
			>
				<div className="header">
					<h1 className="company-name">
						<img alt="Zopfind" src={ZopfindLogo} />
					</h1>
				</div>
				<div className="profile-element">
					<p>
						Please wait, payment taking time. Please re-login after
						few min. If you face issue then contact administration
						for the solution.
					</p>
					<p>
						<Link to="/my-business">Go to Listing Page</Link>
					</p>
				</div>
			</div>
		);
	}
}

export default PaymentTakingTime;
