const getLengthOfWord = (word) => {
  const temp = document.createElement("span");
  temp.style.visibility = "hidden";
  temp.style.whiteSpace = "nowrap";
  temp.style.fontSize = "16px";
  temp.style.fontFamily = "Roboto";
  temp.textContent = word;
  document.body.appendChild(temp);
  const wordWidth = temp.offsetWidth;
  document.body.removeChild(temp);
  return wordWidth;
};

const findSpacePositions = (word) => {
  var spacePositions = [];
  for (var i = 0; i < word.length; i++) {
    if (word[i] === " ") {
      spacePositions.push(i);
    }
  }
  return spacePositions;
};

const removeEmptyStrings = (arr) => {
  return arr.filter(function (item) {
    return item.trim() !== "";
  });
};

export const breakWords = (originalWord, limit) => {
  let breakedWords = [];
  let word, wordCut;
  if (getLengthOfWord(originalWord) >= limit) {
    let start = 0;
    let len = originalWord.length;
    let i = 1;
    let flag = true;
    let previous_cut = 0;
    while (len >= i) {
      if (start !== 0 && flag) {
        word = "-" + originalWord.slice(start, i);
      } else {
        word = originalWord.slice(start, i);
      }
      let wordWidth = getLengthOfWord(word);
      if (wordWidth > limit) {
        let spaceList = findSpacePositions(word);
        let spaceLenght = spaceList.length;
        if (spaceLenght > 0) {
          breakedWords.push(word.slice(0, spaceList[spaceList.length - 1]));
          start = previous_cut + spaceList[spaceLenght - 1] + 1;
          previous_cut = start;
          flag = false;
        } else {
          if (word.slice(-3)[0] === " ") {
            start = i - 2;
            flag = false;
            len++;
            breakedWords.push(word.slice(0, -2));
          } else {
            wordCut = word.slice(0, -1) + "-";
            let wordWidth2 = getLengthOfWord(wordCut);
            if (wordWidth2 > limit) {
              breakedWords.push(wordCut.slice(0, -2) + "-");
              word = "-" + word.slice(i - 2);
              len++;
              start = i - 2;
              flag = true;
            } else {
              breakedWords.push(wordCut.slice(0, -1) + "-");
              word = "-" + word.slice(i - 1);
              len++;
              start = i - 1;
              flag = true;
            }
          }
        }
      }
      i++;
    }
    breakedWords.push(word);
  } else {
    breakedWords.push(originalWord);
  }
  return removeEmptyStrings(breakedWords);
};
