import axios from "axios";

export const getCities = async (city, state_id) => {
  try {
    const config = {
      params: {
        state_name: state_id,
        active:true
      },
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/accounts/cities/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const loadCityOptions = async (search, state_id) => {
  return await getCities(search, state_id).then(({ data }) => {
    let options = data.map((city_obj) => ({
      value: city_obj.id,
      label: city_obj.city_name,
    }));
    return options;
  });
};

export const getCityById = async (city_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    const res = await axios.get(
      process.env.REACT_APP_BASE_URL_API+ "/api/v1/accounts/cities/"
      + city_id ,
      config
    )
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
}