import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPaymentStatus } from "../../services/PaymentService";
import PaymentSucessFull from "../../components/BusinessRegistration/PaymentSucessFull";
import PaymentNotSucessFull from "../../components/BusinessRegistration/PaymentNotSucessFull";

function PaymentStatus() {
  const [status, setStatus] = useState("");
  let { order_id } = useParams();

  useEffect(() => {
    getPaymentStatus(order_id).then((res) => {
      setStatus(res.data["payment_status"]);
    });
  }, [order_id]);

  if (status === "SUCCESS") {
    return <PaymentSucessFull />;
  } else if (
    status === "NOT_ATTEMPTED" ||
    status === "FAILED" ||
    status === "USER_DROPPED" ||
    status === "CANCELLED" ||
    status === "PENDING" ||
    status === "VOID"
  ) {
    return <PaymentNotSucessFull />;
  }
  return <div></div>;
}

export default PaymentStatus;
