import VideoInstalltionComman from "../CommonComponets/VideoInstalltionComman";
import "../../utils/common.css"

function VideoInstalltion() {
  return <div className="body-container" >
    <VideoInstalltionComman back="/how-to-install" />;
  </div>
}

export default VideoInstalltion;
