import "../InitialProcess/Screens.css";
import "./HowToInstallComman.css";
import { Link, useHistory } from "react-router-dom";
import SafariSvg from "../../assets/how_to_install/safari.svg";
import ChromeSvg from "../../assets/how_to_install/chrome.svg";
import SamsungSvg from "../../assets/how_to_install/samsung.svg";
import EdgeSvg from "../../assets/how_to_install/edge.svg";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import play from "../../assets/icons/play.png";
import "../../utils/common.css";


function HowToInstallComman(props) {
  const history = useHistory();
  function isIOSDevice() {
    return !!navigator.userAgent && /iPad|iPhone/.test(navigator.userAgent);
  }
  function isAndroidDevice() {
    return !!navigator.userAgent && /Android/.test(navigator.userAgent);
  }

  return (
    // .body-container {
    //   height: 100vh;
    //   width: 100%;
    //   position: fixed;
    //   top: 0;
    //   overflow: scroll;
    // }
    <div style={{ height: "100%", width: "100%"}} className="" >
      <div className={"initial-screen-page"}>
        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}} className={"intial-screen-container"}>
          {/* <div  > */}
            <div  className="back-btn back-btn-1">
              <Link
                to={{
                  pathname: props.back,
                  state: {
                    from: history.location.pathname,
                  },
                }}
                className="link"
              >
                <img
                  src={backArrowIcon}
                  alt={"back-icon"}
                  className="back-icon back-icon-hti"
                  height="20px"
                />
              </Link>
            </div>
            <div className="top-space" />
            <h2 style={{ margin: "10px 0 0px", fontSize: "25px" }}>
              How to install Web App
            </h2>
            <div className={"content how-to-install"}>
              {/* <div className={"mobile-device without-margin"}> */}
              <p style={{ fontSize: "36px", margin: "30px 0" }}>
                Only use {isIOSDevice() ? "Safari browser" : "these two browser's"}
              </p>
              <p style={{ fontSize: "25px", fontWeight: 200 }}>
                Select to play video
              </p>
              <div
                className="browsers-selection"
                id="browser-selection-id"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: window.innerWidth > 768 ? "60px" : "30px",
                }}
              >
                {isIOSDevice() ? (
                  <div>
                    <Link to={{pathname:"/installation", state: { browser: "IOS" }}} className="browser-link">
                      <div id="samsung-symbol">
                        <img height="80px" src={SafariSvg} alt="" />
                        <img id="play-icon" src={play} alt="" />
                        <p className="browser-text">
                          Safari
                          <br />
                          Browser
                        </p>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <>
                    <div id="samsung-symbol">
                      <Link to={{pathname:"/installation", state: { browser: window.innerWidth > 700 ? "desktop_chrome" : "android_chrome" }}} className="browser-link">
                        <img height="80px" src={ChromeSvg} alt="" />
                        <img id="play-icon" src={play} alt="" />

                        <p className="browser-text">
                          Google
                          <br />
                          Chrome
                        </p>
                      </Link>
                    </div>
                    {isAndroidDevice() ? (
                      <>
                        <div id="samsung-symbol">
                          <Link to={{pathname:"/installation", state: { browser: "samsung" }}} className="browser-link">
                            <img height="80px" src={SamsungSvg} alt="" />
                            <img id="play-icon" src={play} alt="" />
                            <p className="browser-text">
                              Samsung
                              <br />
                              Browser
                            </p>
                          </Link>
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="samsung-symbol">
                          <Link to={{pathname:"/installation", state: { browser: "desktop_edge" }}}  className="browser-link">
                            <img height="80px" src={EdgeSvg} alt="" />
                            <img id="play-icon" src={play} alt="" />
                            <p className="browser-text">
                              Edge
                              <br />
                              Browser
                            </p>
                          </Link>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          {/* </div> */}

          <div id="initial-screen-why-div" className="initial-screen-why">
            <p
              style={{
                fontSize: "18px",
                padding: "0",
                fontWeight: 400,
                fontFamily: "Roboto",
                textAlign: "center",
                margin: "0",
                backgroundColor:"white"
              }}
            >
              Web apps are next generation of app's
            </p>
            <p
              style={{
                // color: "#fff",
                backgroundColor:"white",
                fontSize: "18px",
                padding: "10px 0 0 0",
                fontWeight: 400,
                fontFamily: "Roboto",
                textAlign: "center",
                margin: "0",
              }}
            >
              <Link
                to={props.whyWebApp}
                style={{
                  textDecoration: "none",
                  color: "#1a73e8",
                }}
              >
                Why?
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>

  );
}

export default HowToInstallComman;
