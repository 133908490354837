import "./Category.css";
import "../../utils/common.css";
import { getCategoies, getSubCategoies } from "../../services/Categories";
import { useState, useEffect, useRef } from "react";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useHistory } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import Loader from "react-loader-spinner";

const history = [];
let titleHistory = [];

function Category() {
  const myHistory = useHistory();
  const [data, setData] = useState(null);
  const dataRef = useRef(data);
  dataRef.current = data;
  const [title, setTitle] = useState("Select Category");
  const [showLoader, setShowLoader] = useState("none");
  const [showCategories, setShowCategories] = useState("none");
  const [backToHome, setbackToHome] = useState(false);
  const [fromHome, setFromHome] = useState(false);
  // console.log(titleHistory,"titleHistory", title, "title")
  const goBack = () => {
    if (history.length >= 1) {
      setData(history.pop());
      console.log(6);
    } else {
      if (backToHome) {
        myHistory.push({
          pathname: "/home",
          state: {
            from: myHistory.location.pathname,
          },
        });
      }
    }
    if (titleHistory.length >= 1) {
      titleHistory.pop();
      setTitle(titleHistory[titleHistory.length - 1]);
    }
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (myHistory.location.state) {
      if (myHistory.location.state.data.length === 0) {
        console.log(1);
        setData(null);
        myHistory.push({
          pathname: "/category-search",
          state: {
            category: null,
            from: "/home",
          },
        });
        setbackToHome(true);
        scrollToTop();
        console.log(1);
      } else {
        console.log(2);
        setData(myHistory.location.state.data);
        setTitle(" ");
        setShowLoader("none");
        setShowCategories("block");
        setbackToHome(true);
        titleHistory = [];
        setFromHome(true);
        // console.log("here inside")
      }
    } else {
      getCategoies().then((res) => {
        console.log(3);
        setData(res.data);
        setShowLoader("none");
        setShowCategories("block");
        titleHistory = ["Select Category"];
      });
    }
  }, [myHistory]);

  useEffect(() => {
    const section = document.querySelector("#category-scroll");
    section.scrollIntoView({ behavior: "instant", block: "start" });
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      if (!dataRef.current) setShowLoader("block");
    }, 3000);
  }, []);

  const checkBackAndTitle = (someData, title) => {
    if (title !== "Select Category") {
      return (
        <div
          style={{ border: "none", marginBottom: "0px" }}
          className="back-div"
        >
          <p onClick={goBack} className="link" style={{ marginTop: "17px" }}>
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </p>
        </div>
      );
    } else {
      // setTitle("Select Category");
    }
  };

  const returnComponent = (someData) => {
    someData.sort((a, b) => a.category_name.localeCompare(b.category_name));
    return someData.map((el) => {
      if (el.has_child) {
        return (
          <div
            key={el.id}
            className={`each-category-element ${
              el.main_category ? "main-category " + el.name_key : ""
            }`}
            onClick={() => {
              getSubCategoies(el.category_name,el.parent_category_name).then((res) => {
                history.push(data);
                setData(res.data);
                setTitle(" ");
                titleHistory.push(el.category_name);
              });

              scrollToTop();
            }}
          >
            <div className="each-category">
              <p
                className="each-sub-category each-category"
                style={{ marginLeft: "0px", margin: "0px" }}
              >
                {el.category_name}
              </p>
              <FaChevronRight fill="#1a73e8" />
            </div>
          </div>
        );
      } else {
        return (
          <div
            key={el.id}
            className={`each-category-element ${
              el.main_category ? "main-category " + el.name_key : ""
            }`}
            onClick={() => {
              console.log(4);
              console.log(fromHome, "fromHome");
              // history was not deleted completely
              history.pop();
              myHistory.push({
                pathname: "/category-search",
                state: {
                  category: el.id,
                  from: fromHome ? "/home" : myHistory.location.pathname,
                },
              });
              scrollToTop();
            }}
          >
            <div className="each-category">
              <p
                className="each-sub-category each-category main-category-element"
                style={{ marginLeft: "0px", margin: "0px" }}
              >
                {el.category_name}
              </p>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="body-container-footer">
      <div
        style={{ display: "inline", position: "relative", top: "0" }}
        id="category-scroll"
      ></div>
      <Loader
        type="TailSpin"
        height={"100px"}
        width={"100px"}
        color="#1a73e8"
        style={{ display: showLoader }}
        className={"loader"}
      />
      <div className="profile-page" style={{ display: showCategories }}>
        {data != null ? checkBackAndTitle(data, title) : null}
        <h2
          className={
            title === "Select Category"
              ? "category-title without-header"
              : "category-title"
          }
          style={{ color: "#6a6a6a" }}
        >
          {title}
        </h2>
        <div
          style={{ height: "auto" }}
          className="profile-page-element-container"
        >
          <div className="profile-page-element category-element">
            {data != null ? returnComponent(data) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category;
