import axios from "axios";

const checkTokenPresent = () => {
  if (window.localStorage.getItem("token") !== "") {
    return true;
  } else {
    return false;
  }
};

export const fetchCustomerProfile = async (accesstoken) => {
  if (checkTokenPresent()) {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/accounts/user/",
      config
    );
    res.data = res.data[0];
    return res;
  } else {
    window.location.href = "/";
  }
};

export const updateCustomerProfileDetails = async (body) => {
  if (checkTokenPresent() || true) {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    let res = await axios.put(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/accounts/update_user/",
      body,
      config
    );
    return res;
  } else {
    window.location.href = "/";
  }
};

export const updateUserToCustomer = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/accounts/update_user_to_customer/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const fetchCustomerOfferAndDeals = async () => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        username: "anshul",
        is_saved: true,
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/business/get_deals_in_your_area/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};
export const deleteDealsAndOffers = async (id) => {
  let config = {
    params: {
      deal_id: id,
    },
    headers: {
      Authorization: `Token ${window.localStorage.getItem("token")}`,
    },
  };
  const res = await axios.get(
    process.env.REACT_APP_BASE_URL_API +
      "/api/v1/business/delete_deals_offers/",
    config
  );
  return res;
};

export default fetchCustomerProfile;
