import "./BusinessRegistration.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { getPlans } from "../../services/PaymentService";
import PaymentPlans from "../../components/BusinessRegistration/PaymentPlans";
import PaymentMode from "../../components/BusinessRegistration/PaymentMode";
import { initializePayment } from "../../services/PaymentService";

function PaymentStarter() {
  const [msg, setMsg] = useState(null);
  const [status, setStatus] = useState(0);
  const [userPlan, setUserPlan] = useState(null);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPlans().then((res) => {
      setPlans(res.data);
    });
  }, []);

  const planSelected = (plan) => {
    setUserPlan(plan);
    setStatus(1);
  };

  const paymentModeSelected = (mode) => {
    cashfree_order(mode.value);
  };

  const cashfree_order = (modeValue) => {
    initializePayment(userPlan.id, modeValue).then((res) => {
      if (res.data.payment_link) {
        const paymentSessionId = res.data.postData["payment_session_id"];
        /* global Cashfree */
        const cashfree = new Cashfree(paymentSessionId);
        cashfree.redirect();
      } else {
        setMsg(res.data.order_resp["message"]);
      }
    });
  };

  return (
    <div className="body-container-footer">
      <div className="profile-page payment">
        <div className="back-div" style={{ border: "none" }}>
          <Link to="/profile" className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
        {msg != null ? (
          <React.Fragment>
            <div
              style={{
                marginTop: "80px",
              }}
            >
              <h3>Cashfree Link</h3>
              <div className="other-page-element-container">
                <p
                  style={{
                    fontSize: "18px",
                    padding: "10px",
                    backgroundColor: "#27c327",
                    color: "#fff",
                    width: "90%",
                  }}
                >
                  {msg}
                </p>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {status === 0 && (
              <PaymentPlans plans={plans} planSelected={planSelected} />
            )}
            {status === 1 && (
              <PaymentMode SelectPaymentMode={paymentModeSelected} />
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default PaymentStarter;
