import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";

function HelpPage() {
  return (
    <>
      <div className="body-container-footer">
        <div className="profile-page">
          <div style={{ border: "none" }} className="back-div">
            <Link to="/setting" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>

          <div className="other-page-element-container">
            <div className="other-page-element light-brown withoutmargin">
              <h2
                className={"title-color title"}
                id="setting-heading"
                style={{ marginBottom: "0px" }}
              >
                Help
              </h2>
              <div className="other-element-box">
                <div className="help">
                  <Link to="/help-report-problem" className="link margin-30">
                    <p className="remove-top-margin">Report a Problem</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelpPage;
