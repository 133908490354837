import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import fetchCustomerProfile, {
  deleteDealsAndOffers,
  fetchCustomerOfferAndDeals,
} from "../../services/ProfileService";
import Loader from "react-loader-spinner";
import _ from "lodash";
import BestDeal from "../BestDeal/BestDeal";
import Offer from "../Offer/Offer";
import discountImage from "../../assets/images/discount.png";

function SavedOfferAndDeals() {
  
  return (
    <>
      <div style={{ width: "100%", height: "100%",position:"relative", backgroundImage: `url(${discountImage})`, backgroundRepeat:"no-repeat",backgroundSize:"contain",backgroundPosition: "center" }}      >
        <div style={{ position:"absolute",top:"0",left:"0", height:"60px",background:"transparent" }} className="profile-page">
          <div
            style={{ border: "none", backgroundColor: "transparent" }}
            className="back-div"
          >
            <Link to="/saved-offer-and-deals" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default SavedOfferAndDeals;
