import axios from "axios";

export const getState = async () => {
  try {

    const config = {
      params:{
        format: "json",
        active:true
      },
      headers:{
        Authorization: `Token ${window.localStorage.getItem("token")}`

      }
    }
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/accounts/state/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const loadStateOptions = async () => {
  return await getState().then(({ data }) => {
    let options = data.map((state_obj) => ({
      value: state_obj.id,
      label: state_obj.state_name,
    }));
    return options;
  });
};
