import { IoMdCall, IoMdShareAlt, IoIosList } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import "./BusinessGetListingForCategory.css";
import { checkAvailability } from "../../utils/common";
import { useHistory } from "react-router-dom";

function BusinessGetListingForCategory(props) {
  const history = useHistory();

  const categoriesDisplay = (categories_set) => {
    let new_cat = [];
    for (let cat in categories_set) {
      new_cat.push(categories_set[cat].split(" ").join("\u00A0"));
    }
    return new_cat.join(", ");
  };

  return (
    <div
      className="listing-sub-category-container"
      style={{
        display: !props.filterDisplay ? "flex" : "none",
        marginTop: "60px",
      }}
    >
      {props.filterDisplay === false ? (
        <div className="search-filter-keywords">
          {props.selectedStateCity ? (
            <div
              className="keyword"
              onClick={(e) => {
                props.openFilters("city");
              }}
            >
              {props.selectedStateCity.label}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : null}
      {props.businessListing.length === 0 && (
        <div className="no-business-div">
          <p>Coming Soon...</p>
        </div>
      )}
      {props.businessListing.map((listing) => (
        <div className="listing-main-details" key={listing.id}>
          <div className={"search-main-details"}>
            <div
              className="listing-header-container"
              onClick={() => {
                const url = "/business-page/" + listing.id;
                history.push({
                  pathname: url,
                  state: {
                    from: history.location.pathname,
                  },
                });
              }}
            >
              <h3>{listing.business_name}</h3>
              <p
                style={{
                  fontSize: "18px",
                  margin: "0 0 10px 0",
                  padding: "0px",
                  textAlign: "left",
                }}
              >
                {categoriesDisplay(listing.categories_set)}
              </p>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0 0 10px 0",
                  padding: "0px",
                }}
              >
                {listing.business_city}, {listing.business_area},{" "}
                {listing.business_zipcode}
              </p>
              <p
                style={{ marginBottom: "15px" }}
                className={"time-availability"}
              >
                {checkAvailability(listing.business_timeing)}
              </p>
            </div>
            <div className="social-icons-container">
              <div className="group-social blue-bg">
                <div>
                  <IoMdCall
                    className="social-icon-2 social-icon-big "
                    fill="#fff"
                    onClick={() =>
                      (window.location.href = `tel:+91${listing.business_mobile_number}`)
                    }
                  />
                  <p>Call</p>
                </div>
                <div>
                  {listing.business_location_link_visibility ? (
                    <IoLocationSharp
                      className="social-icon-2 social-icon-big"
                      fill="#fff"
                      onClick={() =>
                        (window.location.href = listing.business_location_link)
                      }
                    />
                  ) : (
                    <IoLocationSharp className="social-icon-disabled social-icon-big" />
                  )}
                  <p>Location</p>
                </div>
                <div>
                  <IoMdShareAlt
                    className="social-icon-2 social-icon-big"
                    fill="#fff"
                    onClick={async () => {
                      try {
                        await navigator
                          .share({
                            title: "Business Link",
                            text: `Find ${listing["business_name"]}, ${listing[
                              "categories_set"
                            ].join(", ")} on zopfind.`,
                            url: `${process.env.REACT_APP_BASE_URL_1}/business-page/${listing["id"]}`,
                          })
                          .then(() => {
                            console.log("Thanks for sharing!!!!");
                          });
                      } catch (err) {
                        await toast.info("Link can't be share");
                      }
                    }}
                  />
                  <p>Share</p>
                </div>
                <div>
                  {
                    <IoIosList
                      className="social-icon-2 social-icon-big"
                      fill="#fff"
                      onClick={() => {
                        const url = "/business-page/" + listing.id;
                        history.push({
                          pathname: url,
                          state: {
                            from: history.location.pathname,
                            list: true,
                          },
                        });
                      }}
                    />
                  }
                  <p>List</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BusinessGetListingForCategory;
