// import "./TermAndService.css";
import { Link, useHistory } from "react-router-dom";
import backArrowIcon from "../../../assets/icons/Back arrow rounded.svg";
import TermAndServiceComman from "../../CommonComponets/TermsAndServiceComman";
import "../../../utils/common.css";

function TermAndServiceSettings() {
  const history = useHistory();
  const goBackLink = () => {
    history.goBack();
  };
  return (
    <>
      <div className="body-container-footer">
        <div className="c6 termAndService">
          <div style={{ border: "none" }} className="back-div">
            <Link onClick={goBackLink} className="link" to="">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <TermAndServiceComman />
        </div>
      </div>
    </>
  );
}
export default TermAndServiceSettings;
