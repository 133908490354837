import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { toast } from "react-toastify";

function Website(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Website
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessWebsiteVisibility(
                !props.businessWebsiteVisibility
              )
            }
            checked={props.businessWebsiteVisibility}
          />
          <br />
          <br />

          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Website"
            variant="outlined"
            id="website"
            inputProps={{ maxLength: 400, minLength: 3 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Add Website"
            onChange={(e) => props.setBusinessWebsite(e.target.value)}
            value={props.businessWebsite}
            disabled={!props.businessWebsiteVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (props.businessWebsiteVisibility === false && JSON.parse(localStorage.getItem("business_website_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessWebsiteVisibility === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_website: props.businessWebsite,
                  business_website_visibility: props.businessWebsiteVisibility,
                });
                localStorage.setItem("business_website_visibility",  props.businessWebsiteVisibility)

              } else {
                if (
                  props.businessWebsiteVisibility === true &&
                  (props.businessWebsite === "" ||
                    props.businessWebsite === null)
                ) {
                  setAlertMessgae("Please fill the input!");
                  setShowAlert("block");
                } else if (
                  props.businessWebsite.length < 3 ||
                  props.businessWebsite.length > 400 ||
                  !/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i.test(
                    props.businessWebsite
                  )
                ) {
                  setAlertMessgae(
                    "Invalid input! accept only min 3 and max 400 char"
                  );
                  setShowAlert("block");
                } else {
                  setShowAlert("none");
                  await props.updateDetails({
                    business_website: props.businessWebsite,
                    business_website_visibility:
                      props.businessWebsiteVisibility,
                  });
                localStorage.setItem("business_website_visibility",  props.businessWebsiteVisibility)

                }
              }
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default Website;