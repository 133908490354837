import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";

function SettingPage() {
  const history = useHistory();

  return (
    <>
      <div className="body-container-footer">
        <div className="profile-page">
          <div style={{ border: "none" }} className="back-div">
            <Link to="/profile" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>

          <div className="other-page-element-container">
            <div className="other-page-element light-brown">
              <h2 data-test="s" id="setting-heading" className={"title-color title"}>
                Settings
              </h2>
              <div className="other-element-box">
                <div id="setting-link-cont">
                  <Link
                    id="setting-link"
                    to="setting-account"
                    className="link setting-link"
                  >
                    <p id="p-link" className="remove-top-margin">
                      Account
                    </p>
                  </Link>
                  <Link
                    id="setting-link"
                    to="/setting-help"
                    className="link setting-link"
                  >
                    <p id="p-link">Help</p>
                  </Link>
                  <Link
                    id="setting-link"
                    to="/setting-about"
                    className="link setting-link"
                  >
                    <p id="p-link">About</p>
                  </Link>
                  <div
                    onClick={() =>
                      history.push({
                        pathname: "/setting-how-to-install",
                        state: {
                          from: "/setting",
                        },
                      })
                    }
                    className="link elements elem-listing"
                  >
                    <div id="how-to-app-cont" className="listing">
                      <p style={{ color: "#fff", textAlign: "left" }}>
                        How To Install Web App
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SettingPage;
