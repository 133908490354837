import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { getUserBusinessDetails } from "../../services/BusinessService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./EditBusinessProfile.css";
import "../../utils/common.css";

function EditBusinessProfile(props) {
  const [businessAddressLine1, setBusinessAddressLine1] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessCity, setBusinessCity] = useState("");
  const [businessPincode, setBusinessPincode] = useState("");
  const [businessArea, setBusinessArea] = useState("");
  const [businessCategory, setBusinessCategory] = useState([]);

  const [showLoader, setShowLoader] = useState("none");
  const [showProfile, setShowProfile] = useState("none");

  const dataRef = useRef(businessName);
  dataRef.current = businessName;

  useEffect(() => {
    setTimeout(() => {
      if (!dataRef.current) setShowLoader("block");
    }, 3000);
  }, []);

  useEffect(() => {
    getUserBusinessDetails().then((res) => {
      let data = res.data[0];
      setBusinessName(data["business_name"]);
      setBusinessPincode(data["business_zipcode"]);
      setBusinessCity(data["business_city"]);
      setBusinessArea(data["business_area"]);
      setBusinessCategory(data["categories_set"]);
      setBusinessAddressLine1(data["business_address_line1"]);
      setShowLoader("none");
      setShowProfile(true);
    });
  }, []);
  const getBusinessAddress = () => {
    const address = [];
    if (businessAddressLine1) {
      address.push(businessAddressLine1);
    }
    if (businessArea) {
      address.push(businessArea);
    }
    return address.join(", ");
  };

  const categoriesDisplay = (categories_set) => {
    let new_cat = [];
    for (let cat in categories_set) {
      new_cat.push(categories_set[cat].split(" ").join("\u00A0"));
    }
    return new_cat.join(", ");
  };
  return (
    <>
      <Loader
        type="TailSpin"
        height={"100px"}
        width={"100px"}
        color="#1a73e8"
        style={{ display: showLoader }}
        className={"loader"}
      />
      <div className="body-container-footer">
        <div className="profile-page" style={{ display: showProfile }}>
          <div
            style={{ border: "none" }}
            id="edit-biz-back-div"
            className="back-div"
          >
            <Link to="/my-business" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <div className="other-page-element-container">
            <div
              className="other-page-element light-brown"
              style={{ alignItems: "flex-start" }}
            >
              <h2
                style={{ position: "relative", marginBottom: "20px" }}
                className={"title-color title"}
              >
                Edit
              </h2>
              <ToastContainer />
              <div other-element-box>
                <Link
                  to="/business/edit-profile-name"
                  className="link full-width-btn"
                >
                  <p style={{ color: "#333", marginTop: 0, textAlign: "left" }}>
                    <strong>{businessName}</strong>
                  </p>
                </Link>

                <p style={{ color: "#757575", textAlign: "left" }}>
                  {categoriesDisplay(businessCategory)}
                </p>
                <p style={{ color: "#757575", textAlign: "left" }}>
                  {businessCity}
                </p>
                <p style={{ color: "#757575", textAlign: "left" }}>
                  {businessPincode}
                </p>
                <p style={{ color: "#757575", textAlign: "left" }}>
                  {getBusinessAddress()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditBusinessProfile;
