import "./HorizontalScroller.scss";
import mobileStore from "../../assets/front_icons/Mobile Shops.svg";
import automotive from "../../assets/front_icons/Automotive.svg";
import baby from "../../assets/front_icons/Baby and Child care.svg";
import beauty from "../../assets/front_icons/Beauty and Fashion.svg";
import diagnostics from "../../assets/front_icons/Diagnostics.svg";
import doctors from "../../assets/front_icons/Doctors.svg";
import eLearning from "../../assets/front_icons/E-Learning.svg";
import education from "../../assets/front_icons/Education.svg";
import financialServices from "../../assets/front_icons/Financial Services.svg";
import fitness from "../../assets/front_icons/Fitness.svg";
import grocery from "../../assets/front_icons/Grocery Shops.svg";
import health from "../../assets/front_icons/Health and Medical.svg";
import homeServices from "../../assets/front_icons/Home Services.svg";
import hospitals from "../../assets/front_icons/Hospitals.svg";
import jwellery from "../../assets/front_icons/Jewellery.svg";
import lawers from "../../assets/front_icons/Lawers.svg";
import matrimonial from "../../assets/front_icons/Matrimonial Bureaus.svg";
import pathology from "../../assets/front_icons/Pathology Labs.svg";
import pharmacy from "../../assets/front_icons/Pharmacy.svg";
import photography from "../../assets/front_icons/Photography.svg";
import professionalServices from "../../assets/front_icons/Professional Services.svg";
import realEstate from "../../assets/front_icons/Real Estate and Construction.svg";
import mwFashion from "../../assets/front_icons/Men and Women Fashion.svg";
import repairs from "../../assets/front_icons/Repairs.svg";
import restaurants from "../../assets/front_icons/Restaurants.svg";
import salon from "../../assets/front_icons/Salon.svg";
import services from "../../assets/front_icons/Services.svg";
import shopping from "../../assets/front_icons/Shopping.svg";
import spa from "../../assets/front_icons/Spa.svg";
import wedding from "../../assets/front_icons/Wedding.svg";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { getSubCategoies } from "../../services/Categories";
import { getCategory } from "../../services/Categories";

function getCategoiesWithIcon() {
  return [
    [
      [
        {
          name: "Doctors",
          icon: doctors,
          pathname: "/categories",
          params: "Doctors",
        },
        {
          name: "Jewellery",
          icon: jwellery,
          pathname: "/categories",
          params: "Jewellery",
        },
        {
          name: "Restaurants",
          icon: restaurants,
          pathname: "/categories",
          params: "Restaurants",
        },
      ],
      [
        {
          name: "Salon",
          icon: salon,
          pathname: "/categories",
          params: "Salons",
        },
        {
          name: "Real Estate and <br/> Construction",
          icon: realEstate,
          pathname: "/categories",
          params: "Real Estate and Construction",
        },
        {
          name: "Wedding",
          icon: wedding,
          pathname: "/categories",
          params: "Wedding",
        },
      ],
    ],

    [
      [
        {
          name: "Beauty and <br/> Fashion",
          icon: beauty,
          pathname: "/categories",
          params: "Beauty and Fashion",
        },
        {
          name: "Home <br/>Services",
          icon: homeServices,
          pathname: "/categories",
          params: "Home Services",
        },
        {
          name: "Diagnostics",
          icon: diagnostics,
          pathname: "/categories",
          params: "Diagnostics",
        },
      ],
      [
        {
          name: "Lawers",
          icon: lawers,
          pathname: "/categories",
          params: "Lawers",
        },
        {
          name: "Repairs",
          icon: repairs,
          pathname: "/categories",
          params: "Repairs",
        },
        {
          name: "Hospitals",
          icon: hospitals,
          pathname: "/categories",
          params: "Hospitals",
        },
      ],
    ],

    [
      [
        {
          name: "Baby and <br/> Child Care",
          icon: baby,
          pathname: "/categories",
          params: "Baby and Child Care",
        },

        {
          name: "Training",
          icon: education,
          pathname: "/categories",
          params: "Training",
        },
        {
          name: "Pharmacy",
          icon: pharmacy,
          pathname: "/categories",
          params: "Pharmacy",
        },
      ],
      [
        {
          name: "Matrimonial <br/>Bureaus",
          icon: matrimonial,
          pathname: "/categories",
          params: "Matrimonial Bureaus",
        },
        {
          name: "Professional <br/>Services",
          icon: professionalServices,
          pathname: "/categories",
          params: "Professional Services",
        },
        {
          name: "Pathology <br/>Labs",
          icon: pathology,
          pathname: "/categories",
          params: "Pathology Labs",
        },
      ],
    ],

    [
      [
        {
          name: "Financial <br/>Services",
          icon: financialServices,
          pathname: "/categories",
          params: "Financial Services",
        },
        {
          name: "Fitness",
          icon: fitness,
          pathname: "/categories",
          params: "Fitness",
        },
        {
          name: "E-Training",
          icon: eLearning,
          pathname: "/categories",
          params: "E-Training",
        },
      ],
      [
        {
          name: "Men and <br/> Women's Fashion",
          icon: mwFashion,
          pathname: "/categories",
          params: "Men and Women's Fashion",
        },
        {
          name: "Spas",
          icon: spa,
          pathname: "/categories",
          params: "Spas",
        },

        {
          name: "Photography",
          icon: photography,
          pathname: "/categories",
          params: "Photography",
        },
      ],
    ],

    [
      [
        {
          name: "Health and <br/>Medical",
          icon: health,
          pathname: "/categories",
          params: "Health and Medical",
        },
        {
          name: "Services",
          icon: services,
          pathname: "/categories",
          params: "Services",
        },
        {
          name: "Shopping",
          icon: shopping,
          pathname: "/categories",
          params: "Shopping",
        },
      ],
      [
        {
          name: "Automotive",
          icon: automotive,
          pathname: "/categories",
          params: "Automotive",
        },
        {
          name: "Mobile <br/> Stores",
          icon: mobileStore,
          pathname: "/categories",
          params: "Mobile Stores",
        },
        {
          name: "Grocery <br/>Shops",
          icon: grocery,
          pathname: "/categories",
          params: "Grocery Shops",
        },
      ],
    ],
  ];
}

function HorizontalScroller() {
  const history = useHistory();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <ul style={{ margin: "20px 0" }}>{dots}</ul>;
    },
  };

  const returnIcon = (obj, iIdx) => (
    <div
      key={`${iIdx}-item`}
      onClick={() => {
        if(obj.name === "Photography"){
          getSubCategoies(obj.params,"Professional Services").then((res) => {
            if (res.data.length === 0) {
              getCategory({ category_name: obj.params }).then((res2) => {
                history.push({
                  pathname: obj.pathname,
                  state: {
                    data: res.data,
                  },
                });
              });
            } else {
              history.push({
                pathname: obj.pathname,
                state: {
                  data: res.data,
                },
              });
            }
          });
        }
        else{
          getSubCategoies(obj.params).then((res) => {
            if (res.data.length === 0) {
              getCategory({ category_name: obj.params }).then((res2) => {
                history.push({
                  pathname: obj.pathname,
                  state: {
                    data: res.data,
                  },
                });
              });
            } else {
              history.push({
                pathname: obj.pathname,
                state: {
                  data: res.data,
                },
              });
            }
          });
        }
      }}
    >
      <img src={obj.icon} alt={obj.name} />
      <p dangerouslySetInnerHTML={{ __html: obj.name }}></p>
    </div>
  );

  const returnIconGroup = (group, gidx) => (
    <div key={`${gidx}-group`}>
      {group.map((item, iIdx) => returnIcon(item, iIdx))}
    </div>
  );

  return (
    <div className="scrolling-wrapper-flexbox">
      <Slider {...settings}>
        {getCategoiesWithIcon().map((cards, idx) => {
          return (
            <div key={`${idx}-card`} className="card">
              {" "}
              {cards.map((group, gidx) => returnIconGroup(group, gidx))}
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default HorizontalScroller;
