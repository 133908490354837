import "./Privacy.css";
import { Link } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useHistory } from "react-router-dom";
import "../../utils/common.css";

function CopyRight() {
  const history = useHistory();
  const goBackLink = () => {
    history.goBack();
  };
  return (
    <>
      <div
        className="body-container-footer"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="c5 privacyAndPolicy copyRight">
          <div
            style={{ border: "none", backgroundColor: "#fafafa" }}
            className="back-div"
          >
            <Link onClick={goBackLink} to="" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <p className="c2">
            <span className="c0">
              zopfind <sup>TM</sup> Copyright &copy; 2023. All Rights Reserved.
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

export default CopyRight;
