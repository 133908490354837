import "./Profile.css";
import "../../utils/common.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fetchCustomerProfile from "../../services/ProfileService";
import Loader from "react-loader-spinner";
import { getUserBusinessDetails } from "../../services/BusinessService";

function Profile() {
  const [languageName, setLanguageName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [showLoader, setShowLoader] = useState("block");
  const [showProfile, setShowProfile] = useState("none");
  const [isBusinessUser, setIsBusinessUser] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [is_has_subscription, setIsHasSubscription] = useState(false);
  const [businessId, setBusinessId] = useState(null);

  useEffect(() => {
    fetchCustomerProfile().then((res) => {
      setLanguageName(res.data["user_language"]);
      setCountryName(res.data["country"]);
      setIsBusinessUser(res.data["is_business_user"]);
      setShowLoader("none");
      setShowProfile("block");
      if (res.data["is_business_user"]) {
        getUserBusinessDetails().then((user_res) => {
          let data = user_res.data[0];
          setIsMobileVerified(data["mobile_verified"]);
          setIsHasSubscription(data["has_subscription"]);
          setBusinessId(data["id"]);
        });
      }
    });
  }, []);

  const goToTheLink = () => {
    if (isMobileVerified) {
      if (is_has_subscription) {
        return "/my-business";
      }
      return "/payment-starter";
    } else {
      return {
        pathname: "/business-register-mobile",
        state: {
          from: "/profile",
          businessId: businessId,
        },
      };
    }
  };
  return (
    <>
      <div className="body-container-footer" style={{ display: showProfile }}>
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: showLoader }}
          className={"loader"}
        />
        <div id="profile-pg-all" className="profile-page">
          <div className="profile-page-element-container">
            <div className="profile-page-element2 profile-page-element page-element profile-change-opt  withoutmargin width90">
              <div
                className="profile-element-box bottom-margin"
                style={{ marginTop: "30px" }}
              >
                <div
                  className="profile-elem-contains"
                  style={{
                    textAlign: "left",
                    borderRadius: "30px",
                    border: "1px solid #aaa",
                  }}
                >
                  <Link to="/my-profile" className="link elements">
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <span data-test="profile-column-in-profile"
                        style={{
                          fontWeight: "bold",
                          color: "#6a6a6a",
                        }}
                      >
                        Profile
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#989BA2",
                          fontSize: "16px",
                        }}
                      >
                        Email, User ID
                      </span>
                    </p>
                  </Link>
                  <Link to="/saved-offer-and-deals" className="link elements">
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <span data-test="discounts-column-in-profile" style={{ fontWeight: "bold", color: "#6a6a6a" }}>
                        Discounts
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#989BA2",
                          fontSize: "16px",
                        }}
                      >
                        Saved Offers, Deals
                      </span>
                    </p>
                  </Link>
                  <div className="elements user-language ">
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#b3b3b3" }}>
                        {languageName}
                      </span>
                      <br />
                      <span data-test="language-column-in-profile"
                        className="profile-language"
                        style={{
                          fontSize: "16px",
                        }}
                      >
                        Language
                      </span>
                    </p>
                  </div>
                  <Link to="/change-country" className="link elements">
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#6a6a6a" }}>
                        {countryName}
                      </span>
                      <br />
                      <span data-test="edit-country-section-in-profile"
                        style={{
                          color: "#989BA2",
                          fontSize: "16px",
                        }}
                      >
                        Country, State, City
                      </span>
                    </p>
                  </Link>
                  <div
                    className="link elements"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      // this.inviteRef.current.focus();
                      // this.inviteRef.current.select();
                      // this.inviteRef.current.setSelectionRange(0, 99999);
                      // document.execCommand("copy");
                      // alert(
                      //   "ZopFind URL Copied: " +
                      //   process.env.REACT_APP_BASE_URL_1
                      // );
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                      }}
                      className="link"
                      onClick={() => {
                        console.log(navigator, "navigaptor", navigator.share, "navigator.share")
                        if (navigator.share) {
                          navigator
                            .share({
                              title: "Business Link",
                              text: `Hey, I'am using zopfind to get offers and discounts on everything around me.\n Download it here:`,
                              url: `${process.env.REACT_APP_BASE_URL_1}`,
                            })
                            .then(() => {
                              console.log("Thanks for sharing!");
                            });
                        } else {
                          // window.location.href = `${process.env.REACT_APP_BASE_URL_1}`;
                          console.log("inside else")
                          alert("Share feature isn't supported by your browser.")
                        
                        }
                      }}
                    >
                      <span style={{ fontWeight: "bold", color: "#6a6a6a" }}>
                        Invite Friends
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#989BA2",
                          fontSize: "16px",
                        }}
                      >
                        Share and Invite
                      </span>
                    </p>
                  </div>
                  {/* <div className="elements"> */}
                  <Link to="/setting" className="link elements">
                    <p
                      style={{
                        margin: 0,
                      }}
                    >
                      <span data-test="settings-button-profile-section" style={{ fontWeight: "bold", color: "#6a6a6a" }}>
                        Settings
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#989BA2",
                          fontSize: "16px",
                        }}
                      >
                        Account, Help, etc.
                      </span>
                    </p>
                  </Link>
                  {/* </div> */}
                </div>

                {isBusinessUser ? (
                  <Link
                    to={goToTheLink}
                    className="link elements elem-listing zero-margin your-business-cont"
                  >
                    <div
                      id="your-biz-btn-cont"
                      className="listing-container"
                      style={{}}
                    >
                      <div id="your-biz-btn" className="listing">
                        <p style={{ fontWeight: "bold" }}>Your Business</p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to="/business-register-country"
                    className="link elements elem-listing-btn zero-margin"
                  >
                    <div
                      id="your-biz-btn-cont"
                      className="listing-container"
                      style={{}}
                    >
                      <div className="listing">
                        <p>List a Business</p>
                      </div>
                    </div>

                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Profile;
