import React, { useEffect } from "react";
import { Box, Modal, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  p: 4,
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "white",
  zIndex: 1,
};

const Sub_CategoryModel = ({ id, open, handleClose, fetchData }) => {
  // console.log(id)
  const [selectIdData, setSelectIdData] = React.useState([]);
  const [MenuDetails, setMenuDetails] = React.useState({
    category: "",
    subCategory: "",
    price: "",
  });

  const getData = async (id) => {
    // console.log("seletId from subcategory", id);
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API +
          `/api/v1/business/menusubcategories/${id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
        
      if (response.status === 200) {
        // Update the state with the response data
        setSelectIdData(response.data);

        // Set the initial values of MenuDetails with selected data
        setMenuDetails({
          category: response.data.category,
          subCategory: response.data.sub_category_name,
          price: response.data.price,
        });

        // console.log(response.data);
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };

  const handleMenuDetailsChange = (event) => {
    const { name, value } = event.target;
    console.log("this is menu details change")

    setMenuDetails((prevMenuDetails) => ({
      ...prevMenuDetails,
      [name]: value,
    }));
  };

  const handleMenuDetails = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.put(
        process.env.REACT_APP_BASE_URL_API +
          `/api/v1/business/menusubcategories/${id}/`,
        {
          sub_category_name: MenuDetails.subCategory,
          price: MenuDetails.price,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("SubCategory Updated Successfully..", {
          autoClose: 2000,
        });
        console.log("Data updated successfully");
        handleClose();
        fetchData();
      } else {
        console.log("Error updating menu items");
      }
    } catch (error) {
      console.error("PUT request error:", error);
    }
  };

  useEffect(() => {
    getData(id);
    fetchData();
  }, [id]);

  return (
    <div>
      {open && <div style={overlayStyle}></div>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="business-element page-element"
          sx={style}
          textAlign={"center"}
          width={{ xs: 350, md: 600 }}
          position={"relative"}
        >
          <Box
            position="absolute"
            top={20}
            right={30}
            fontWeight={"bold"}
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              zIndex: 1,
            }}
            fontSize={"25px"}
          >
            &times;
          </Box>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{ marginTop: '16px'}}>
            Change
          </Typography>
          <br />
          <TextField
            name="category"
            value={MenuDetails.category}
            onChange={handleMenuDetailsChange}
            autoFocus={true}
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Category"
            fullWidth
            disabled
          />
          <TextField
            name="subCategory"
            value={MenuDetails.subCategory}
            onChange={handleMenuDetailsChange}
            style={{ marginTop: "20px" }}
            autoFocus={true}
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            label="Sub Category"
            placeholder="Sub Category"
            fullWidth
          />
          <TextField
            name="price"
            value={MenuDetails.price}
            onChange={handleMenuDetailsChange}
            style={{ marginTop: "20px" }}
            autoFocus={true}
            variant="outlined"
            className="full-width-input custom-material-input"
            label="Price"
            placeholder="Price"
            fullWidth
          />

          <button
            onClick={handleMenuDetails}
            className="done-btn full-width-btn"
          >
            Change
          </button>
        </Box>
      </Modal>
    </div>
  );
};

export default Sub_CategoryModel;
