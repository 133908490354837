import "./BusinessRegistration.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserBusinessDetails } from "../../services/BusinessService";
import moment from "moment";
import "../../utils/common.css";
import axios from "axios";

function BusinessAddDetails() {
  const history = useHistory();
  const [menuItems, setMenuItems] = useState([]);
  const [businessData, setBusinessData] = useState({});
  const [businessServices, setBusinessServices] = useState([]);
  const [businessFeature, setBusinessFeature] = useState([]);
  const [businessHealthAndSafety, setBusinessHealthAndSafety] = useState([]);
  const [busienssOfferValidity, setBusienssOfferValidity] = useState(false);
  const [busienssDealValidity, setBusienssDealValidity] = useState(false);
  // Visibility
  const businessShareLinkVisibility = true;

  useEffect(() => {
    getUserBusinessDetails().then((res) => {
      let data = res.data[0];
      setBusinessData(data);
      setBusinessServices(
        res.data[0]["business_services"] ? res.data[0]["business_services"] : []
      );
      setBusinessFeature(
        res.data[0]["business_features_highlight"]
          ? res.data[0]["business_features_highlight"]
          : []
      );
      setBusinessHealthAndSafety(
        res.data[0]["business_health_and_safety"]
          ? res.data[0]["business_health_and_safety"]
          : []
      );
      const deal_and_offer = data["top_deals"];
      // console.log(data, "data", deal_and_offer, "deal and offer");
      for (let i = 0; i < 2; i++) {
        if (deal_and_offer[i]["is_deal"]) {
          const deal = deal_and_offer[i];
          if (deal["is_active"]) {
            setBusienssDealValidity(moment(deal["top_deal_expiry"]) > moment());
          } else {
            setBusienssDealValidity(false);
          }
        } else {
          const offer = deal_and_offer[i];
          setBusienssOfferValidity(offer["is_active"]);
        }
      }
    });
  }, []);

  const createComponent = (string) => {
    history.push({
      pathname: "/business/details",
      state: {
        componentString: string,
      },
    });
  };

  const fetchMenuItems = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menucategories/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the response data
        setMenuItems(response.data);
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  return (
    <div style={{ background: "white" }} className="body-container-footer">
      <div className="profile-page">
        <div style={{ border: "none" }} className="back-div">
          <Link to="/my-business" className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>

        {/* <h2>Add Details</h2> */}
        <div className="other-page-element-container">
          <div className="business-page-element">
            <div className="business-detail-group">
              <div className="group-details-btn add-business-detail">
                <div
                  className={
                    businessData["business_mobile_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("contact")}
                >
                  <p>Contact Number</p>
                </div>

                <div
                  className={
                    businessData["business_location_link_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("location")}
                >
                  <p>Add Location</p>
                </div>
                <div
                  className={
                    businessShareLinkVisibility
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("link")}
                >
                  <p>Share Link</p>
                </div>
                <div
                  className={
                    businessData["business_whatsapp_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("whatsapp")}
                >
                  <p>Add Whatsapp</p>
                </div>
                <div
                  className={
                    businessData["business_youtube_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("youtube")}
                >
                  <p>Add Youtube</p>
                </div>
                <div
                  className={
                    businessData["business_facebook_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("facebook")}
                >
                  <p>Add Facebook</p>
                </div>
                <div
                  className={
                    businessData["business_instagram_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("instagram")}
                >
                  <p>Add Instagram</p>
                </div>
                <div
                  className={
                    businessData["business_twitter_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("twitter")}
                >
                  <p>Add X (Twitter)</p>
                </div>
                <div
                  className={
                    businessData["business_linkedin_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("linkedin")}
                >
                  <p>Add Linkedin</p>
                </div>
                <div
                  className={
                    businessData["business_skype_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("skype")}
                >
                  <p>Add Skype</p>
                </div>
                <div
                  className={
                    businessData["business_website_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("website")}
                >
                  <p>Add Website</p>
                </div>
                {/* </div> */}
                {/* <div className="group-details-btn"> */}
                <div
                  className={
                    busienssOfferValidity ? "active-btn-opt" : "default-btn-opt"
                  }
                  onClick={() => createComponent("offer")}
                >
                  <p>Add Offer</p>
                </div>
                <div
                  className={
                    busienssDealValidity ? "active-btn-opt" : "default-btn-opt"
                  }
                  onClick={() => createComponent("today_deal")}
                >
                  <p>Add Today's Deal</p>
                </div>
                <div
                  className={
                    businessData["business_book_appointment_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("book_appointment")}
                >
                  <p>Add Book Appointment/ Enquire</p>
                </div>
                <div
                  className={
                    businessData["business_virtual_appointment_visibility"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("virtual")}
                >
                  <p>Add Virtual Consultation</p>
                </div>
                <div
                  className={
                    businessServices.length > 0
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("services")}
                >
                  <p>Add Services</p>
                </div>
                <div
                  className={
                    businessFeature.length > 0
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("features")}
                >
                  <p>Add Features and Higlights</p>
                </div>
                <div
                  className={
                    businessHealthAndSafety.length > 0
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("health")}
                >
                  <p>Add Health and Safety</p>
                </div>
                <div
                  className={
                    businessData["business_home"]
                      ? "active-btn-opt"
                      : "default-btn-opt"
                  }
                  onClick={() => createComponent("home")}
                >
                  <p>Add Home Service/Delivery</p>
                </div>
                <div onClick={() => createComponent("available")}>
                  <p>Add Available Hours</p>
                </div>
                {/* </div> */}
                {/* <div className="group-details-btn"> */}
                <div
                  className={
                    menuItems.length === 0
                      ? "default-btn-opt"
                      : "active-btn-opt"
                  }
                  onClick={() => createComponent("AddMenuDetails")}
                >
                  <p>Add Menu/Details</p>
                </div>
                <div
                  className={
                    !businessData["business_address_line1"] &&
                    !businessData["business_address_line2"] &&
                    !businessData["business_address_line3"]
                      ? "default-btn-opt"
                      : "active-btn-opt"
                  }
                  // style={{ width: "100%" }}
                  onClick={() => createComponent("address")}
                >
                  <p>Add Address</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessAddDetails;
