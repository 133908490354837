import axios from "axios";

export const getCategoies = async () => {
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API +
        "/api/v1/business/categories/?categories=true",
      config
    );
    return res;
  } catch (err) {
    console.log(err);
    throw new Error("Unable to Send Verification Code");
  }
};

export const getCategory = async (params) => {
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: params,
    };

    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/business/category/",
      config
    );
    return res;
  } catch (err) {
    console.log(err);
    throw new Error("Unable to Send Verification Code");
  }
};

export const getSubCategoies = async (parent_name,parent_category_name) => {
  try {
    let config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        category_name: parent_name,
        parent_category_name:parent_category_name
      },
    };

    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/business/categories",
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const searchCategoies = async (search) => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        name: search,
      },
    };

    const res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/business/categories/",
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const loadCategoriesOptions = async (search) => {
  return await searchCategoies(search).then(({ data }) => {
    let options = data.map((category_obj) => ({
      value: category_obj.name_with_id.split("##")[1],
      label: category_obj.name_with_id.split("##")[0],
    }));
    return options;
  });
};

export const searchOptions = async (search) => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };

    const res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/get_options",
      {
        params: {
          search: search,
        },
      },
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};

export const loadSearchOptions = async (search) => {
  return await searchOptions(search).then(({ data }) => {
    let options = data.map((opt) => ({
      value: `${opt.name}##${opt["is_category"]}`,
      label: `${opt.name}`,
      is_category: `${opt["is_category"]}`,
      id: opt["id"],
    }));
    return options;
  });
};
export const getCityCategoriesOptions = async (search, city_id) => {
  try {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
      params: {
        city_id: city_id,
        active: true,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL_API}/api/v1/business/get_city_categories/`,
      config
    );
    return res;
  } catch (err) {
    throw new Error("Unable to Send Verification Code");
  }
};
export const loadCityCategoriesOptions = async (search, city_id) => {
  return await getCityCategoriesOptions(search, city_id).then(({ data }) => {
    let options = data.map((category_obj) => ({
      value: category_obj.id,
      label: category_obj.category_name,
    }));
    return options;
  });
};
