import "./Profile.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import "../../utils/common.css"

function ReportProblemPage() {
  return (
    <div className="body-container-footer" >
      <div className="profile-page">
        <div className="back-div">
          <Link to="/setting-help" className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>


        <div className="other-page-element-container">
          <div className="other-page-element light-brown withoutmargin">
            <h2
              className={"title-color title"}
              id="setting-heading"
              style={{ marginBottom: "0px" }}
            >
              Report a Problem
            </h2>

            <div className="other-element-box">
              <div className="help">
                <Link
                  to={{
                    pathname: "/submit-problem",
                    state: { subject: "Listed my Business" },
                  }}
                  className="link margin-30"
                >
                  <p className="remove-top-margin">Listed my Business</p>
                </Link>
                <Link
                  to={{
                    pathname: "/submit-problem",
                    state: { subject: "I am Customer not Listed User" },
                  }}
                  className="link margin-30"
                >
                  <p className="remove-top-margin" >I am Customer not Listed User</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportProblemPage;
