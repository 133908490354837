import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import _ from "lodash";
import { toast } from "react-toastify";


function Contact(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Contact
      </h2>
      {!_.includes(
        ["Please fill the telephone number input", "Invalid telephone number!"],
        alertMessage
      ) ? (
        <>
          <Alert value={alertMessage} show={showAlert} />
        </>
      ) : null}
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessMobileNumberVisibility(
                !props.businessMobileNumberVisibility
              )
            }
            checked={props.businessMobileNumberVisibility}
          />
          <br />
          {/* <h3 className="mobile-number">Mobile Number</h3> */}
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Mobile Number"
            variant="outlined"
            id="mobileNum"
            className="full-width-input custom-material-input"
            type="text"
            inputProps={{ maxLength: 10, minLength: 10 }}
            placeholder="Add Mobile Number"
            onChange={(e) => props.setBusinessMobileNumber(e.target.value)}
            value={props.businessMobileNumber}
            disabled={!props.businessMobileNumberVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (props.businessMobileNumberVisibility === false && JSON.parse(localStorage.getItem("business_mobile_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessMobileNumber === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_mobile_number: props.businessMobileNumber,
                  business_mobile_visibility:
                    props.businessMobileNumberVisibility,
                });
                localStorage.setItem("business_mobile_visibility",  props.businessMobileNumberVisibility)

              } else {
                if (
                  props.businessMobileNumber === "" ||
                  props.businessMobileNumber === null
                ) {
                  setAlertMessgae("Please fill the input");
                  setShowAlert("block");
                } else if (
                  props.businessMobileNumber.length < 10 ||
                  props.businessMobileNumber.length > 10 ||
                  props.businessMobileNumber.match(/^\d{10}$/) == null
                ) {
                  setAlertMessgae("Invalid mobile number!");
                  setShowAlert("block");
                } else {
                  setShowAlert("none");
                  await props.updateDetails({
                    business_mobile_number: props.businessMobileNumber,
                    business_mobile_visibility:
                      props.businessMobileNumberVisibility,
                  });
                localStorage.setItem("business_mobile_visibility",  props.businessMobileNumberVisibility)

                }
              }
            }}
          >
            Save Mobile Number
          </button>
          <br />
          {_.includes(
            [
              "Please fill the telephone number input",
              "Invalid telephone number!",
            ],
            alertMessage
          ) ? (
            <>
              <Alert value={alertMessage} show={showAlert} />
              <br />
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Contact;