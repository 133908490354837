import "./Profile.css";
import "../../utils/common.css";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";

function AboutPage() {
  return (
    <>
      <div className="body-container-footer">
        <div className="profile-page">
          <div style={{ border: "none" }} className="back-div">
            <Link to="/setting" className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>

          <div className="other-page-element-container">
            <div className="other-page-element light-brown without-left-margin withoutmargin">
              <h2 className={"title-color title"} id="setting-heading">
                About
              </h2>
              <div className="other-element-box">
                <div className="about">
                  <Link
                    to="/copyright"
                    className="link increase-space margin-30"
                  >
                    <p className="remove-top-margin">Copyright</p>
                  </Link>
                  <Link
                    to="/setting-terms-of-service"
                    className="link increase-space margin-30"
                  >
                    <p>Terms of Services</p>
                  </Link>
                  <Link
                    to="/setting-privacy-policy"
                    className="link increase-space margin-30"
                  >
                    <p>Privacy Policy</p>
                  </Link>
                  {/* <Link to="/about-opensource" className="link increase-space">
            <p>Open Source Libraries</p>
          </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutPage;
