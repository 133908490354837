// import "./TermAndService.css";
import { Link, useHistory } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import TermAndServiceComman from "../CommonComponets/TermsAndServiceComman";

function TermAndService(props) {
  const history = useHistory();
  const goBackLink = () => {
    history.goBack();
  };
  return (
    <div className="c6 termAndService">
      <div style={{ border: "none" }} className="back-div">
        <Link onClick={goBackLink} className="link" to="">
          <img
            src={backArrowIcon}
            alt={"back-icon"}
            className="back-icon"
            height="20px"
          />
        </Link>
      </div>
      <TermAndServiceComman />
    </div>
  );
}
export default TermAndService;
