import "../Offer/offer.css";

function BlankCard(props) {
  return (
    <div className="business-offer">
      <div
        style={{ backgroundColor: props.cardColor, minHeight: "200px" , borderRadius:"30px"}}
        className="offer-section"
      >
        <p
        id="comg-soon-p"
          style={{
            fontSize: "32px",
            fontWeight: 600,
            marginTop: "75px",
            marginBottom: "20px",
          }}
        >
          Coming Soon....
        </p>
      </div>
    </div>
  );
}

export default BlankCard;
