import "../InitialProcess/Screens.css";
import { Link, useHistory } from "react-router-dom";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";

function WhyWebAppComman(props) {
  const history = useHistory();
  return (
    <div className={"initial-screen-page"}>
      <div className={"intial-screen-container"}>
        <div className="back-btn">
          <Link
            to={{
              pathname:  props.back ,
              state: { from: history.location.pathname },
            }}
            className="link"
          >
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon back-icon-hti"
              height="20px"
            />
          </Link>
        </div>
        <div className="top-space" />
        <h2
          style={{ margin: "10px 0 0px", fontSize: "33px", fontWeight: "bold" }}
        >
          Why Progressive Web App (PWA)?
        </h2>
        <div className={"content advantages"}>
          <p style={{ fontSize: "20px" }}>* Fast Installation</p>
          <p style={{ fontSize: "20px" }}>* Works in poor network</p>
          <p style={{ fontSize: "20px" }}>* No need to update every time</p>
          <p style={{ fontSize: "20px" }}>* Better performance</p>
          <p style={{ fontSize: "20px" }}>* Less Internet data usage</p>
          <p style={{ fontSize: "20px" }}>* Mobile will have more life</p>
          <p style={{ fontSize: "20px" }}>* Less mobile storage required</p>
          <p style={{ fontSize: "20px" }}>* Google says PWA is FIRE</p>
          <p
            style={{
              fontSize: "20px",
              marginTop: "-20px",
              paddingLeft: "14px",
            }}
          >
            Fast, Integrated, Reliable, Engaging
          </p>
        </div>
      </div>
    </div>
  );
}

export default WhyWebAppComman;
