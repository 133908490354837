import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Location(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );

  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Location
      </h2>
      <div style={{ fontSize: "18px", color: "#6a6a6a" }}>
        (Google Map Link Only)
      </div>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessLocationLinkVisibility(
                !props.businessLocationLinkVisibility
              )
            }
            checked={props.businessLocationLinkVisibility}
          />
          <br />
          <br />

          <TextField
            inputRef={(input) => input && input.focus()}
            label="Add Location"
            variant="outlined"
            id="location"
            inputProps={{ maxLength: 600, minLength: 3 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Add Location"
            onChange={(e) => props.setBusinessLocationLink(e.target.value)}
            value={props.businessLocationLink}
            autoFocus={true}
            disabled={!props.businessLocationLinkVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              console.log(props.businessLocationLinkVisibility, JSON.parse(localStorage.getItem("business_location_link_visibility")))
              if (props.businessLocationLinkVisibility === false && JSON.parse(localStorage.getItem("business_location_link_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessLocationLinkVisibility === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_location_link: props.businessLocationLink,
                  business_location_link_visibility:
                    props.businessLocationLinkVisibility,
                });
                localStorage.setItem("business_location_link_visibility",  props.businessLocationLinkVisibility)

              } else {
                if (
                  props.businessLocationLinkVisibility === true &&
                  (props.businessLocationLink === "" ||
                    props.businessLocationLink === null)
                ) {
                  setAlertMessgae("Please fill the input");
                  setShowAlert("block");
                } else if (
                  props.businessLocationLink.length < 3 ||
                  props.businessLocationLink.length > 600 ||
                  props.businessLocationLink.match(
                    /(^http(s)):\/\/((www|maps)\.|)?((google|goo)\.[a-z]+\/maps|(google|app\.goo.gl).*)/g
                  ) == null
                ) {
                  setAlertMessgae(
                    "Invalid input! accept only min 3 and max 600 char"
                  );
                  setShowAlert("block");
                } else {
                  setShowAlert("none");
                  await props.updateDetails({
                    business_location_link: props.businessLocationLink,
                    business_location_link_visibility:
                      props.businessLocationLinkVisibility,
                  });
                  localStorage.setItem("business_location_link_visibility",  props.businessLocationLinkVisibility)
                }
              }
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default Location;