import axios from "axios";

export const getSignature = (data) => {
	const config = {
		headers: {
			Authorization: `Token ${window.localStorage.getItem("token")}`,
		},
	};

	const res = axios.post(
		process.env.REACT_APP_BASE_URL_API +
			"/api/v1/business/provide_signature",
		data,
		config
	);
	return res;
};

export const getPlans = () => {
	try {
		const config = {
			headers: {
				Authorization: `Token ${window.localStorage.getItem("token")}`,
			},
		};
		let res = axios.get(
			process.env.REACT_APP_BASE_URL_API + "/api/v1/order/plan/",
			config
		);
		return res;
	} catch (err) {
		throw new Error("Unable to Send Verification Code");
	}
};

export const initializePayment = (planId, modeValue) => {
	const hostname = window.location.hostname;
	let return_url;
	if (hostname === "zopfind.com") {
		return_url =
			process.env.REACT_APP_BASE_URL_1 + "/payment_status/{order_id}";
	} else if (hostname === "www.zopfind.com") {
		return_url =
			process.env.REACT_APP_BASE_URL_2 + "/payment_status/{order_id}";
	} else if (hostname === "staging.zopfind.com") {
		return_url =
			process.env.REACT_APP_BASE_URL_3 + "/payment_status/{order_id}";
	}
	try {
		const data = {
			returnUrl: return_url,
			notifyUrl:
				process.env.REACT_APP_BASE_URL_API +
				"/notify_status/{order_id}",
			plan: planId,
			mode: modeValue,
		};
		const config = {
			headers: {
				Authorization: `Token ${window.localStorage.getItem("token")}`,
			},
		};
		let res = axios.post(
			process.env.REACT_APP_BASE_URL_API +
				"/api/v1/order/provide_signature/",
			data,
			config
		);
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const getPaymentStatus = async (orderId) => {
	try {
		const config = {
			headers: {
				Authorization: `Token ${window.localStorage.getItem("token")}`,
			},
			params: {
				order_id: orderId,
			},
		};
		let res = await axios.get(
			process.env.REACT_APP_BASE_URL_API +
				"/api/v1/order/get_payment_status/",
			config
		);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
};
