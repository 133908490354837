import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import { toast } from "react-toastify";


function Youtube(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Youtube
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessYoutubeVisibility(
                !props.businessYoutubeVisibility
              )
            }
            checked={props.businessYoutubeVisibility}
          />
          <br />
          <br />
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Youtube Link"
            variant="outlined"
            id="youtube"
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Youtube Link"
            inputProps={{ maxLength: 150, minLength: 3 }}
            onChange={(e) => props.setBusinessYoutube(e.target.value)}
            value={props.businessYoutube}
            disabled={!props.businessYoutubeVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (props.businessYoutubeVisibility === false && JSON.parse(localStorage.getItem("business_youtube_visibility")) === false){
                toast.warning("Button Is Disabled");
                return 
              }
              if (props.businessYoutubeVisibility === false) {
                setShowAlert("none");
                await props.updateDetails({
                  business_youtube: props.businessYoutube,
                  business_youtube_visibility: props.businessYoutubeVisibility,
                });
                localStorage.setItem("business_youtube_visibility",  props.businessYoutubeVisibility)

              } else if (
                props.businessYoutubeVisibility === true &&
                (props.businessYoutube === "" || props.businessYoutube === null)
              ) {
                setAlertMessgae("Please fill the field!");
                setShowAlert("block");
              } else if (
                props.businessYoutubeVisibility === true &&
                (props.businessYoutube.length < 3 ||
                  props.businessYoutube.length > 150 ||
                  props.businessYoutube.match(
                    /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/g
                  ) == null)
              ) {
                setAlertMessgae(
                  "Invalid youtube link! accept only min 3 and max 150 char"
                );
                setShowAlert("block");
              } else {
                setShowAlert("none");
                await props.updateDetails({
                  business_youtube: props.businessYoutube,
                  business_youtube_visibility: props.businessYoutubeVisibility,
                });
                localStorage.setItem("business_youtube_visibility",  props.businessYoutubeVisibility)

              }
            }}
          >
            Done
          </button>
        </div>
      </div>
    </>
  );
}

export default Youtube;