import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ZopfindLogo from "../../assets/logo/logo.svg";

function PaymentSucessFull() {
  const history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => {
      history.push({
        pathname: "/my-business",
      });
    }, 6000);
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <div className="profile-container" style={{ display: true }}>
      <div className="header">
        <h1 className="company-name">
          <img alt="Zopfind" src={ZopfindLogo} />
        </h1>
      </div>
      <div className="profile-element">
        <p>
          Congratulations, Your Payment is Recieved. Now You can Create Your
          Business Profile and Listing.
        </p>
        <p>
          <Link to="/my-business">Go to Listing Page</Link>
        </p>
      </div>
    </div>
  );
}
export default PaymentSucessFull;
