import './Header.css';
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import { AiFillProfile, AiOutlineProfile } from 'react-icons/ai';
import { AiFillAppstore, AiOutlineAppstore } from 'react-icons/ai';
import {
    Link, useLocation
} from "react-router-dom";


function Header() {
    const location = useLocation();
    return (
        <div className="header-container">
            <div className="header-company">
                <h2 style={{ color: '#1a73e8', width: '100%', height: '100%' }} >Zopfind</h2>
            </div>
            <div className="header-icons">
                <Link to="/home" className="header-icon-container link">
                    {location.pathname === '/home' ? (<AiFillHome className="header-icon" />) : (<AiOutlineHome className="header-icon" />)}
                </Link>
                <Link to="/categories" className="header-icon-container link">
                    {location.pathname === '/categories' ? (<AiFillAppstore className="header-icon" />) : (<AiOutlineAppstore className="header-icon" />)}
                </Link>
                <Link to="/profile" className="header-icon-container link">
                    {location.pathname === '/profile' ? (<AiFillProfile className="header-icon" />) : (<AiOutlineProfile className="header-icon" />)}
                </Link>
            </div>
        </div>
    );
}

export default Header;