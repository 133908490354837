import Alert from "../../Alert/Alert";
import { TiDeleteOutline } from "react-icons/ti";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";

function Services(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  const [serviceEditModalDisplay, setServiceEditModalDisplay] = useState(false);
  const handleClose = () => {
    setServiceEditModalDisplay(false);
  };
  const [modalServiceText, setModalServiceText] = useState("");
  return (
    <>
      <Modal
        open={serviceEditModalDisplay}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        onClose={handleClose}
      >
        <div className={"modal2-container-box"}>
          <div className="close">
            <div onClick={handleClose}>&times;</div>
          </div>
          <div>
            <p>Edit Service</p>
          </div>
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Edit Service"
            variant="outlined"
            id="edit-service"
            inputProps={{ maxLength: 100, minLength: 1 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Edit Services here"
            onChange={(e) => setModalServiceText(e.target.value)}
            value={modalServiceText}
          />
          <button
            className="done-btn btn-blue full-width-btn"
            onClick={(e) => {
              setServiceEditModalDisplay(!serviceEditModalDisplay);
            }}
          >
            Change
          </button>
        </div>
      </Modal>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Services
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Add Service"
            variant="outlined"
            id="service"
            inputProps={{ maxLength: 100, minLength: 1 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Add Services here"
            onChange={(e) => props.setService(e.target.value)}
            value={props.service}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (
                props.service == null ||
                _.isEmpty(props.service) ||
                (props.service != null && _.isEmpty(props.service.trim()))
              ) {
                return;
              }
              if (
                props.businessServices !== null &&
                props.businessServices !== []
              ) {
                if (
                  _.filter(
                    props.businessServices,
                    (name) => name.toLowerCase() === props.service.toLowerCase()
                  ).length > 0
                ) {
                  setAlertMessgae("Service Already Exist!");
                  setShowAlert(true);
                  return;
                }
                setAlertMessgae("Please Provide All The Details");
                setShowAlert("none");

                props.setBusinessServices([
                  ...props.businessServices,
                  props.service,
                ]);
                await props.updateDetails({
                  business_services: [...props.businessServices, props.service],
                });
              } else {
                props.setBusinessServices((arr) => [props.service]);
                await props.updateDetails({
                  business_services: [props.service],
                });
              }
            }}
          >
            Add
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "10px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.businessServices !== null &&
            props.businessServices !== [] ? (
              props.businessServices.map((aservice, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    backgroundColor: "#FAFAFA",
                    margin: "10px",
                    padding: "10px",
                    boxShadow: "0 1px 5px -2px rgba(0, 0, 0, 0.8)",
                  }}
                  className="service-tags"
                >
                  <div
                    key={index + "serv"}
                    onClick={() => {
                      setModalServiceText(aservice);
                      setServiceEditModalDisplay(true);
                    }}
                  >
                    {aservice}
                  </div>
                  <TiDeleteOutline
                    style={{
                      fontSize: "25px",
                      fill: "red",
                    }}
                    onClick={async (e) => {
                      props.setDeleteElement({
                        elem_type: "service",
                        elem: aservice,
                      });
                      props.setOpenDailog(true);
                    }}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
