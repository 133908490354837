import axios from "axios";

export const fbLogin = async (accesstoken) => {
	let res = await axios.post(
		process.env.REACT_APP_BASE_URL_API + "/api/v1/accounts/facebook/auth/",
		{
			access_token: accesstoken,
		}
	);
	await window.localStorage.setItem("token", res.data.key);
	res = await axios.get(
		process.env.REACT_APP_BASE_URL_API +
			"/api/v1/accounts/check_user_profile/",
		{
			headers: {
				Authorization: `Token ${res.data.key}`,
			},
		}
	);
	return await res.data.is_profile_completed;
};

export const fetchCountries = async () => {
	let res = await axios.get(
		process.env.REACT_APP_BASE_URL_API + "/api/v1/country/all_countries"
	);
	return res;
};

export const fetchLanguages = async () => {
	let res = await axios.get(
		process.env.REACT_APP_BASE_URL_API + "/api/v1/language/all_languages"
	);
	return res;
};

export const googleLogin = async (code, hostname) => {
	let res;
	if (hostname === "zopfind.com") {
		res = await axios.post(
			process.env.REACT_APP_BASE_URL_API +
				"/api/v1/accounts/google/auth1/",
			{
				code: code,
			}
		);
	} else if (hostname === "www.zopfind.com") {
		res = await axios.post(
			process.env.REACT_APP_BASE_URL_API +
				"/api/v1/accounts/google/auth2/",
			{
				code: code,
			}
		);
	} else if (hostname === "staging.zopfind.com") {
		res = await axios.post(
			process.env.REACT_APP_BASE_URL_API +
				"/api/v1/accounts/google/auth3/",
			{
				code: code,
			}
		);
	}
	await window.localStorage.setItem("token", res.data.key);
	const config = {
		headers: {
			Authorization: `Token ${window.localStorage.getItem("token")}`,
		},
	};
	let resp = await axios.get(
		process.env.REACT_APP_BASE_URL_API + "/api/v1/accounts/user/",
		config
	);
	resp.data = resp.data[0];
	return resp;
};
