import axios from "axios";

const checkTokenPresent = () => {
  console.log(window.localStorage.getItem("token"));
  if (window.localStorage.getItem("token") !== "") {
    return true;
  } else {
    return false;
  }
};

export const fetchCustomerProfile = async () => {
  if (checkTokenPresent()) {
    const config = {
      headers: {
        Authorization: `Token ${window.localStorage.getItem("token")}`,
      },
    };
    let res = await axios.get(
      process.env.REACT_APP_BASE_URL_API + "/api/v1/customer/customer_profile/",
      config
    );
    return res;
  } else {
    window.location.href = "/";
  }
};

export const getDealsAndOffer = async () => {
  let config = {
    headers: {
      Authorization: `Token ${window.localStorage.getItem("token")}`,
    },
  };
  const res = await axios.get(
    process.env.REACT_APP_BASE_URL_API +
      "/api/v1/business/get_deals_in_your_area/",
    config
  );
  return res;
};

export const saveDealsAndOffers = async (id) => {
  let config = {
    params: {
      deal_id: id,
    },
    headers: {
      Authorization: `Token ${window.localStorage.getItem("token")}`,
    },
  };
  const res = await axios.get(
    process.env.REACT_APP_BASE_URL_API + "/api/v1/business/save_deals_offers/",
    config
  );
  return res;
};
