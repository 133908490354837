import "./BusinessPage.css";
import { useHistory, useParams, Link, useNavigate } from "react-router-dom";
import { businessDetailsById } from "../../services/BusinessService";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Loader from "react-loader-spinner";
import moment from "moment";
import _ from "lodash";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import YoutubeRoundIcon from "../../assets/social_icons/Youtube Round Icon.svg";
import WhatsappRoundIcon from "../../assets/social_icons/Whatsapp Round Icon.svg";
import TwitterRoundIcon from "../../assets/social_icons/Twitter Round Icon.svg";
import LinkedinRoundIcon from "../../assets/social_icons/LinkedIn Round Icon.svg";
import InstagramRoundIcon from "../../assets/social_icons/Instagram Round Icon.svg";
import FacebookRoundIcon from "../../assets/social_icons/Facebook Round Icon.svg";
import SkypeRoundIcon from "../../assets/social_icons/Skype Round Icon.svg";

import YoutubeGrayRoundIcon from "../../assets/social_icons_gray/Youtube Round Icon.svg";
import WhatsappGrayRoundIcon from "../../assets/social_icons_gray/Whatsapp Round Icon.svg";
import TwitterGrayRoundIcon from "../../assets/social_icons_gray/Twitter Round Icon.svg";
import LinkedinGrayRoundIcon from "../../assets/social_icons_gray/LinkedIn Round Icon.svg";
import InstagramGrayRoundIcon from "../../assets/social_icons_gray/Instagram Round Icon.svg";
import FacebookGrayRoundIcon from "../../assets/social_icons_gray/Facebook Round Icon.svg";
import SkypeGrayRoundIcon from "../../assets/social_icons_gray/Skype Round Icon.svg";
import { IoLocationSharp, IoCheckmarkCircleOutline } from "react-icons/io5";
import {
  IoMdCall,
  IoMdCheckmark,
  IoMdStar,
  IoMdMedical,
  IoMdOpen,
  IoMdShareAlt,
  IoIosList,
} from "react-icons/io";

import BestDeal from "../BestDeal/BestDeal";
import Offer from "../Offer/Offer";
import {
  checkAvailability,
  getWeekDay,
  isAvailable,
  categoriesDisplay,
} from "../../utils/common";
import { breakWords } from "./breakWords";
import { saveDealsAndOffers } from "../../services/HomeService";
import "../../utils/common.css";
import axios from "axios";

function BusinessPage() {
  const businessId = useParams()["id"];
  const history = useHistory();
  const servicesRef = useRef();
  const [menuItems, setMenuItems] = useState([]);
  const [isBusinessActive, setIsBusinessActive] = useState(false);
  const [bookedDateTimeModalDisplay, setBookedDateTimeModalDisplay] =
    useState(false);
  const [bookedDateTime, setBookedDateTime] = useState(new Date());
  const [showLoader, setShowLoader] = useState("none");
  const [showListing, setShowListing] = useState("none");
  const [businessData, setBusinessData] = useState([]);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [businessAvailableHours, setBusinessAvailableHours] = useState({});
  const [businessTopDealName, setBusinessTopDealName] = useState(null);
  const [businessTopDealActualPrice, setBusinessTopDealActualPrice] =
    useState(null);
  const [businessTopDealPrice, setBusinessTopDealPrice] = useState(null);
  const [businessDealExpiry, setBusinessDealExpiry] = useState(null);
  const [businessDealId, setBusinessDealId] = useState(null);
  const [businessOfferVisibility, setBusinessOfferVisibility] = useState(false);
  const [businessOffer, setBusinessOffer] = useState("");
  const [businessOfferId, setBusinessOfferId] = useState(null);
  const [offerCardColor, setOfferCardColor] = useState("#ff3366");
  const [dealCardColor, setDealCardColor] = useState("#ff3366");
  const [businessServices, setBusinessServices] = useState([]);
  const [businessFeature, setBusinessFeature] = useState([]);
  const [businessHealthAndSafety, setBusinessHealthAndSafety] = useState([]);
  const [whatsappMessage, setWhatsappMessage] = useState(
    "Hi,\r\nI found you on zopfind\r\nI would like to Book Appointment on"
  );
  const [loader, setLoader] = useState(false);
  const dataRef = useRef(loader);
  dataRef.current = loader;

  useEffect(() => {
    setTimeout(() => {
      if (!dataRef.current) setShowLoader("block");
    }, 3000);
  }, []);
  useEffect(() => {
    businessDetailsById(businessId).then((res) => {
      if (res.data.length === 0) {
        setShowLoader("none");
        setShowListing("block");
        setIsBusinessActive(false);
        setLoader(true);
      } else {
        const data = res.data[0];
        setIsBusinessActive(true);
        setBusinessData(res.data[0]);

        setBusinessCategories(data["categories_set"]);
        setBusinessAvailableHours(data["business_timeing"]);
        setBusinessServices(data["business_services"]);
        setBusinessFeature(data["business_features_highlight"]);
        setBusinessHealthAndSafety(data["business_health_and_safety"]);
        if (
          data["business_address_line1"] != null &&
          !_.isEmpty(data["business_address_line1"]) &&
          data["business_address_line2"] != null &&
          !_.isEmpty(data["business_address_line2"]) &&
          data["business_address_line3"] != null &&
          !_.isEmpty(data["business_address_line3"])
        ) {
          const city_state =
            data["business_city"] ||
            data["business_state"] ||
            data["business_pincode"]
              ? `${data["business_address_line1"]}, ${
                  data["business_address_line2"]
                }, ${data["business_address_line3"]}, ${_.join(
                  _.compact([
                    data["business_city"],
                    data["business_state"],
                    data["business_zipcode"],
                  ]),
                  ", "
                )}`
              : `${data["business_address_line1"]}, ${data["business_address_line2"]}, ${data["business_address_line3"]}`;

          setBusinessAddress(city_state);
        }
        for (let i = 0; i < data["top_deals"].length; i++) {
          let deal = data["top_deals"][i];
          if (deal["is_deal"]) {
            setBusinessTopDealActualPrice(deal["top_deal_actual_price"]);
            setBusinessTopDealName(deal["top_deal_name"]);
            setBusinessTopDealPrice(deal["top_deal_price"]);
            setBusinessDealExpiry(deal["top_deal_expiry"]);
            setDealCardColor(deal["color_code"]);
            setBusinessDealId(deal["id"]);
          } else {
            setBusinessOfferVisibility(deal["is_active"]);
            setBusinessOffer(deal["business_offer"]);
            setBusinessOfferId(deal["id"]);
            setOfferCardColor(deal["color_code"]);
          }
        }
        setShowLoader("none");
        setShowListing("block");
        setLoader(true);
        if (history.location.state) {
          if (history.location.state.list) {
            ScrollToOurService();
          }
        }
      }
    });
  }, [businessId, history.location.state]);
  const ScrollToOurService = () => {
    if (servicesRef && servicesRef.current) {
      servicesRef.current.scrollIntoView({
        behavior: "smooth",
        top: "100px",
        block: "start",
      });
    }
  };
  const AddToWishList = (dealId) => {
    saveDealsAndOffers(dealId).then((res) => {
      if (res && res.data && res.data.msg) {
        toast.info(res.data.msg);
      }
    });
  };
  const goBackLink = () => {
    console.log("go back");
    history.goBack();
  };
  const handleClose = () => {
    setBookedDateTimeModalDisplay(false);
  };

  const fetchMenuItems = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menucategories/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the response data
        setMenuItems(response.data);
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);
  return (
    <>
      <div
        className="body-container-footer"
        style={{ backgroundColor: "white" }}
      >
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{ display: showLoader }}
          className="loader"
        />
        <div
          className="profile-page list-business-page own-listing"
          style={{ display: showListing }}
        >
          <div
            style={{ border: "none" }}
            id="business-pg-back-div"
            className="back-div"
          >
            <a
              style={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            >
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </a>
          </div>
          {isBusinessActive ? (
            <div className="listing-main-container">
              <Modal
                open={bookedDateTimeModalDisplay}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                onClose={handleClose}
              >
                <div className={"modal2-container-box"}>
                  <div className="close">
                    <div onClick={handleClose}>&times;</div>
                  </div>
                  <div>
                    <p>Please select your Date-Time</p>
                  </div>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      label="Select Date-Time"
                      inputVariant="outlined"
                      value={bookedDateTime}
                      id="date-time-picker"
                      onChange={(newValue) => {
                        setBookedDateTime(newValue);
                      }}
                      mindate={Date.now()}
                      mintime={Date.now()}
                      sx={{
                        label: {
                          position: "absolute",
                          top: "-20px",
                        },
                      }}
                      renderinput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Date-Time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                  <button
                    className="done-btn btn-blue full-width-btn"
                    onClick={(e) => {
                      setBookedDateTimeModalDisplay(
                        !bookedDateTimeModalDisplay
                      );
                      const timeFormat = {
                        hour12: true,
                        hour: "2-digit",
                        minute: "2-digit",
                      };
                      const msg = window.encodeURIComponent(
                        `${
                          whatsappMessage ? whatsappMessage : "hi"
                        }\r\n\r\nDate: ${bookedDateTime.toLocaleDateString()}\r\nTime: ${bookedDateTime.toLocaleTimeString(
                          Object(),
                          timeFormat
                        )}`
                      );

                      if (
                        businessData["business_whatsapp"] &&
                        businessData["business_whatsapp_visibility"]
                      ) {
                        window.location.href =
                          businessData["business_whatsapp"] &&
                          businessData["business_whatsapp"].indexOf("+91") > -1
                            ? `https://wa.me/${businessData["business_whatsapp"]}/?text=${msg}`
                            : `https://wa.me/+91${businessData["business_whatsapp"]}/?text=${msg}`;
                      }
                    }}
                  >
                    Okay
                  </button>
                </div>
              </Modal>
              <div className="listing-sub-container page-element">
                <div className="listing-main-details">
                  <div
                    className="listing-header-container page-block"
                    style={{ marginTop: "0px" }}
                  >
                    <h3>{businessData["business_name"]}</h3>
                    <div
                      style={{
                        fontSize: "18px",
                        margin: "0 0 10px 0",
                        padding: "0px",
                      }}
                    >
                      <div
                        style={{
                          whiteSpace: "break-spaces",
                          textAlign: "left",
                        }}
                      >
                        {categoriesDisplay(businessCategories)}
                        {/* categoriesDisplay(props.businessCategory,props.requestFromBusinessPage) */}
                      </div>
                    </div>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "0 0 10px 0",
                        padding: "0px",
                        textAlign: "left",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {businessData["business_city"]},{" "}
                      {businessData["business_area"]}
                    </p>
                    <p className={"time-availability"}>
                      {checkAvailability(businessAvailableHours)}
                    </p>
                  </div>
                  <div
                    id="biz-pg-social"
                    style={{}}
                    className="social-icons-container page-block"
                  >
                    <div className="group-social blue-bg">
                      <div>
                        <IoMdCall
                          className="social-icon-2 social-icon-big"
                          fill="#fff"
                          onClick={() =>
                            (window.location.href = `tel:+91${businessData["business_mobile_number"]}`)
                          }
                        />
                        <p>Call</p>
                      </div>
                      <div>
                        {businessData["business_location_link_visibility"] ? (
                          <IoLocationSharp
                            className="social-icon-2 social-icon-big"
                            fill="#fff"
                            onClick={() =>
                              window.open(
                                businessData["business_location_link"],
                                "_blank"
                              )
                            }
                          />
                        ) : (
                          <IoLocationSharp className="social-icon-disabled social-icon-big" />
                        )}
                        <p>Location</p>
                      </div>
                      <div>
                        <IoMdShareAlt
                          className="social-icon-2 social-icon-big"
                          fill="#fff"
                          onClick={async () => {
                            try {
                              await navigator
                                .share({
                                  title: "Business Link",
                                  text: `Find ${
                                    businessData["business_name"]
                                  }, ${businessData["categories_set"].join(
                                    ", "
                                  )} on zopfind.`,
                                  url: `${process.env.REACT_APP_BASE_URL_1}/business-page/${businessData["id"]}`,
                                })
                                .then(() => {
                                  console.log("Thanks for sharing!");
                                });
                            } catch (err) {
                              console.log("error", err);
                            }
                          }}
                          height="20"
                          width="20"
                        />

                        <p>Share</p>
                      </div>
                      <div>
                        <IoIosList
                          className="social-icon-2 social-icon-big"
                          fill="#fff"
                          onClick={() => {
                            ScrollToOurService();
                          }}
                        />
                        <p>List</p>
                      </div>
                    </div>
                    <div className="group-social">
                      <div>
                        {businessData["business_whatsapp_visibility"] ? (
                          <img
                            alt="whatsapp icon"
                            src={WhatsappRoundIcon}
                            className="social-icon"
                            fill="#55CD6C"
                            onClick={() => {
                              const url =
                                businessData["business_whatsapp"] &&
                                businessData["business_whatsapp"].indexOf(
                                  "+91"
                                ) > -1
                                  ? `https://wa.me/${
                                      businessData["business_whatsapp"]
                                    }/?text=${window.encodeURIComponent(
                                      "Hi\r\n\rI found you on zopfind"
                                    )}`
                                  : `https://wa.me/+91${
                                      businessData["business_whatsapp"]
                                    }/?text=${window.encodeURIComponent(
                                      "Hi\r\n\rI found you on zopfind"
                                    )}`;
                              window.open(url, "_blank");
                            }}
                          />
                        ) : (
                          <img
                            alt="whatsapp icon"
                            src={WhatsappGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Whatsapp</p>
                      </div>
                      <div>
                        {businessData["business_youtube_visibility"] ? (
                          <img
                            alt="youtube icon"
                            src={YoutubeRoundIcon}
                            style={{background: "white"}}
                            className="social-icon"
                            onClick={() =>
                              window.open(businessData["business_youtube"])
                            }
                          />
                        ) : (
                          <img
                            alt="youtube icon"
                            style={{background:"white"}}
                            src={YoutubeGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Youtube</p>
                      </div>
                      <div>
                        {businessData["business_facebook_visibility"] ? (
                          <img
                            alt="facebook icon"
                            src={FacebookRoundIcon}
                            className="social-icon"
                            onClick={() =>
                              window.open(businessData["business_facebook"])
                            }
                          />
                        ) : (
                          <img
                            alt="facebook icon"
                            src={FacebookGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Facebook</p>
                      </div>
                      <div>
                        {businessData["business_instagram_visibility"] ? (
                          <img
                            src={InstagramRoundIcon}
                            alt="instagram icon"
                            className="social-icon"
                            fill="#A16682"
                            onClick={() =>
                              window.open(
                                businessData["business_instagram"],
                                "_blank"
                              )
                            }
                          />
                        ) : (
                          <img
                            alt="instagram icon"
                            src={InstagramGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Instagram</p>
                      </div>
                    </div>
                    <div className="group-social">
                      <div>
                        {businessData["business_twitter_visibility"] ? (
                          <img
                            src={TwitterRoundIcon}
                            style={{background:"white"}}
                            alt="twitter icon"
                            className="social-icon"
                            fill="#1777F7"
                            onClick={() =>
                              window.open(businessData["business_twitter"])
                            }
                          />
                        ) : (
                          <img
                            alt="twitter icon"
                            style={{background:"white"}}
                            src={TwitterGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>X</p>
                      </div>
                      <div>
                        {businessData["business_linkedin_visibility"] ? (
                          <img
                            alt="linkedin icon"
                            src={LinkedinRoundIcon}
                            className="social-icon"
                            fill="#1777F7"
                            onClick={() =>
                              window.open(businessData["business_linkedin"])
                            }
                          />
                        ) : (
                          <img
                            alt="linkedin icon"
                            src={LinkedinGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Linkedin</p>
                      </div>
                      <div>
                        {businessData["business_skype_visibility"] ? (
                          <img
                            src={SkypeRoundIcon}
                            alt="skype icon"
                            className="social-icon"
                            fill="#1777F7"
                            style={{background: "white"}}
                            onClick={() =>
                              window.open(
                                businessData["business_skype"].indexOf(
                                  "skype"
                                ) > -1 &&
                                  businessData["business_skype"].indexOf(
                                    "invite"
                                  ) > -1
                                  ? businessData["business_skype"]
                                  : `skype:${businessData["business_skype"]}?chat`
                              )
                            }
                          />
                        ) : (
                          <img
                            alt="skype icon"
                            style={{background:"white"}}
                            src={SkypeGrayRoundIcon}
                            className="social-icon"
                          />
                        )}
                        <p>Skype</p>
                      </div>
                      <div>
                        {businessData["business_website_visibility"] ? (
                          <IoMdOpen
                            className="social-icon-2 social-icon-big"
                            fill="#fff"
                            style={{
                              backgroundColor: "#1a73e7",
                            }}
                            onClick={() =>
                              window.open(
                                businessData["business_website"].indexOf(
                                  "http://"
                                ) === 0 ||
                                  businessData["business_website"].indexOf(
                                    "https://"
                                  ) === 0
                                  ? businessData["business_website"]
                                  : `https://${businessData["business_website"]}`
                              )
                            }
                            height="30"
                          />
                        ) : (
                          <IoMdOpen className="social-icon-disabled social-icon-big" />
                        )}
                        <p>Website</p>
                      </div>
                    </div>
                  </div>
                  <div className="outer-container page-block">
                    <button
                      id="biz-pg-appo-btn"
                      className={
                        businessData["business_whatsapp"] &&
                        businessData["business_whatsapp_visibility"] &&
                        businessData["business_book_appointment_visibility"]
                          ? "bordered-btn"
                          : "bordered-btn disabled"
                      }
                      onClick={() => {
                        if (
                          businessData["business_whatsapp"] &&
                          businessData["business_whatsapp_visibility"] &&
                          businessData["business_book_appointment_visibility"]
                        ) {
                          setWhatsappMessage(
                            "Hi,\r\nI found you on zopfind\r\nI would like to Book Appointment/Enquire on"
                          );
                          setBookedDateTimeModalDisplay(
                            !bookedDateTimeModalDisplay
                          );
                        }
                      }}
                    >
                      Book Appointment/Enquire
                    </button>
                  </div>
                  <div className="outer-container page-block">
                    <button
                      className={
                        businessData["business_whatsapp"] &&
                        businessData["business_whatsapp_visibility"] &&
                        businessData["business_virtual_appointment_visibility"]
                          ? "bordered-btn"
                          : "bordered-btn disabled"
                      }
                      onClick={() => {
                        if (
                          businessData["business_whatsapp"] &&
                          businessData["business_whatsapp_visibility"] &&
                          businessData[
                            "business_virtual_appointment_visibility"
                          ]
                        ) {
                          setWhatsappMessage(
                            "Hi,\r\nI found you on zopfind\r\nI would like to take Video Consultation on"
                          );
                          setBookedDateTimeModalDisplay(
                            !bookedDateTimeModalDisplay
                          );
                        }
                      }}
                    >
                      Request Video Consultation
                    </button>
                  </div>
                  <div className="outer-container page-block">
                    {menuItems.length === 0 ? (
                      ""
                    ) : (
                      <Link to={{ pathname: "/menu-details" }}>
                        <button className="bordered-btn">Menu/Details</button>
                      </Link>
                    )}
                  </div>
                  {/* offer part started */}
                  {businessOfferVisibility ? (
                    <div
                      style={{ marginTop: "20px" }}
                      id="biz-pg-offer"
                      className="listing-offer-block page-block"
                    >
                      {/* <br /> */}
                      <h3
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Offer
                      </h3>
                      <div style={{ width: "calc(85vw)" }}>
                        <Offer
                          businessName={businessData["business_name"]}
                          businessCategory={businessData["categories_set"]}
                          offer={businessOffer}
                          cardColor={offerCardColor}
                          AddToWishList={AddToWishList}
                          businessId={businessData["id"]}
                          displayAddBtn={true}
                          dealId={businessOfferId}
                        />
                      </div>
                    </div>
                  ) : null}
                  {businessTopDealName &&
                  businessTopDealPrice &&
                  businessTopDealActualPrice &&
                  businessDealExpiry &&
                  moment(businessDealExpiry) > moment() ? (
                    <div className="listing-deal page-block">
                      <h3
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Today's Deal
                      </h3>
                      <div style={{ width: "calc(85vw)" }}>
                        <BestDeal
                          businessName={businessData["business_name"]}
                          businessCategory={businessData["categories_set"]}
                          dealName={businessTopDealName}
                          dealActualPrice={businessTopDealActualPrice}
                          dealPrice={businessTopDealPrice}
                          dealExpiry={businessDealExpiry}
                          cardColor={dealCardColor}
                          AddToWishList={AddToWishList}
                          businessId={businessData["id"]}
                          displayAddBtn={true}
                          dealId={businessDealId}
                          requestFromBusinessPage={true}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div
                    style={{ position: "relative" }}
                    className="width-85 page-block"
                  >
                    <span
                      ref={servicesRef}
                      style={{
                        position: "absolute",
                        top: "-70px",
                      }}
                    ></span>
                    <h3
                      id="business-pg-our-services"
                      style={{ fontSize: "20px" }}
                      className="bold-text"
                    >
                      Our Services
                    </h3>
                    {/* <br /> */}
                    <div
                      style={{
                        gap:
                          window.innerWidth > 768
                            ? window.innerWidth > 1000
                              ? "0 350px"
                              : "0px 240px"
                            : "0 0",
                      }}
                      className="business-page-services services1"
                    >
                      {businessServices !== null ? (
                        businessServices.map((service) => (
                          <div className={"service-detail"} key={service}>
                            <p className={"icon"}>
                              <IoMdCheckmark className="service-icon green-icon" />
                            </p>

                            <p id="listing-text-1" className="listing-text">
                              {breakWords(service, 94).join("\n")}
                            </p>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div
                    style={{ position: "relative" }}
                    className="width-85 page-block"
                  >
                    <h3
                      style={{ fontSize: "20px" }}
                      className="bold-text"
                      id="business-pg-our-services"
                    >
                      Features and Highlights
                    </h3>
                    <div
                      style={{
                        gap:
                          window.innerWidth > 768
                            ? window.innerWidth > 1000
                              ? "0 350px"
                              : "0 240px"
                            : "",
                      }}
                      className="business-page-services services1"
                    >
                      {businessFeature !== null ? (
                        businessFeature.map((feature) => (
                          <div className={"service-detail"} key={feature}>
                            <p className={"icon"}>
                              <IoMdStar className="service-icon yellow-icon" />
                            </p>
                            <p className="listing-text">
                              {breakWords(feature, 94).join("\n")}
                            </p>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="width-85 page-block">
                    <h3
                      style={{ fontSize: "20px" }}
                      className="bold-text"
                      id="business-pg-our-services"
                    >
                      Health and Safety
                    </h3>
                    <div
                      style={{
                        gap:
                          window.innerWidth > 768
                            ? window.innerWidth > 1000
                              ? "0 350px"
                              : "0 240px"
                            : "",
                      }}
                      className="business-page-services services1"
                    >
                      {businessHealthAndSafety !== null ? (
                        businessHealthAndSafety.map((health) => (
                          <div className={"service-detail"} key={health}>
                            <p className={"icon"}>
                              <IoMdMedical className="service-icon blue-icon" />
                            </p>
                            <p className="listing-text">
                              {breakWords(health, 94).join("\n")}
                            </p>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {businessData["business_home"] ? (
                    <>
                      <h3
                        style={{ fontSize: "20px", marginBottom: "20px" }}
                        className="bold-text page-block"
                      >
                        Home Service/Delivery
                      </h3>
                      <div className="home-delivery">
                        <div>
                          <IoCheckmarkCircleOutline
                            id="checkmark-icon"
                            className="big-icon"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {businessAvailableHours &&
                  !_.isEmpty(businessAvailableHours) ? (
                    <>
                      <div
                        className="business-page-services page-block"
                        style={{ width: "85%", marginTop: "10px" }}
                      >
                        <h3
                          style={{
                            fontSize: "20px",
                            marginBottom: "20px",
                            width: "100%",
                          }}
                          className="bold-text "
                        >
                          Available Hours
                        </h3>
                        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                          <div className={"available-hours"} key={day}>
                            <div
                              className={
                                isAvailable(businessAvailableHours[day])
                                  ? "day-info active"
                                  : "day-info"
                              }
                            >
                              {getWeekDay(day)}
                            </div>
                            <div className={"timing-info"}>
                              {businessAvailableHours[day]["close"]
                                ? "Close for the day"
                                : businessAvailableHours[day]["all_day"]
                                ? "24 hours open"
                                : `${moment(
                                    businessAvailableHours[day]["start_time"],
                                    "HH:mm:ss"
                                  ).format("hh:mm a")} - ${moment(
                                    businessAvailableHours[day]["end_time"],
                                    "HH:mm:ss"
                                  ).format("hh:mm a")}`}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                  {businessAddress && !_.isEmpty(businessAddress) ? (
                    <>
                      <div
                        id="business-address"
                        className="business-page-services page-block"
                        style={{
                          lineHeight: "1.4",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            width: "100%",
                            marginBottom: "20px",
                          }}
                        >
                          Address
                        </h3>
                        {businessAddress}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div
              className="listing-main-container"
              style={{ marginTop: "75px" }}
            >
              <p>No Business Found </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default BusinessPage;
