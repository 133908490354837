import { useState,useEffect } from "react";
import Alert from "../../Alert/Alert";
import Switch from "react-switch";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {getUserBusinessDetails} from "../../../services/BusinessService";
import {useHistory} from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

function Whatsapp(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [buttonPressed, setButtonPressed] = useState(JSON.parse(localStorage.getItem('whatsapp')));
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  const [whatsappNumber,setWhatsappNumber] =  useState()
  const [contactNumber,setContactNumber] = useState()
  const history = useHistory();

  const details = async () =>{
    const response = await getUserBusinessDetails()
    console.log(response.data[0])
    setContactNumber(response.data[0].business_mobile_number)
    setWhatsappNumber(response.data[0].business_whatsapp)
  }

  useEffect(()=>{
    details()
  },[])

  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Whatsapp
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <Switch
            onColor="#1777F7"
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={() =>
              props.setBusinessWhatsappVisibility(
                !props.businessWhatsappVisibility
              )
            }
            checked={props.businessWhatsappVisibility}
          />
          <br />
          <br />
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Whatsapp Number"
            variant="outlined"
            id="whatsapp_number"
            className="full-width-input custom-material-input text-align-left"
            type="text"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
              inputProps: {
                maxLength: 10,
                minLength: 10,
              },
            }}
            placeholder="Whatsapp Number"
            onChange={(e) => {props.setBusinessWhatsapp(e.target.value); setWhatsappNumber(e.target.value)}}
            value={props.businessWhatsapp}
            disabled={!props.businessWhatsappVisibility}
          />
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if(whatsappNumber !== contactNumber && props.businessWhatsappVisibility === true){
                console.log(whatsappNumber)
                try{
                  await axios.post(process.env.REACT_APP_BASE_URL_API + `/api/v1/business/whatsapp-send-otp/`,
                  {
                    "whatsapp_number":`${whatsappNumber}`
                  },
                  {
                    headers: {
                      Authorization: `Token ${window.localStorage.getItem("token")}`,
                    },
                  })
                  history.push({
                    pathname: "/whatsapp-verification",
                    state: {
                      from: history.location.pathname,
                      whatsappNumber:whatsappNumber
                    },
                  });
                }catch(error){
                  console.log(error)
                }
                localStorage.setItem('whatsapp','false')
                setButtonPressed(JSON.parse(localStorage.getItem('whatsapp')));
              }
              else{
                if (props.businessWhatsappVisibility === false && buttonPressed===false) {
                  setShowAlert("none");
                  let postData = {
                    business_whatsapp: props.businessWhatsapp,
                    business_whatsapp_visibility:
                      props.businessWhatsappVisibility,
                  };
                  await props.updateDetails(postData);
                  localStorage.setItem('whatsapp','true')
                  setButtonPressed(JSON.parse(localStorage.getItem('whatsapp')));
                }else if(props.businessWhatsappVisibility === false && buttonPressed===true){
                  toast.warning("Button Is Disabled");
                }else {
                  if (
                    (props.businessWhatsappVisibility === true &&
                      (props.businessWhatsapp === "" ||
                        // _.isEmpty(props.businessWhatsapp) ||
                        props.businessWhatsapp.length > 10 ||
                        props.businessWhatsapp.length < 10)) ||
                    props.businessWhatsapp.match(/^\d{10}$/) == null
                  ) {
                    setAlertMessgae("Inavlid number!");
                    setShowAlert("block");
                  } else {
                    setShowAlert("none");
                    const pstData = {
                      business_whatsapp: props.businessWhatsapp,
                      business_whatsapp_visibility:
                        props.businessWhatsappVisibility,
                    };
                    if (props.businessWhatsappVisibility === false) {
                      pstData["business_book_appointment_visibility"] = false;
                      pstData["business_virtual_appointment_visibility"] = false;
                    }
                    await props.updateDetails(pstData);
                  }
                  localStorage.setItem('whatsapp','false')
                  setButtonPressed(JSON.parse(localStorage.getItem('whatsapp')));
                }
              }
            }}
          >
            Done
          </button>
          <br />
          <p>You need to add Whatsapp</p>
        </div>
      </div>
    </>
  );
}

export default Whatsapp;
