import { useState } from "react";
import { useHistory } from "react-router-dom";

import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link } from "react-router-dom";
import { Countries } from "../../Countries";
import Alert from "../Alert/Alert";
import Select from "react-select";
import _ from "lodash";

function BusinessCountrySelection(props) {
  const history = useHistory();
  // const countries = Countries;
  const countries = Countries.map((obj) => {
    return { name: obj.country_name, label: obj.country_name };
  });

  const [country, setCountry] = useState("India");
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );

  const changeCountry = (selected_country) => {
    setCountry(selected_country.name);
  };
  const submit = () => {
    if (_.isEmpty(country)) {
      setAlertMessgae("Please select the country option!");
      setShowAlert(true);
      return;
    }
    history.push({
      pathname: "/business-register-category",
      state: {
        from: history.location.pathname,
        country: country,
      },
    });
  };
  return (
    <div className="body-container-footer">
      <div className="profile-page">
        <div className="back-div">
          <Link to="/profile" className="link">
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
        <h2 style={{ marginTop: "100px", marginBottom: "23px" }}>
          Select Country
        </h2>
        <Alert value={alertMessage} show={showAlert} />
        <div className="profile-page-element-container">
          <div className="profile-page-element">
            <Select
              name="form-field-name"
              className="auto-search-select"
              placeholder="Search Country"
              multi={false}
              value={countries[0]}
              options={countries}
              onChange={changeCountry}
            />
            <br />
            <br />
            <button className="done-btn" onClick={submit}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessCountrySelection;
