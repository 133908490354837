import HowToInstallComman from "../CommonComponets/HowToInstallComman";
import "../../utils/common.css";

function HowToInstallSetting() {
  return (
    <>
      <div className="body-container-footer" >
        <HowToInstallComman
          back="/setting"
          whyWebApp="/setting-why-web-app"
          video="/setting-video-installation"
        />
      </div>
    </>
  );
}

export default HowToInstallSetting;
