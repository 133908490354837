import { Box, Modal, TextField, Typography } from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, {useState, useEffect } from "react";
import { BiRupee } from "react-icons/bi";
import { TiDeleteOutline } from "react-icons/ti";
import { IoCaretDownOutline } from "react-icons/io5";
import { AiOutlineCaretRight } from "react-icons/ai";
import { toast } from "react-toastify";
import DeleteSubCategoryModel from "./DeleteSubCategoryModel";
import DeleteCategoryModel from "./DeleteCategoryModel";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "40px",
  boxShadow: 24,
  p: 4,
};

const AddMenuDetails = (props) => {
  const history = useHistory();
  const [menuItems, setMenuItems] = React.useState([]);
  // console.log(menuItems);
  const [MenuDetails, setMenuDetails] = React.useState({
    category: "",
    subCategory: "",
    price: "",
  });

  const [open, setOpen] = React.useState(false);
  const [changeAddClicked, setChangeAddClicked] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [deleteSubCategoryModel, setdeleteSubCategoryModel] = React.useState(null);
  const [deleteCategoryModel, setdeleteCategoryModel] = React.useState(null);
  const [addButtonBackground, setAddButtonBackground] = React.useState("grey");

  const [totalCategories,setTotalCategories] = useState({})

  // OpendeleteCategoryModel here
  const handleOpendeleteCategoryModel = () => {
    setdeleteCategoryModel(true);
  };
  const handleClosedeleteCategoryModel = () => {
    setdeleteCategoryModel(false);
  };

  // OpendeleteSubCategoryModel here
  const handleOpendeleteSubCategoryModel = () => {
    setdeleteSubCategoryModel(true);
  };
  const handleClosedeleteSubCategoryModel = () => {
    setdeleteSubCategoryModel(false);
  };


  const resetModalState = () => {
    setMenuDetails({
      category: "",
      subCategory: "",
      price: "",
    });
    setSubCategoryDisabled(true);
    setPriceDisabled(true);
    setAddButtonDisabled(true);
  };

  const handleOpen = () => {
    setOpen(true);
    resetModalState();
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleCategoryChange = (event) => {
    setMenuDetails((prevState) => ({
      ...prevState,
      category: event.target.value,
    }));
    if (event.target.value !== "") {
      setSubCategoryDisabled(false);
    } else {
      setSubCategoryDisabled(true);
    }
  };

  const handleSubCategoryChange = (event) => {
    setMenuDetails((prevState) => ({
      ...prevState,
      subCategory: event.target.value,
    }));
    if (event.target.value !== "") {
      setPriceDisabled(false);
    } else {
      setPriceDisabled(true);
    }
  };

  const handlePriceChange = (event) => {
    setMenuDetails((prevState) => ({
      ...prevState,
      price: event.target.value,
    }));
    if (event.target.value !== "") {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  };

  const [subCategoryDisabled, setSubCategoryDisabled] = React.useState(true);
  const [priceDisabled, setPriceDisabled] = React.useState(true);
  const [addButtonDisabled, setAddButtonDisabled] = React.useState(true);

  const handleMenuDetails = async () => {
    const payload = {
      category_name: MenuDetails.category,
      subcategories: [
        {
          sub_category_name: MenuDetails.subCategory,
          price: MenuDetails.price,
        },
      ],
    };
    // console.log("payload", payload);
    const token = window.localStorage.getItem("token");

    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menu-items/",
        payload,
        config
      );

      if (response.data.status === 400) {
        console.log("Error");
      } else if (response.status === 200) {
        toast.success("Menu Details Added Successfully !", {
          autoClose: 2000,
        });
        handleClose();
        // console.log("user Successfully Added");

        // Call fetchData() here to fetch and display updated data
        fetchData();
      }
    } catch (error) {
      console.error("Post error:", error);
    }
  };

  const fetchData = async () => {
    const token = window.localStorage.getItem("token");

    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL_API + "/api/v1/business/menucategories/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Update the state with the response data
        setMenuItems(response.data);
      } else {
        console.log("Error fetching menu items");
      }
    } catch (error) {
      console.error("GET request error:", error);
    }
  };
  useEffect(() => {
    const { category, subCategory, price } = MenuDetails;
    if (category && subCategory && price) {
      setAddButtonBackground("#1a73e8");
    } else {
      setAddButtonBackground("grey");
    }
    fetchData();

    if (selectedCategory !== null) {
      setTotalCategories(prevTotalCategories => ({
        ...prevTotalCategories,
        [selectedCategory]: !prevTotalCategories[selectedCategory],
      }));
    } else {
      // When no category is selected, ensure that none are marked as open
      setTotalCategories(prevTotalCategories => {
        let newTotalCategories = { ...prevTotalCategories };
        Object.keys(newTotalCategories).forEach(key => {
          newTotalCategories[key] = false;
        });
        return newTotalCategories;
      });
    }
  },[MenuDetails,selectedCategory]);

  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Menu/Details
      </h2>
      <div
        style={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        {changeAddClicked ? null : (
          <div>
            <button
              onClick={handleOpen}
              style={{ fontWeight: 500, marginTop: "50px" }}
              className="bordered-btn"
            >
              Add Category
            </button>

            {menuItems.length === 0 ? null : (
              <button
                onClick={() => {
                  setChangeAddClicked(true);
                  history.push("/change-add");
                }}
                className="bordered-btn"
                style={{
                  marginTop: "20px",
                  fontWeight: 500,
                  marginTop: "30px",
                }}
              >
                Change/Add
              </button>
            )}
          </div>
        )}
      </div>

      <Box
        marginTop={4}
        display={"flex"}
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "none", md: "center" }}
        alignItems={{ xs: "center", md: "start" }}
        padding={"10px"}
        width={{ xs: "auto", md: "100%" }}
        flexWrap={{ xs: "none", md: "wrap" }}
      >
        {menuItems.map((category, index) => {
          const totalSubcategories = category.subcategories.length;
          const toggleDropdown = (categoryName) => {
            setSelectedCategory(prevSelectedCategory => 
              prevSelectedCategory === categoryName ? null : categoryName
            );
          };

          return (  
            
            <div key={index} style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"flex-start", gap:"0px",margin:"20px"}}>
              <p  style={{marginLeft:"25px",marginBottom:"-8px",fontWeight:"600"}}>
                  Category
                </p>
              <Box key={index}>
                
              <div style={{display: "flex",overflow:"inherit",flexDirection: "column",justifyContent: "space-between",margin: "10px",padding: "10px",height:"50px",width:"193px",cursor: "pointer",fontWeight: "bold", }}>
                <div>
                  {/* Category name buttons starts here */}
                  <div style={{position:"absolute",boxShadow:"0 1px 5px -2px rgba(0, 0, 0, 0.8)",marginTop:"-8px",background:"#FAFAFA",padding:"10px",borderRadius: " 16px 16px 16px 16px",height:"60px",width:"190px",display:"flex", justifyContent:"space-between", alignItems:"center",overflow:"scroll"}}>
                      {/* <div onClick={()=>{setSelectedId(category.id); toggleDropdown();   }} style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"1px",}} > */}
                      <div onClick={() => {toggleDropdown(category.category_name); setSelectedId(category.id);}} style={{display:"flex",justifyContent:"space-between",alignItems:"center",gap:"1px",}} >
                        <div style={{textAlign: "left",wordBreak:"break-all",width:"108px"}}>
                          {category.category_name}
                          {`(${totalSubcategories})`}

                          <span style={{ cursor: "pointer" }}>
                            {/* {totalCategories[category.category_name] ? "▼" : "▶"} */}
                            {totalCategories[category.category_name] ? <IoCaretDownOutline />: <AiOutlineCaretRight />}
                          </span>
                        </div>
                      </div>
                      
                      <div onClick={() => { handleOpendeleteCategoryModel();
                        if (!totalCategories[category.category_name]) {
                          toggleDropdown();
                          handleOpendeleteCategoryModel();
                        }
                        else{
                          handleOpendeleteCategoryModel();
                        }
                         setSelectedId(category.id);}}>
                        <TiDeleteOutline style={{fontSize: "25px",fill: "red",cursor: "pointer",}} />
                      </div>

                  </div>
                </div>
              </div>

                {/* {selectedCategory === category.category_name && ( */}
                {totalCategories[category.category_name] && (
                  <div style={{textAlign:"left",marginLeft:"20px"}}>
                    <h6 style={{ textAlign:"left",fontWeight: "600" ,marginTop:"30px",marginLeft:"6px"}}>
                      Sub-Category and Price
                    </h6>
                    {category.subcategories.map((subCategory, subIndex) => (
                      <div
                        key={subIndex}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignSelf: "center",
                          gap: "10px",
                          borderRadius: "20px",
                          backgroundColor: "#FAFAFA",
                          marginTop: "10px",
                          padding: "0px 15px",
                          paddingTop: "20px",
                          boxShadow: "0 1px 5px -2px rgba(0, 0, 0, 0.8)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "40px",
                          }}
                        >
                          <p style={{ fontWeight: "600" }}>
                            {" "}
                            {subCategory.sub_category_name}
                          </p>

                          <p style={{ fontWeight: "600" }}>
                            <BiRupee />
                            {subCategory.price}
                          </p>
                        </div>
                        <div>
                          <p
                            onClick={() => {
                              handleOpendeleteSubCategoryModel();
                              setSelectedId(subCategory.id);
                            }}
                          >
                            <TiDeleteOutline
                              style={{
                                fontSize: "25px",
                                fill: "red",
                                cursor: "pointer",
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    ))}
                    {
                      <DeleteSubCategoryModel
                        open={deleteSubCategoryModel}
                        handleClose={handleClosedeleteSubCategoryModel}
                        fetchData={fetchData}
                        id={selectedId}
                      />
                    }
                    {
                      <DeleteCategoryModel
                        open={deleteCategoryModel}
                        handleClose={handleClosedeleteCategoryModel}
                        fetchData={fetchData}
                        id={selectedId}
                      />
                    }
                  </div>
                )}
              </Box>

            </div>
              
          );
        })}
      </Box>
      
      {/* Model start here */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="business-element page-element"
          sx={style}
          width={{ xs: 350, md: 600 }}
          position="relative"
        >
          <Box
            position="absolute"
            top={20}
            right={30}
            fontWeight={"bold"}
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              zIndex: 1,
            }}
            fontSize={"25px"}
          >
            &times;
          </Box>
          <Typography id="modal-modal-title" variant="h5" component="h2" style={{marginTop:"16px"}}>
            Add
          </Typography>

          <br />
          <TextField
            name="category"
            value={MenuDetails.category}
            onChange={handleCategoryChange}
            label="Category"
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            fullWidth
          />
          <TextField
            name="subCategory"
            label="SubCategory"
            value={MenuDetails.subCategory}
            onChange={handleSubCategoryChange}
            style={{ marginTop: "20px" }}
            variant="outlined"
            className="full-width-input custom-material-input"
            type="text"
            fullWidth
            disabled={subCategoryDisabled}
          />
          <TextField
            name="price"
            label="Price"
            value={MenuDetails.price}
            onChange={handlePriceChange}
            style={{ marginTop: "20px" }}
            variant="outlined"
            className="full-width-input custom-material-input"
            fullWidth
            disabled={priceDisabled}
          />

          <button
            onClick={handleMenuDetails}
            className="done-btn full-width-btn"
            style={{
              backgroundColor: addButtonBackground,
              cursor: "pointer",
              pointerEvents: addButtonBackground === "grey" ? "none" : "auto",
            }}
            disabled={!addButtonBackground || addButtonBackground === "grey"}
          >
            Add
          </button>
        </Box>
      </Modal>
    </>
  );
};

export default AddMenuDetails;

         // setTotalCategories({[category.category_name]:""})
          // console.log(totalCategories)
          // const toggleDropdown = () => {
          //   console.log(category.id)
          //   if (selectedCategory === category.category_name) {
          //     setSelectedCategory(null);
          //     // console.log(`${category.category_name} is closed ${false}`)
          //     setTotalCategories({...totalCategories,[category.category_name]:false})
          //     // console.log(category.category_name `is closed ${[category.category_name]}`)
          //     console.log(category.category_name + "is closed")
          //     // console.log(totalCategories[category.category_name])
          //     // console.log(totalCategories)
          //   } else {
          //     // console.log(`${category.category_name} is opened ${true}`)
          //     setSelectedCategory(category.category_name);
          //     setTotalCategories({...totalCategories,[category.category_name]:true})
          //     // console.log(category.category_name `is opened ${[category.category_name]} `)
          //     // console.log(totalCategories[category.category_name])
          //     // console.log(totalCategories)
          //     console.log(category.category_name +" is opened" )
          //   }
          // };


  // useEffect(() => {
  //   const { category, subCategory, price } = MenuDetails;
  //   if (category && subCategory && price) {
  //     setAddButtonBackground("#1a73e8");
  //   } else {
  //     setAddButtonBackground("grey");
  //   }
  //   fetchData();

  //     setTotalCategories(prevTotalCategories => ({
  //     ...prevTotalCategories,
  //     // Set to true if the category is selected (opened), otherwise false (closed)
  //     [selectedCategory]: selectedCategory !== null,
  //   }));
  //           // Additional actions based on the updated selectedCategory
  // }, [MenuDetails,selectedCategory]);


  // console.log(MenuDetails)