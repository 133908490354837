import Alert from "../../Alert/Alert";
import { TiDeleteOutline } from "react-icons/ti";
import TextField from "@material-ui/core/TextField";
import { useState } from "react";
import _ from "lodash";

function Health(props) {
  const [showAlert, setShowAlert] = useState("none");
  const [alertMessage, setAlertMessgae] = useState(
    "Please Provide All The Details"
  );
  return (
    <>
      <h2 className={"title-color"} style={{ fontWeight: "bold" }}>
        Add Health and Safety
      </h2>
      <Alert value={alertMessage} show={showAlert} />
      <div className="add-detail-page-element-container">
        <div className="business-element page-element">
          <TextField
            inputRef={(input) => input && input.focus()}
            autoFocus={true}
            label="Health"
            variant="outlined"
            id="health"
            inputProps={{ maxLength: 100, minLength: 1 }}
            className="full-width-input custom-material-input"
            type="text"
            placeholder="Health"
            onChange={(e) => props.setHealth(e.target.value)}
            value={props.health}
          />
          {/* <input
        id="location"
        className="full-width-input custom-material-input"
        type="text"
        placeholder="Add Here"
        value={health}
        onChange={(e) => setHealth(e.target.value)}
        /> */}
          <button
            className="done-btn full-width-btn"
            onClick={async () => {
              if (
                props.health == null ||
                _.isEmpty(props.health) ||
                (props.health != null && _.isEmpty(props.health.trim()))
              ) {
                return;
              }
              if (
                props.businessHealthAndSafety !== null &&
                props.businessHealthAndSafety !== []
              ) {
                if (
                  _.filter(
                    props.businessHealthAndSafety,
                    (name) => name.toLowerCase() === props.health.toLowerCase()
                  ).length > 0
                ) {
                  setAlertMessgae("Health Already Exist!");
                  setShowAlert("block");
                  return;
                }
                setAlertMessgae("Please Provide All The Details");
                setShowAlert("none");
                props.setBusinessHealthAndSafety([
                  ...props.businessHealthAndSafety,
                  props.health,
                ]);
                await props.updateDetails({
                  business_health_and_safety: [
                    ...props.businessHealthAndSafety,
                    props.health,
                  ],
                });
              } else {
                props.setBusinessHealthAndSafety([props.health]);
                await props.updateDetails({
                  business_health_and_safety: [props.health],
                });
              }
            }}
          >
            Add
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              padding: "10px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.businessHealthAndSafety !== null &&
            props.businessHealthAndSafety !== [] ? (
              props.businessHealthAndSafety.map((ahealth, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "16px",
                    backgroundColor: "#FAFAFA",
                    margin: "10px",
                    padding: "10px",
                    boxShadow: "0 1px 5px -2px rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <div key={index + "health"}>{ahealth}</div>
                  <TiDeleteOutline
                    style={{
                      fontSize: "25px",
                      fill: "red",
                    }}
                    onClick={async (e) => {
                      props.setDeleteElement({
                        elem_type: "health",
                        elem: ahealth,
                      });
                      props.setOpenDailog(true);
                    }}
                  />
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Health;
