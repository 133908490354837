import "./offer.css";
import { categoriesDisplay } from "../../utils/common.js"
function Offer(props) {
  return (
    <div style={{}} className="business-offer">
      <div
        style={{ backgroundColor: props.cardColor }}
        className="offer-section"
        onClick={(e) => {
          if (props.businessId) {
            window.location.href = `/business-page/` + props.businessId;
          }
        }}
      >
        {props.displayAddBtn ? (
          <div className={"save-deal-offer-container"}>
            <div
              className="save-deal-offer"
              style={{ color: props.cardColor }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.AddToWishList(props.dealId);
              }}
            >
              Save
            </div>
          </div>
        ) : null}
        {props.deleteDealOffer ? (
          <div className={"save-deal-offer-container"}>
            <div
              className="save-deal-offer"
              style={{ color: props.cardColor }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.deleteDealOffer(props.dealId);
              }}
            >
              Delete
            </div>
          </div>
        ) : null}
        <p
          style={{
            fontSize: "24px",
            fontWeight: 600,
            marginBottom: 0,
            marginTop:
              props.displayAddBtn || props.deleteDealOffer ? 0 : "20px",
          }}
        >
          {props.businessName}
        </p>
        <p style={{ margin: "11px 11px 20px", fontSize: "18px" }}>
          {categoriesDisplay(props.businessCategory,props.requestFromBusinessPage)}
        </p>
        <p
          style={{
            fontSize: "32px",
            fontWeight: 600,
            marginTop: "22px",
            marginBottom: "20px",
          }}
        >
          {props.offer}% OFF
        </p>
      </div>
    </div>
  );
}

export default Offer;
