import "./BestDeal.css";
import { FaRupeeSign } from "react-icons/fa";
import moment from "moment";
import Countdown from "react-countdown";
import { categoriesDisplay } from "../../utils/common.js"

function Deal(props) {
  return (
    <div
      style={{ border: "none" }}
      className="best-deal business-offer"
      onClick={(e) => {
        // if (props.businessId) {
        // window.location.href = `/business-selected/${props.businessId}`;
        // }
        if (props.businessId) {
          window.location.href = `/business-page/${props.businessId}`;
        }
      }}
    >
      <div
        className="top-section"
        style={{
          backgroundColor: props.cardColor,
          border: "none",
          borderRadius: "30px 30px 0px 0px",
        }}
      >
        {props.displayAddBtn ? (
          <div className={"save-deal-offer-container"}>
            <div
              className="save-deal-offer"
              style={{ color: props.cardColor }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.AddToWishList(props.dealId);
              }}
            >
              Save
            </div>
          </div>
        ) : null}
        {props.deleteDealOffer ? (
          <div className={"save-deal-offer-container"}>
            <div
              className="save-deal-offer"
              style={{ color: props.cardColor }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.deleteDealOffer(props.dealId);
              }}
            >
              Delete
            </div>
          </div>
        ) : null}

        <p
          style={{
            fontWeight: 600,
            fontSize: "24px",
            marginBottom: 0,
            marginTop:
              props.displayAddBtn || props.deleteDealOffer ? 0 : "20px",
          }}
        >
          {props.businessName}
        </p>
        <p style={{ fontSize: "18px" }}>{ categoriesDisplay(props.businessCategory,props.requestFromBusinessPage)}</p>
      </div>
      <div
        style={{
          borderRight: "1px solid #ccc",
          borderLeft: "1px solid #ccc",
          borderBottom: "1px solid #ccc",
          borderRadius: "0px 0px 30px 30px",
        }}
        className="bottom-section"
      >
        <h3
          style={{
            color: "#1777F7",
            margin: "10px 0",
            fontSize: "18px",
            textAlign: "center",
            marginTop: "30px",
          }}
        >
          {props.dealName}
        </h3>
        <div className="deal-price-bar deal-price-bar-width">
          <div
            style={{
              width: "105px",
            }}
          >
            <p
              style={{
                color: "black",
                margin: "0",
                textDecoration: "line-through",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="barprice-1"
            >
              <FaRupeeSign style={{ fontSize: "20px" }} />
              {parseInt(props.dealActualPrice)}
            </p>
          </div>
          <div
            style={{
              width: "105px",
            }}
          >
            <p
              style={{
                color: "#1777F7",
                margin: "0px",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="barprice-2"
            >
              <FaRupeeSign style={{ fontSize: "20px" }} />
              {parseInt(props.dealPrice)}
            </p>
          </div>
          <div
            style={{
              width: "105px",
            }}
          >
            <p
              style={{
                backgroundColor: "#DBEEFF",
                color: "#1777F7",
                fontSize: "14px",
                padding: "6px",
                borderRadius: "4px",
                margin: 0,
                fontWeight: 600,
                // width:"70px",
                display: "inline",
              }}
              className="barprice-3"
            >
              {parseInt(
                ((props.dealActualPrice - props.dealPrice) /
                  props.dealActualPrice) *
                  100
              )}
              % OFF
            </p>
          </div>
        </div>
        <p
          style={{
            color: "#6a6a6a",
            marginBottom: "5px",
            marginTop: "15px",
            fontSize: "11px",
          }}
        >
          Validity:
          {`${moment(props.dealExpiry)
            .subtract(24, "hours")
            .format("DD/MMM/YYYY HH:mma")} - ${moment(props.dealExpiry).format(
            "DD/MMM/YYYY HH:mma"
          )}`}
        </p>
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "12px",
            marginBottom: "20px",
          }}
        >
          {props.leftTime}
        </p>
      </div>
    </div>
  );
}

function BestDeal(props) {
  const renderDeal = (prop, deal, cardColour) => {
    if (prop.completed) {
      // Render a complete state
      return null;
    } else {
      // Render a countdown
      const leftTime =
        prop.hours > 2
          ? `${prop.hours} hours left`
          : prop.minutes > 59 && prop.hours <= 0
          ? prop.minutes <= 10
            ? `Only ${prop.minutes} minute, ${prop.seconds} second left`
            : `Only ${prop.minutes} minute left`
          : `${prop.hours} hours ${prop.minutes} minute left`;
      return (
        <Deal
          dealId={props.dealId}
          deleteDealOffer={deal.deleteDealOffer}
          businessName={deal["businessName"]}
          businessCategory={deal["businessCategory"]}
          dealName={deal["dealName"]}
          dealActualPrice={deal["dealActualPrice"]}
          dealPrice={deal["dealPrice"]}
          dealExpiry={deal["dealExpiry"]}
          cardColor={cardColour}
          leftTime={leftTime}
          AddToWishList={props.AddToWishList}
          businessId={props.businessId}
          displayAddBtn={props.displayAddBtn}
          requestFromBusinessPage={props.requestFromBusinessPage}
        />
      );
    }
  };
  return (
    <Countdown
      date={new Date(props["dealExpiry"])}
      renderer={(event) => renderDeal(event, { ...props }, props["cardColor"])}
    />
  );
}

export default BestDeal;
