// import logo from './logo.svg';
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import Category from "./components/Category/Category";
import CategorySearch from "./components/CategorySearch/CategorySearch";
import Profile from "./components/Profile/Profile";
import ProfilePage from "./components/Profile/ProfilePage";
import CountryPage from "./components/Profile/CountryPage";
import SettingPage from "./components/Profile/SettingPage";
import AccountPage from "./components/Profile/AccountPage";
import HelpPage from "./components/Profile/HelpPage";
import AboutPage from "./components/Profile/AboutPage";
import ReportProblemPage from "./components/Profile/ReportProblemPage";
import ProblemSubmissionPage from "./components/Profile/ProblemSubmissionPage";
import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import React, { Component } from "react";
import SignUp from "./components/SignUp/SignUp";
import CountrySelection from "./components/CustomerRegistration/CountrySelection";
import LocationSelection from "./components/CustomerRegistration/LocationSelection";
import BusinessCountrySelection from "./components/BusinessRegistration/CountrySelection";
import BusinessCategory from "./components/BusinessRegistration/Category";
import BusinessLocationSelection from "./components/BusinessRegistration/LocationSelection";
import MobileSelection from "./components/BusinessRegistration/MobileSelection";
import MobileVerification from "./components/BusinessRegistration/MobileVerification";
import BusinessAddDetails from "./components/BusinessRegistration/BusinessAddDetails";
import BusinessDetails from "./components/BusinessRegistration/BusinessDetails";
import BusinessMainPage from "./components/BusinessRegistration/BusinessMainPage";
import EditBusinessProfile from "./components/BusinessRegistration/EditBusinessProfile";
import EditBusinessName from "./components/BusinessRegistration/EditBusinessName";
import BusinessListingForCategory from "./components/BusinessListing/BusinessListingForCategory";
import PaymentStarter from "./components/BusinessRegistration/PaymentStarter";
import PaymentStatus from "./components/BusinessRegistration/PaymentStatus";
import PaymentTakingTime from "./components/BusinessRegistration/PaymentTakingTime";
import BusinessTiming from "./components/BusinessTiming/BusinessTiming";
import SavedOfferAndDeals from "./components/Profile/SavedOfferAndDeals";
import FirstScreen from "./components/InitialProcess/FirstScreen";
import HowToInstall from "./components/InitialProcess/HowToInstall";
import VideoInstalltion from "./components/InitialProcess/VideoInstalltion";
import WhyWebApp from "./components/InitialProcess/WhyWebApp";
import PrivacyAndPolicy from "./components/Pages/PrivacyAndPolicy";
import TermAndService from "./components/Pages/TermAndService";
import AboutUs from "./components/Pages/AboutUs";
import RefundPolicy from "./components/Pages/RefundPolicy";
import { ToastContainer } from "react-toastify";
import BusinessPage from "./components/BusinessListing/BusinessPage";
import CopyRight from "./components/Pages/CopyRight";
import WhatsappVerification from "./components/BusinessRegistration/WhatsappVerification";

// IMPORT SETTING HOW TO INSTALL
import HowToInstallSetting from "./components/Profile/HowToInstallSetting";
import WhyWebAppSetting from "./components/Profile/WhyWebAppSetting";
import VideoInstalltionSetting from "./components/Profile/VideoInstalltionSetting";
import TermAndServiceSettings from "./components/Profile/Settings/TermsAndServiceSettings";
import PrivacyAndPolicySettings from "./components/Profile/Settings/PrivacyAndPolicySettings";
import ContactUs from "./components/Pages/ContantUs";
import MenuDetails from "./components/BusinessListing/MenuDetails";
import Change_Add from "./components/BusinessRegistration/BusinessDetails/AddMenu/Change_Add";
import {hash} from "./meta.json"
import OfferUsageGuide from "./components/Profile/OfferUsageGuide";

function PrivateRoute({ children, ...rest }) {
  const token = window.localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect to={{ pathname: "/", state: { from: location } }} />
        )
      }
    />
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.is_user_a_customer = window.localStorage.getItem("is_user_a_customer");
    if (window.innerWidth > 5000) {
      this.state = {
        headerComponent: <Header />,
        footerComponent: <p style={{ display: "none" }}></p>,
      };
    } else {
      this.state = {
        headerComponent: <p style={{ display: "none" }}></p>,
        footerComponent: <Footer />,
      };
    }
    window.addEventListener("resize", this.checkWindowSize);
  }

  checkWindowSize = () => {
    if (window.innerWidth > 5000) {
      this.setState({
        headerComponent: <Header />,
        footerComponent: <p style={{ display: "none" }}></p>,
      });
    } else {
      this.setState({
        headerComponent: <p style={{ display: "none" }}></p>,
        footerComponent: <Footer />,
      });
    }
  };
  render() {
    return (
      <Router>
        <div className="App">
          <ToastContainer />
          <Switch>
            <Route exact path="/">
              <FirstScreen />
            </Route>

            <Route exact path="/login">
              <Login />
            </Route>

            <Route exact path="/signup">
              <SignUp />
            </Route>

            <Route exact path="/how-to-install">
              {this.state.headerComponent}
              <HowToInstall />
            </Route>

            <Route exact path="/installation">
              {this.state.headerComponent}
              <VideoInstalltion />
            </Route>

            <Route exact path="/why-web-app">
              {this.state.headerComponent}
              <WhyWebApp />
            </Route>

            <Route exact path="/privacy-policy">
              {this.state.headerComponent}
              <PrivacyAndPolicy />
            </Route>

            <Route exact path="/terms-of-service">
              {this.state.headerComponent}
              <TermAndService />
            </Route>

            <Route exact path="/about-us">
              {this.state.headerComponent}
              <AboutUs />
            </Route>

            <Route exact path="/contact-us">
              {this.state.headerComponent}
              <ContactUs />
            </Route>

            <Route exact path="/refund-policy">
              {this.state.headerComponent}
              <RefundPolicy />
            </Route>

            <Route exact path="/customer-register-country">
              <CountrySelection />
            </Route>

            {/* private routes */}

            <PrivateRoute exact path="/home">
              {this.state.headerComponent}
              <Home />
              {
                <Footer
                  mobile={window.innerWidth < 768 ? true : false}
                  blue={true}
                />
              }
            </PrivateRoute>

            <PrivateRoute exact path="/categories">
              {this.state.headerComponent}
              <Category />
              {
                <Footer
                  mobile={window.innerWidth < 768 ? true : false}
                  blue={true}
                />
              }
            </PrivateRoute>

            <PrivateRoute exact path="/category-search">
              {this.state.headerComponent}
              <CategorySearch />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/profile">
              {this.state.headerComponent}
              <Profile />
              {
                <Footer
                  mobile={window.innerWidth < 768 ? true : false}
                  blue={true}
                />
              }
            </PrivateRoute>

            <PrivateRoute exact path="/saved-offer-and-deals">
              {this.state.headerComponent}
              <SavedOfferAndDeals />
              {this.state.footerComponent}
            </PrivateRoute>
            
            <PrivateRoute exact path="/offer-usage-guide">
              {this.state.headerComponent}
              <OfferUsageGuide />
            </PrivateRoute>

            <PrivateRoute exact path="/my-profile">
              {this.state.headerComponent}
              <ProfilePage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/change-country">
              {this.state.headerComponent}
              <CountryPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting">
              {this.state.headerComponent}
              <SettingPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-how-to-install">
              {this.state.headerComponent}
              <HowToInstallSetting />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-terms-of-service">
              {this.state.headerComponent}
              <TermAndServiceSettings />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-privacy-policy">
              {this.state.headerComponent}
              <PrivacyAndPolicySettings />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-why-web-app">
              {this.state.headerComponent}
              <WhyWebAppSetting />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-video-installation">
              {this.state.headerComponent}
              <VideoInstalltionSetting />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-account">
              {this.state.headerComponent}
              <AccountPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-help">
              {this.state.headerComponent}
              <HelpPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/setting-about">
              {this.state.headerComponent}
              <AboutPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/copyright">
              {this.state.headerComponent}
              <CopyRight />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/help-report-problem">
              {this.state.headerComponent}
              <ReportProblemPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/submit-problem">
              {this.state.headerComponent}
              <ProblemSubmissionPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-register-country">
              {this.state.headerComponent}
              <BusinessCountrySelection />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-register-category">
              {this.state.headerComponent}
              <BusinessCategory />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-register-location">
              {this.state.headerComponent}
              <BusinessLocationSelection />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-register-mobile">
              {this.state.headerComponent}
              <MobileSelection />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-mobile-verification">
              {this.state.headerComponent}
              <MobileVerification />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/whatsapp-verification">
              {this.state.headerComponent}
              <WhatsappVerification />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/payment-starter">
              {this.state.headerComponent}
              <PaymentStarter />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-page/:id">
              {this.state.headerComponent}
              <BusinessPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/menu-details">
              {this.state.headerComponent}
              <MenuDetails />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/change-add">
              {this.state.headerComponent}
              <Change_Add />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/my-business">
              {this.state.headerComponent}
              <BusinessMainPage />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business/details">
              {this.state.headerComponent}
              <BusinessDetails />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business/add-details">
              {this.state.headerComponent}
              <BusinessAddDetails />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business/edit-profile">
              {this.state.headerComponent}
              <EditBusinessProfile />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business/edit-profile-name">
              {this.state.headerComponent}
              <EditBusinessName />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/business-your-area">
              {this.state.headerComponent}
              <BusinessListingForCategory />
              {this.state.footerComponent}
            </PrivateRoute>

            <PrivateRoute exact path="/payment_status/:order_id">
              <PaymentStatus />
            </PrivateRoute>

            <PrivateRoute exact path="/business-payment-taking-time">
              <PaymentTakingTime />
            </PrivateRoute>

            <PrivateRoute exact path="/address">
              {this.state.headerComponent}
              <BusinessTiming />
              {this.state.footerComponent}
            </PrivateRoute>

            {this.is_user_a_customer === "true" ? (
              <p style={{ display: "none" }}></p>
            ) : (
              <Route exact path="/customer-register-location">
                <LocationSelection />
              </Route>
            )}
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
