// import React, { Component } from "react";
import "../Login/Login.css";
import { googleLogin } from "../../services/LoginService";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import ZopfindLogo from "../../assets/logo/logo.svg";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "../../utils/common.css";

const languages = [
  { id: 1, language: "English", default: true },
  { id: 2, language: "Hindi", default: false },
];

function SignUp() {
  const [showLoader, setShowLoader] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const dataRef = useRef(showLogin);
  const history = useHistory();
  const hostname = window.location.hostname;

  useEffect(() => {
    dataRef.current = showLogin;
  }, [showLogin]);


  useEffect(() => {
    setTimeout(() => {
      if (!dataRef.current){
        showLoader(false)
      }
    }, 3000);
  }, []);


  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "" &&
      window.localStorage.getItem("token") !== undefined
    ) {
      history.push({
        pathname: "/home",
        state: {
          from: history.location.pathname,
        },
      });
    } else {
      setShowLogin(true);
      setShowLoader(false);
    }
  }, [history]);


  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  /*global google*/
  let client;
  if (typeof google != "undefined") {
    client = google.accounts.oauth2.initCodeClient({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
      callback: async (response) => {
        await googleLogin(response.code, hostname).then((res) => {
          if (!res.data["is_profile_completed"]) {
            history.push({
              pathname: "/customer-register-country",
              state: {
                from: history.location.pathname,
              },
            });
          } else {
            history.push({
              pathname: "/home",
              state: {
                from: history.location.pathname,
              },
            });
          }
        });
      },
    });
  } else {
    async function wait_for_1_sec() {
      await delay(1000);
      client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",

        callback: async (response) => {
          await googleLogin(response.code, hostname).then((res) => {
            if (!res.data["is_profile_completed"]) {
              history.push({
                pathname: "/customer-register-country",
                state: {
                  from: history.location.pathname,
                },
              });
            } else {
              history.push({
                pathname: "/home",
                state: {
                  from: history.location.pathname,
                },
              });
            }
          });
        },
      });
    }
    wait_for_1_sec();
  }
  return (
    <>
      <div style={{ height: "90%", marginBottom: "30px" }}>
        <Loader
          type="TailSpin"
          height={"100px"}
          width={"100px"}
          color="#1a73e8"
          style={{
            display: showLoader ? "block" : "none",
          }}
          className={"loader"}
        />
        <div
          style={{
            display: showLogin ? "block" : "none",
            height: "100%",
          }}
        >
          <div
            className="back-div"
            style={{ border: "none", backgroundColor: "white" }}
          >
            <Link to={"/"} className="link">
              <img
                src={backArrowIcon}
                alt={"back-icon"}
                className="back-icon"
                height="20px"
              />
            </Link>
          </div>
          <div className="login-container-box">
            {/* <select disabled className="language-select" id="language">
              {languages.map((lang) => {
                if (lang.default) {
                  return (
                    <option key={lang.id} defaultValue={lang.language}>
                      {lang.language}
                    </option>
                  );
                }
                return (
                  <option key={lang.id} value={lang.language}>
                    {lang.language}
                  </option>
                );
              })}
            </select> */}
            {/* style={{marginTop:window.innerWidth === 1920 ? "107px" : "74px"}}  */}
            <div id="signup-container" className="login-container-box-inner">
              <div className="login">
                <h1 className="signup-logo" style={{}}>
                  <img className="zopfind-logo" alt="Zopfind" src={ZopfindLogo} />
                </h1>
                <p className="login-logo-size" style={{ fontSize: "20px",  }}>Sign up</p>
                <div>
                  <button
                    className="login-btn OAuth"
                    onClick={() => client.requestCode()}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                      className="login-btn-container"
                    >

                      <div>
                        <FcGoogle className="google-logo" size={30} />
                      </div>
                      <div style={{ color: "black" }}>Signup with Google</div>
                      {/* <p style={{border:'2px solid red', width:"200px"}} >Signup with Google</p> */}
                    </div>
                  </button>
                </div>
              </div>
              <p className="para-account">
                Already have an account?{" "}
                <Link to="/login"  style={{color:"#0177F2", fontWeight:"bold"}} className="my-link" data-test="link-to-login-page">
                  Login
                </Link>{" "}
                Here
              </p>
            </div>
          </div>
        </div>
        <div id="termsandcondition" className="termsandcondition" style={{ position:"absolute", bottom:"20px", width:"100%"}} >
          <p className="termsSizeControl"
            style={{
              fontSize: "12px",
              color: "#808285",
              // backgroundColor:"blue",
              // height:"100px",
              // position: "relative",
              // bottom: "100px",
              // position:"fixed",
              // position: "relative"
            }}
          >
            By continuing, you agree to our{" "}
            <Link style={{ color: "#808285" }} to={"/terms-of-service"}>
              Terms of Service
            </Link>{" "}
            &{" "}
            <Link style={{ color: "#808285" }} to={"/privacy-policy"}>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
      {/* <div   className=""> */}

      {/* </div> */}
    </>
  );
}
export default SignUp;
