import { useEffect, useState } from "react";
import backArrowIcon from "../../assets/icons/Back arrow rounded.svg";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {
  sendVerificationCode,
  updateBusinessProfile,
  checkMobileNumber,
} from "../../services/BusinessRegistration";
import TextField from "@material-ui/core/TextField";

import _ from "lodash";
import Alert from "../Alert/Alert";

function MobileSelection() {
  const [mobile_number, setMobileNumber] = useState("");
  const [alertDisplay, setAlertDisplay] = useState("none");
  const alertErrorMsg =
    "Please Provide Correct Mobile Number! (mobile number must be 10 digit long)";
  const [displayPage, setDisplayPage] = useState("none");
  const history = useHistory();

  useEffect(() => {
    if (history.location.state && history.location.state.from) {
      if (
        history.location.state.from === "/business-register-location" ||
        history.location.state.from === "/business-mobile-verification" ||
        history.location.state.from === "/profile"
      ) {
        setDisplayPage(true);
      }
    }
  }, [history.location.state]);

  const checkMobileNumberStatus = async () => {
    checkMobileNumber(mobile_number).then((res) => {
      if (res.data.length === 0) {
        addMobileNumebrBusinessUser();
      } else if (res.data.length === 1) {
        if (res.data[0]["mobile_verified"]) {
          toast.error("Mobile Number Already Present");
        } else {
          addMobileNumebrBusinessUser();
        }
      } else {
        toast.error("Mobile Number Already Present");
      }
    });
  };

  const addMobileNumebrBusinessUser = async () => {
    if (
      _.isEmpty(mobile_number) ||
      (!_.isEmpty(mobile_number) && mobile_number.length !== 10)
    ) {
      setAlertDisplay(true);
    } else {
      try {
        setAlertDisplay("none");
        await updateBusinessProfile(
          {
            business_mobile_number: mobile_number,
          },
          history.location.state["businessId"]
        );
        await sendVerificationCode();
        history.push({
          pathname: "/business-mobile-verification",
          state: {
            from: history.location.pathname,
          },
        });
      } catch (err) {
        toast.error("Sorry, Something Went Wrong");
      }
    }
  };

  return (
    <div className="body-contaier-footer">
      <div
        className="profile-page"
        style={{ display: displayPage, width: "100vw" }}
      >
        <div className="back-div">
          <Link
            to={{
              pathname: "/business-register-location",
              state: {
                from: history.location.pathname,
              },
            }}
            className="link"
          >
            <img
              src={backArrowIcon}
              alt={"back-icon"}
              className="back-icon"
              height="20px"
            />
          </Link>
        </div>
        <ToastContainer />
        <h2 style={{ marginTop: "100px", marginBottom: "23px" }}>
          Listing Mobile Number
        </h2>
        <Alert value={alertErrorMsg} show={alertDisplay} />
        <div className="profile-page-element-container">
          <div className="profile-page-element" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <span style={{ marginRight: "5px" }}>+91</span>

              <TextField
                label="Mobile"
                variant="outlined"
                id="mobile_num"
                inputProps={{ maxLength: 10, minLength: 10 }}
                className="full-width-input custom-material-input update-label top-label-mobile"
                type="text"
                placeholder="Provide Mobile Number"
                onChange={(e) => setMobileNumber(e.target.value)}
                value={mobile_number}
              />
            </div>
            <br />
            <br />
            <button className="done-btn" onClick={checkMobileNumberStatus}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSelection;
