import { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import "../../App.css";

function BusinessAddress(props) {
  const [addressDoorNumber, setAddressDoorNumber] = useState(
    props.businessAreaLine1
  );
  const [addressStreet, setAddressStreet] = useState(props.businessAreaLine2);
  const [addressLandmark, setAddressLandmark] = useState(
    props.businessAreaLine3
  );
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    setAddressDoorNumber(props.businessAreaLine1);
  }, [props.businessAreaLine1]);
  useEffect(() => {
    setAddressStreet(props.businessAreaLine2);
  }, [props.businessAreaLine2]);
  useEffect(() => {
    setAddressLandmark(props.businessAreaLine3);
  }, [props.businessAreaLine3]);
  return (
    <>
      <Alert
        value="Please Provide All The Details Correct"
        show={showAlert ? "block" : "none"}
      />
      <label>Address Line 1 Door Number</label>

      <TextField
        label="Door Number"
        variant="outlined"
        id="locality"
        className="full-width-input custom-material-input"
        type="text"
        inputProps={{ minLength: 3, maxLength: 100 }}
        placeholder="Enter Door Number"
        onChange={(e) => setAddressDoorNumber(e.target.value)}
        value={addressDoorNumber}
      />
      <label>Address Line 2 Street Name</label>

      <TextField
        label="Enter Street"
        variant="outlined"
        id="locality2"
        className="full-width-input custom-material-input"
        type="text"
        inputProps={{ minLength: 3, maxLength: 100 }}
        placeholder="Enter Street"
        onChange={(e) => setAddressStreet(e.target.value)}
        value={addressStreet}
      />
      <label>Address Line 3 Landmark</label>

      <TextField
        label="Landmark"
        variant="outlined"
        id="locality3"
        className="full-width-input custom-material-input"
        type="text"
        inputProps={{ minLength: 3, maxLength: 100 }}
        placeholder="Enter landmark"
        onChange={(e) => setAddressLandmark(e.target.value)}
        value={addressLandmark}
      />
      <div style={{ color: "#6a6a6a", marginTop: "10px" }}>
        {_.join(
          _.compact([
            props.businessCity,
            props.businessState,
            props.businessPincode,
          ]),
          ", "
        )}
      </div>
      <button
        className="done-btn full-width-btn"
        onClick={async () => {
          if (
            addressDoorNumber === null ||
            _.isEmpty(addressDoorNumber) ||
            addressStreet === null ||
            _.isEmpty(addressStreet) ||
            addressLandmark === null ||
            _.isEmpty(addressLandmark) ||
            addressDoorNumber.match(/^[A-Za-z0-9,/\\.:\s-]*$/) == null ||
            addressStreet.match(/^[A-Za-z0-9,\s]*$/) == null ||
            addressLandmark.match(/^[A-Za-z0-9,\s]*$/) == null
          ) {
            setShowAlert(true);
          } else {
            setShowAlert(false);
            await props.updateDetails({
              business_address_line1: addressDoorNumber,
              business_address_line2: addressStreet,
              business_address_line3: addressLandmark,
            });
          }
        }}
      >
        Change
      </button>
    </>
  );
}

export default BusinessAddress;
