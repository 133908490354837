import React from "react";
import "./BusinessRegistration.css";

function PaymentPlans(props) {
  const convertToRupee = (inr) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(inr);
  };

  return (
    <React.Fragment>
      <h2
        className={"title-color title"}
        style={{ marginLeft: 0, textAlign: "center", marginTop: "100px" }}
      >
        SELECT
      </h2>
      <div className="profile-page-element-container">
        <div className="profile-page-element page-element full-width without-padding without-margin">
          <div className="payment-options full-width">
            {props.plans.map((plan, index) => {
              return (
                <div
                  style={{ borderRadius: "60px" }}
                  className={
                    plan.is_active ? "option-div" : "option-div deactive"
                  }
                  key={index}
                  onClick={() =>
                    plan.is_active ? props.planSelected(plan) : null
                  }
                >
                  {plan.is_active ? (
                    <div className="option-name">
                      {convertToRupee(
                        parseFloat(plan.discount) + parseFloat(plan.price)
                      )}
                    </div>
                  ) : (
                    <div>
                      <div
                        className="option-name"
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        {convertToRupee(
                          parseFloat(plan.discount) + parseFloat(plan.price)
                        )}
                      </div>
                    </div>
                  )}
                  <div className="option-price">
                    <div>
                      {plan.period} {plan.period > 1 ? "Years" : "Year"}
                    </div>
                    <div>{convertToRupee(plan.price)}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PaymentPlans;
